import Documents from './documents';
import config from 'config';

export const DocumentsConfig = {
  routes : [
    {
      path      : config.pages.abaca.documents,
      component : Documents
    },
    {
      path      : config.pages.store.documents,
      component : Documents
    }
  ]
}