import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Icon, Popover, AutoComplete, Alert, Checkbox } from 'antd';
import _capitalize from 'lodash/capitalize';
import _debounce from 'lodash/debounce';

// Components
import NameInput from './NameInput';

// Helpers
import config from 'config';
import {
  getProductByDesignation,
  getProviderById,
  getCategoryById,
  getCategoryByName,
  getProviderByName,
} from 'utils';
import requestService from 'services/requestService';

const { Option } = AutoComplete;

const styles = () => ({
  NewProductWrapper: {
    border: '1px solid lightgrey',
    borderRadius: '8px',
  },
  remove: {
    cursor: 'pointer',
    color: config.colors.yellowAnzac,
    marginTop: '10px',
    marginBottom: '35px',
    textAlign: 'center',
  },
  firstItem: {
    opacity: 0,
    cursor: 'default',
  },
  inputsWrapper: {
    padding: '10px 20px',
  },
  input: {
    margin: '5px 0',
  },
  info: {
    color: config.colors.greenSuccess,
    textAlign: 'right',
    opacity: 0,
    '&.show': {
      opacity: 1,
    },
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0',
  },
  switch: {
    marginTop: 20,
    display: 'inline-block',

    '& label': {
      fontSize: 13,
    },
  },
  newItemInfo: {
    opacity: 0,
    '&.show': {
      opacity: 1,
    },
  },
  stockQuantity: {
    display: 'none',
    '&.show': {
      display: 'block',
    },
    '& strong': {
      fontSize: config.fontSizes.h3,
      marginLeft: 8,
    },
  },
  okWrapper: {
    marginTop: 15,
  },
  ok: {
    color: config.colors.yellowAnzac,
  },
});

class NewProduct extends Component {
  static defaultProps = {
    isStockAction: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      designation: '',
      quantity: 1,
      description: '',
      provider: '',
      category: '',
      price: '',
      newPrice: '',
      modifiedPrice: '',
      isPopoverVisible: false,
      mustBeOrdered: false,
      isDisabled: false,
      isNewProvider: false,
      isNewCategory: false,
      error: '',
      stock: 0,
      editingOrderNumber: false,
      filteredProductsList: [],
      filteredCategoriesList: [],
    };

    this.detectExistingCategory = _debounce(this.detectExistingCategory, 600, {
      leading: true,
    });
  }

  componentDidUpdate = prevProps => {
    const { preFillDesignation } = this.props;
    if (prevProps.preFillDesignation !== preFillDesignation) {
      if (preFillDesignation) {
        this.onDesignationSelect(preFillDesignation);
      }
    }

    if (prevProps !== this.props) {
      let filteredCategoriesList = this.props.allCategoriesList;
      let _filteredProductsList = this.state.filteredProductsList;
      if (!this.state.category) {
        _filteredProductsList = this.props.allProductsList;
      }

      this.setState(
        {
          designation: this.props.designation,
          quantity: this.props.quantity,
          description: this.props.description,
          provider: this.props.provider,
          category: this.props.category,
          price: this.props.price,
          mustBeOrdered: this.props.mustBeOrdered,
          isDisabled: this.props.isDisabled,

          filteredCategoriesList,
          filteredProductsList: _filteredProductsList,
        },
        () => {
          if (prevProps.designation !== this.state.designation)
            this.onDesignationSelect(this.state.designation);
        },
      );
    }
  };

  detectExistingCategory = async value => {
    const { allCategoriesList } = this.props;

    let category = getCategoryByName(value, allCategoriesList);

    console.log(
      'Looking for category self-filled in',
      value,
      category,
      this.state.category,
    );

    if (category) {
      this.onCategorySelect(category.name, { key: category.id });
    }
  };

  updateInputValue = async (label, value) => {
    console.log('updateInputValue', label, value);

    const { allProductsList } = this.props;

    const { category } = this.state;

    let _isNewProvider = this.state.isNewProvider;
    let _isNewCategory = this.state.isNewCategory;
    if (label === 'provider') {
      _isNewProvider = value !== this.state.provider;
    } else if (label === 'category') {
      _isNewCategory = value !== category;

      if (_isNewCategory && value) {
        this.detectExistingCategory(value);
      }
    }

    const alwaysAvailableInputs = ['quantity', 'newPrice'];
    const areInputsDisabled = alwaysAvailableInputs.includes(label)
      ? this.state.isDisabled
      : false;

    let _filteredProductsList = this.state.filteredProductsList;
    if (_isNewCategory) {
      _filteredProductsList =
        label === 'category' && value === '' ? allProductsList : [];
    }

    let add = {};
    if (
      this.state.isDisabled &&
      !areInputsDisabled &&
      label !== 'description'
    ) {
      add.description = '';
    }

    const newValues = {
      [label]: value,
      isDisabled: areInputsDisabled,
      isNewProvider: value && _isNewProvider,
      isNewCategory: value && _isNewCategory,
      filteredProductsList: _filteredProductsList,
      ...add,
    };

    console.log('newValues', newValues);

    this.setState(newValues, () => {
      this.props.onChange(this.state);

      if (label === 'provider' && _isNewProvider && category) {
        this.detectExistingCategory(category);
      }
    });
  };

  handleCheckboxChange = isChecked => {
    this.setState(
      {
        mustBeOrdered: !isChecked,
      },
      () => {
        this.props.onChange(this.state);
      },
    );
  };

  onDesignationSelect = async (designation, option) => {
    const {
      allProductsList,
      onChange,
      allProvidersList,
      allCategoriesList,
    } = this.props;
    console.log('onDesignationSelect designation ===>', designation, option);

    const selectedProduct = getProductByDesignation(
      designation,
      allProductsList,
    );
    console.log('selectedProduct ===> ', selectedProduct);

    if (selectedProduct) {
      let provider = {};
      let category = {};
      try {
        provider = await getProviderById(
          selectedProduct.provider_id,
          allProvidersList.length > 0 ? allProvidersList : [],
        );

        if (selectedProduct.category_id) {
          category = await getCategoryById(
            selectedProduct.category_id,
            allCategoriesList.length > 0 ? allCategoriesList : [],
          );
        }
      } catch (error) {
        console.error('onDesignationSelect', error);
      }

      this.setState(
        {
          id: selectedProduct.id,
          designation: selectedProduct.designation,
          description: selectedProduct.description,
          provider: provider ? provider.name : '',
          category: category ? category.name : '',
          isNewProvider: false,
          isNewCategory: false,
          price: selectedProduct.price,
          isDisabled: true,
          stock: selectedProduct.stock ? selectedProduct.stock.quantity : 0,
          mustBeOrdered: selectedProduct.stock
            ? this.state.mustBeOrdered
            : true,
        },
        () => {
          onChange(this.state);
        },
      );
    } else {
      this.setState(
        {
          stock: 0,
        },
        () => {
          onChange(this.state);
        },
      );
    }
  };

  onProviderSelect = newProvider => {
    const { onChange } = this.props;
    const { provider } = this.state;
    console.log('onProviderSelect newProvider ===>', newProvider);

    this.setState(
      {
        provider: newProvider || '',
        isNewProvider: false,
      },

      () => onChange(this.state),
    );
  };

  onCategorySelect = async (newCategory, option) => {
    const { onChange, allProductsList, allProvidersList } = this.props;
    const { provider } = this.state;
    console.log('onCategorySelect newCategory ===>', newCategory, option);

    let filteredProductsList =
      allProductsList &&
      allProductsList.length > 0 &&
      (await allProductsList.filter(product => {
        let forProvider = true;

        if (provider) {
          const selectedProvider = getProviderByName(
            provider,
            allProvidersList,
          );

          if (selectedProvider) {
            forProvider = selectedProvider.id === product.provider_id;
          }
        }

        if (newCategory) {
          if (product.category_id) {
            return (
              Number(option.key) === Number(product.category_id) && forProvider
            );
          }

          return false;
        }

        return forProvider;
      }));

    console.log('filteredProductsList', filteredProductsList);

    this.setState(
      {
        category: newCategory || '',
        isNewCategory: false,
        filteredProductsList,
      },
      () => onChange(this.state),
    );
  };

  getHelperText = (type = 'provider') => {
    const { isDisabled } = this.state;
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.newItemInfo,
          this.state['isNew' + _capitalize(type)] ? 'show' : undefined,
        )}
      >
        <Icon type="info-circle" />{' '}
        {type === 'provider' ? 'Nouveau fournisseur' : 'Nouvelle catégorie'}
      </div>
    );
  };

  handlePopoverVisibleChange = isVisible => {
    const { user } = this.props;
    const { designation, isDisabled } = this.state;

    const isUserAllowed =
      user.role === config.authRoles.admin ||
      user.role === config.authRoles.owner;

    const canPopoverBeDisplayed =
      isVisible && designation && isDisabled && isUserAllowed;

    this.setState({
      isPopoverVisible: canPopoverBeDisplayed,
    });
  };

  submitNewPrice = async () => {
    const { newPrice, id } = this.state;

    if (!newPrice) {
      return false;
    }

    const productInfo = {
      id: id,
      price: newPrice,
    };

    const response = await new requestService().putProduct(productInfo);

    if (response.data && response.data.status) {
      this.setState(
        {
          price: newPrice,
          modifiedPrice: newPrice,
          isPopoverVisible: false,
        },
        () => {
          this.props.onChange(this.state);
        },
      );
    } else {
      this.setState({
        error:
          "Une erreur s'est produite lors de la modification du prix du produit",
      });
    }
  };

  getEditPriceContent = () => {
    const { classes } = this.props;
    const { newPrice, error } = this.state;

    if (error) {
      return (
        <div>
          <p>{error}</p>
        </div>
      );
    }

    return (
      <div>
        <NameInput
          type={'number'}
          label={'Nouveau prix unitaire'}
          placeholder={'Ex: 350'}
          value={newPrice}
          onChange={value => this.updateInputValue('newPrice', value)}
        />
        <div className={classes.okWrapper}>
          <a className={classes.ok} onClick={this.submitNewPrice}>
            OK
          </a>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      className,
      width,
      id,
      onRemove,
      allProvidersList,
      isStockAction,
      action,
    } = this.props;

    const {
      designation,
      quantity,
      description,
      price,
      provider,
      category,
      mustBeOrdered,
      isDisabled,
      stock,
      isPopoverVisible,

      filteredProductsList,
      filteredCategoriesList,
    } = this.state;

    let filteredCategoriesListDS = filteredCategoriesList.map(category => (
      <Option key={category.id} value={category.name}>
        {category.name}
      </Option>
    ));

    let filteredProductsListDS = filteredProductsList.map(product => (
      <Option
        key={product.id}
        value={product.designation}
        stock={product.stock ? product.stock.quantity : 0}
      >
        {product.designation} <small>({product.description})</small>
      </Option>
    ));

    const mustBeOrderedTitle =
      action == 'newStock'
        ? 'Si le produit ne fait pas partie du stock existant, il doit être commandé'
        : "Si le produit n'est pas disponible immédiatement en boutique, il doit être commandé";

    return (
      <div
        className={classNames(
          className,
          classes.NewProductWrapper,
          'new-product',
        )}
        style={{ width: width }}
      >
        <div className={classes.inputsWrapper}>
          <div className={classes.input}>
            <div
              className={classNames(
                classes.input,
                classes.info,
                !isDisabled ? 'show' : undefined,
              )}
            >
              <Icon type="info-circle" /> Nouveau produit
            </div>
          </div>

          <div
            className={classNames(classes.input, 'flex sm:flex-row flex-col')}
          >
            <NameInput
              autocomplete={
                allProvidersList &&
                allProvidersList.length > 0 &&
                allProvidersList.map(provider => provider.name)
              }
              label={'Fournisseur'}
              placeholder={'Ex: Meridiani'}
              isRequired={true}
              value={provider}
              onChange={value => this.updateInputValue('provider', value)}
              disabled={isDisabled}
              onSelect={
                allProvidersList && allProvidersList.length > 0
                  ? this.onProviderSelect
                  : null
              }
              helperText={this.getHelperText('provider')}
              allowClear
              className="mr-0 sm:mr-16"
            />

            <NameInput
              autocomplete={filteredCategoriesListDS}
              label={'Catégorie'}
              placeholder={
                isDisabled && !category ? '' : 'Ex: Canapé convertible'
              }
              isRequired={false}
              value={category}
              onChange={value => this.updateInputValue('category', value)}
              disabled={isDisabled}
              onSelect={
                filteredCategoriesListDS.length > 0
                  ? this.onCategorySelect
                  : null
              }
              helperText={this.getHelperText('category')}
              allowClear
            />
          </div>

          <div className={classes.input}>
            <NameInput
              autocomplete={filteredProductsListDS}
              label={'Désignation'}
              placeholder={'Ex: Norton capitonné'}
              isRequired={true}
              value={designation}
              onChange={value => this.updateInputValue('designation', value)}
              onSelect={
                filteredProductsListDS.length > 0
                  ? this.onDesignationSelect
                  : null
              }
              allowClear
            />
          </div>
          <div className={classes.input}>
            <NameInput
              label={'Description'}
              placeholder={'Ex: Grand canapé gris capitonné'}
              isRequired={true}
              value={description}
              onChange={value => this.updateInputValue('description', value)}
              disabled={isDisabled}
              isTextarea={true}
            />
          </div>

          <div
            className={classNames(
              classes.input,
              classes.stockQuantity,
              isDisabled ? 'show' : undefined,
            )}
          >
            <Alert
              message={`Actuellement en stock : ${stock}`}
              type={'info'}
              showIcon
            />
          </div>
          <div className={classes.priceWrapper}>
            <NameInput
              type={'number'}
              label={'Quantité'}
              placeholder={'1'}
              isRequired={true}
              value={quantity}
              onChange={value => this.updateInputValue('quantity', value)}
              className="mr-16"
            />
            <Popover
              content={this.getEditPriceContent()}
              title={`Modification du prix: ${designation}`}
              trigger="hover"
              visible={isPopoverVisible}
              onVisibleChange={this.handlePopoverVisibleChange}
            >
              <NameInput
                type={'number'}
                label={'Prix unitaire'}
                placeholder={'Ex: 350'}
                isRequired={true}
                value={price}
                onChange={value => this.updateInputValue('price', value)}
                disabled={isDisabled}
              />
            </Popover>
          </div>

          <React.Fragment>
            {action === 'newOrder' && (
              <div className={classes.switch}>
                <Checkbox
                  onChange={e => this.handleCheckboxChange(e.target.checked)}
                  defaultChecked={mustBeOrdered}
                >
                  Est disponible en boutique
                </Checkbox>
              </div>
            )}

            <div
              className={classNames(
                classes.remove,
                id === 0 ? classes.firstItem : '',
              )}
              onClick={id !== 0 ? onRemove : null}
            >
              <span>Supprimer</span>
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ abaca, auth }) {
  return {
    user: auth.user,
    allProductsList: abaca.products.allProductsList,
    allProvidersList: abaca.products.allProvidersList,
    allCategoriesList: abaca.products.allCategoriesList,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(NewProduct));
