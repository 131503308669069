import React, {Component} from 'react';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import { Paper, Icon, Input } from '@material-ui/core';
import {FuseAnimate, FuseSelectedTheme} from '@fuse';
import PropTypes from 'prop-types';


const styles = () => ({
  layoutRoot: {}
});

class SearchInput extends Component {
  static defaultProps = {
    placeholder: 'Chercher un client dans la base de données'
  };

  constructor() {
    super();
    this.state = {
      labelText: 'Rechercher un client, une contremarque...',
      searchText: ''
    }
  }

  setSearchText = (value) => {
    this.setState({searchText: value});
    this.props.onChange(value);
  }

  render()
  {
    const { labelText, searchText } = this.state

    return (

      <div className='search-input-wrapper' style={{minWidth:'400px'}} >
        <MuiThemeProvider theme={FuseSelectedTheme}>
          <FuseAnimate animation="transition.slideDownIn" delay={300}>
              <Paper className="flex items-center w-full max-w-512 rounded-32" elevation={1}>
                  <Input
                      placeholder={this.props.placeholder}
                      className="flex flex-1 px-16 py-8"
                      disableUnderline
                      fullWidth
                      value={searchText}
                      inputProps={{
                        'aria-label': labelText
                      }}
                      onChange={(e) => this.setSearchText(e.target.value)}
                  />
                  <Icon className="mr-8" color="action">search</Icon>
              </Paper>
          </FuseAnimate>
        </MuiThemeProvider>
      </div>
    )
    
  };

}

SearchInput.propTypes = {
  placeholder: PropTypes.string
}

export default withStyles(styles)(SearchInput);