import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import SwiperList from 'components/SwiperList';
import AddNewItem from 'components/AddNewItem';
import NewProduct from 'components/NewProduct';

const styles = () => ({
  spacing: {},
});

class ProductData extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items,
    };
  }

  componentDidMount() {
    this.setItemsFromProps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setItemsFromProps();
    }
  }

  setItemsFromProps = () => {
    this.setState({
      items: this.props.items,
    });
  };

  handleNewItem = () => {
    // Make a shallow copy of the items
    let items = [...this.state.items];

    items.push({
      description: '',
      provider: '',
      category: '',
      mustBeOrdered: false,
    });

    this.setState({ items });
    this.props.onProductChange(items);
  };

  handleChange = (incomingItemChanges, i) => {
    // Make a shallow copy of the items
    let items = [...this.state.items];

    // Mutate the copy with incoming changes
    items[i] = incomingItemChanges;

    // Set the state to our new copy
    this.setState({ items });
    this.props.onProductChange(items);
  };

  handleDelete = id => {
    const { items } = this.state;
    let clonedItems = [...items];

    clonedItems.splice(id, 1);

    this.setState({ items: clonedItems });
    this.props.onProductChange(clonedItems);
  };

  render() {
    const { classes, action, preFillDesignation } = this.props;
    const { items } = this.state;

    return (
      <div className={classNames('product-data-wrapper', classes.spacing)}>
        <SwiperList>
          {items.map((item, i) => {
            return (
              <NewProduct
                key={i}
                id={i}
                description={item.description}
                designation={item.designation}
                provider={item.provider}
                category={item.category}
                price={item.modifiedPrice || item.price}
                quantity={item.quantity || 1}
                mustBeOrdered={item.mustBeOrdered}
                onChange={incomingItemChanges =>
                  this.handleChange(incomingItemChanges, i)
                }
                onRemove={() => this.handleDelete(i)}
                isDisabled={item.isDisabled}
                action={action}
                preFillDesignation={preFillDesignation}
              />
            );
          })}

          <AddNewItem
            title="Ajouter un produit"
            className="swiper-slide"
            width="300px"
            onClick={this.handleNewItem}
          />
        </SwiperList>
      </div>
    );
  }
}

export default withStyles(styles)(ProductData);
