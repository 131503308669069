import NewStoreSellerAbaca from './new-store-seller-abaca';
import config from 'config';

export const NewStoreSellerAbacaConfig = {
  routes: [
    {
      path: config.pages.abaca.newStoreSeller,
      component: NewStoreSellerAbaca,
    },
  ],
};
