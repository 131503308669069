import moment from 'moment';

const filterByCreationDate = (orders, from, to) => {
  let filteredOrders = [];
  for (let i = 0; i < orders.length; i++) {
    const createdAt = orders[i].created_at;
    if (moment(createdAt).isBetween(from, to)) {
      filteredOrders.push(orders[i]);
    }
  }
  return filteredOrders;
};

const filterByStatus = (orders, statuses) => {
  const filteredOrders = orders.filter(order => {
    return statuses[order.status] === true;
  });

  return filteredOrders;
};

const filterByUser = (orders, customerId) => {
  const filteredOrders = orders.filter(order => {
    return Number(order.customer_id) === Number(customerId);
  });

  return filteredOrders;
};

const filterByShop = (orders, shopId) => {
  const filteredOrders = orders.filter(order => {
    return Number(order.store_id) === Number(shopId);
  });

  return filteredOrders;
};

const filterByOrder = (orders, orderId) => {
  const filteredOrders = orders.filter(order => {
    return Number(order.id) === Number(orderId);
  });

  return filteredOrders;
};

export const filterOrders = (
  activeFilters,
  orders,
  customerId = null,
  shopId = null,
  orderId = null,
) => {
  let filteredOrders = [];

  if (orderId) {
    return filterByOrder(orders, orderId);
  }

  // Filter by date
  filteredOrders = filterByCreationDate(
    orders,
    activeFilters.dates.from,
    activeFilters.dates.to,
  );

  // Filter by status
  filteredOrders = filterByStatus(filteredOrders, activeFilters.statuses);

  // Filter by customer or shop if specified
  if (customerId) {
    filteredOrders = filterByUser(filteredOrders, customerId);
  } else if (shopId) {
    filteredOrders = filterByShop(filteredOrders, shopId);
  }

  return filteredOrders;
};
