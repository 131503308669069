import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// store
import * as Actions from 'store/actions';

// Components
import Title from 'components/Title';
import ContactDataContainer from 'components/ContactDataContainer';
import Stepper from 'components/Stepper';

// Config & Service
import config from 'config';
import errorActionNames from 'config/errorActionNames';
import requestService from 'services/requestService';

const newCustomer = {
  title: 'NOUVEAU CLIENT',
  subtitle: 'Sélectionnez une adresse de livraison pour cette commande',
};

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto',
    marginTop: 0,
  },
  stepperWrapper: {
    marginTop: 15,
    maxWidth: config.responsive.medium,
    margin: 'auto',
  },
});

class newCustomerPage extends Component {
  static defaultProps = {
    store: '',
  };

  submitHandler = async contact => {
    console.log('contact =====>', contact);

    const { user, setErrorAction, removeErrorsAction } = this.props;

    let isSubmitSuccess = true;
    let customerId = null;

    // create customer
    try {
      const response = await new requestService().postCustomer({
        firstname: contact.firstname,
        lastname: contact.lastname,
        civility: contact.civility,
        mobile_number: contact.phone_number,
        email: contact.email,
        store_id: user.store_id,
      });
      if (response && response.statusText === 'OK' && response.data) {
        customerId = response.data.customer.id;
      } else {
        isSubmitSuccess = false;
      }
    } catch (error) {
      setErrorAction({
        actionName: errorActionNames.postCustomer,
        message: error,
      });
      isSubmitSuccess = false;
      return null;
    }

    // create address
    try {
      const contactAddress = {
        ...contact.address,
        customer_id: customerId,
      };
      const response = await new requestService().postAddress(contactAddress);
      if (response.statusText !== 'OK') {
        isSubmitSuccess = false;
      }
    } catch (error) {
      setErrorAction({
        actionName: errorActionNames.postAddress,
        message: error,
      });
      isSubmitSuccess = false;
      return null;
    }

    if (isSubmitSuccess) {
      this.props.history.push(
        `${config.pages.store.newOrder}/?customerId=${customerId}`,
      );
      removeErrorsAction();
    }
  };

  getSectionTitle = type => {
    return type === 'title'
      ? `${newCustomer[type]} ${this.props.store.toUpperCase()}`
      : newCustomer[type];
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Title
          text={this.getSectionTitle('title')}
          subtitle={null}
          backRoute={config.pages.store.home}
          isLarge
        />
        <div className={classes.stepperWrapper}>
          <Stepper current={0} steps={config.stepper.newOrder} />
        </div>
        <ContactDataContainer onSubmit={this.submitHandler} />
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setErrorAction: Actions.setErrorAction,
      removeErrorsAction: Actions.removeErrorsAction,
    },
    dispatch,
  );
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(newCustomerPage),
);
