import React, { PureComponent } from 'react';
import { Card, Alert, Icon, Button, List } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import _cloneDeep from 'lodash/cloneDeep';
import _some from 'lodash/some';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Store
import * as Actions from 'store/actions/abaca';

// Components
import Title from 'components/Title';
import SubmitButton from 'components/SubmitButton';
import Stepper from 'components/Stepper';
import ManageableSeller from 'components/ManageableSeller';
import NewStoreStaff from 'components/NewStoreStaff';

// Config & service
import config from 'config';
import requestService from 'services/requestService';
import Notification from 'services/Notification';

const listGrid = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
  xxl: 4,
};

const styles = () => ({
  spacing: {
    margin: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
  },
  stepperWrapper: {
    marginTop: 15,
    maxWidth: config.responsive.medium,
    margin: 'auto',
  },
  alert: {
    maxWidth: 350,
    marginBottom: 10,
    alignSelf: 'center',
  },
  newSellerBtn: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
  },
  submitButtonWrapper: {
    marginTop: 15,
    alignSelf: 'center',
  },
  layout: {
    maxWidth: config.responsive.medium,
    margin: 'auto',
    marginTop: 35,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&.idle': {
      alignItems: 'initial',
    },
  },
  cancel: {
    cursor: 'pointer',
    color: config.colors.yellowAnzac,
    marginRight: '50px',
  },
  modifyButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
  },
});

class NewStoreSellerAbacaPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isEmptyInput: true,
      sellerPageStatus: config.sellerPageStatus.idle,
      sellerContactInfo: {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
      },
      storeId: '',
    };
  }

  async componentDidMount() {
    const { setSelectedSellers } = this.props;
    const { newStoreParam, authRoles } = config;
    const storeId = new URLSearchParams(window.location.search).get(
      newStoreParam.storeId,
    );
    if (storeId) {
      const { data } = await new requestService().getStoreUsers(
        storeId,
        authRoles.staff,
      );
      if (data && data.users && data.users.length) {
        // Set sellers
        setSelectedSellers(data.users);
      }
      this.setState({ storeId });
    } else {
      this.setState({
        error: 'Identifiant de boutique introuvable',
      });
    }
  }

  isEmptyInput = () => {
    const { sellerContactInfo } = this.state;
    let contactWithoutId = { ...sellerContactInfo };
    delete contactWithoutId.id;
    // It returns true if it finds an empty string in contact
    const isEmptyString = _some(contactWithoutId, function(obj) {
      return obj === '';
    });
    return isEmptyString;
  };

  checkDataValidity = () => {
    const { sellerPageStatus } = this.state;
    const { sellerPageStatus: sellerPageStatusConf } = config;

    if (sellerPageStatus === sellerPageStatusConf.idle) {
      // Allow to go next step when the page is on idle state
      return true;
    }

    let areFieldsFilled = false;

    if (this.isEmptyInput()) {
      if (!this.state.isEmptyInput) {
        this.setState({ isEmptyInput: true });
      }
      areFieldsFilled = false;
    } else {
      if (this.state.isEmptyInput) {
        this.setState({ isEmptyInput: false });
      }
      areFieldsFilled = true;
    }

    return areFieldsFilled;
  };

  handleChange = (label, value) => {
    const { sellerContactInfo } = this.state;

    this.setState({
      sellerContactInfo: {
        ...sellerContactInfo,
        [label]: value,
      },
    });
  };

  setNewSellerPageStatus = () => {
    this.setState({
      sellerContactInfo: {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
      },
      sellerPageStatus: config.sellerPageStatus.new,
    });
  };

  setModifySellerPageStatus = seller => {
    this.setState({
      sellerContactInfo: {
        id: seller.id,
        firstname: seller.firstname,
        lastname: seller.lastname,
        email: seller.email,
      },
      sellerPageStatus: config.sellerPageStatus.modify,
    });
  };

  setIdleSellerPageStatus = () => {
    this.setState({ sellerPageStatus: config.sellerPageStatus.idle });
  };

  onRemove = seller => {
    // TODO
    console.log('onRemove', seller);
  };

  submitHandler = async () => {
    const { setSelectedSellers, sellers } = this.props;
    const { sellerPageStatus, sellerContactInfo, storeId } = this.state;
    const {
      sellerPageStatus: sellerPageStatusConf,
      authRoles,
      pages,
      newStoreParam,
    } = config;

    // Go to next step
    if (sellerPageStatus === sellerPageStatusConf.idle) {
      return this.props.history.push(
        `${pages.abaca.newStoreLegals}?${newStoreParam.storeId}=${storeId}`,
      );
    }

    let content = 'Erreur';
    let title = "Une erreur s'est produite";
    let level = 'ERROR';

    // Create new seller
    if (sellerPageStatus === sellerPageStatusConf.new) {
      const withRoleAndStoreId = Object.assign(sellerContactInfo, {
        role: authRoles.staff,
        store_id: storeId,
      });
      const { data } = await new requestService().createUser(
        withRoleAndStoreId,
      );
      if (data && data.status && data.user) {
        content = 'Vendeur créé avec succès';
        title = 'Création réussie';
        level = 'SUCCESS';
        setSelectedSellers([...sellers.selected, data.user]);
        this.setState({
          sellerPageStatus: sellerPageStatusConf.idle,
        });
      }
    }

    // Modify seller
    if (sellerPageStatus === sellerPageStatusConf.modify) {
      // Update user then set the new list with updated user
      const { data } = await new requestService().updateUser(sellerContactInfo);
      if (data && data.status && data.user) {
        const response = await new requestService().getStoreUsers(
          storeId,
          authRoles.staff,
        );
        if (
          response.data &&
          response.data.users &&
          response.data.users.length
        ) {
          content = 'Information vendeur modifiée avec succès';
          title = 'Modification réussie';
          level = 'SUCCESS';
          setSelectedSellers(response.data.users);
          this.setState({
            sellerPageStatus: sellerPageStatusConf.idle,
          });
        }
      }
    }

    Notification.display({
      content: content,
      title: title,
      level: level,
    });
  };

  render() {
    const { classes, sellers } = this.props;
    const { error, sellerContactInfo, sellerPageStatus } = this.state;

    const { idle } = config.sellerPageStatus;
    const isIdleList = sellerPageStatus === config.sellerPageStatus.idle;

    return (
      <div className={classes.root}>
        <Title
          text={error ? 'Erreur' : 'Vendeur'}
          helperTxt={error || ''}
          isLarge={true}
        />

        {!error && (
          <div className={classes.spacing}>
            <div className={classes.stepperWrapper}>
              <Stepper current={2} steps={config.stepper.newStore} />
            </div>

            <div
              className={classNames(
                classes.layout,
                isIdleList ? idle : undefined,
              )}
            >
              {isIdleList ? (
                <>
                  <div className={classes.newSellerBtn}>
                    <Button type="dashed" onClick={this.setNewSellerPageStatus}>
                      <Icon type="plus" /> Ajouter un vendeur
                    </Button>
                  </div>

                  <List
                    grid={listGrid}
                    dataSource={sellers.selected || []}
                    renderItem={seller => (
                      <List.Item>
                        <ManageableSeller
                          key={seller.id}
                          firstname={seller.firstname}
                          lastname={seller.lastname}
                          email={seller.email}
                          onModify={() =>
                            this.setModifySellerPageStatus(seller)
                          }
                          onRemove={() => this.onRemove(seller)}
                        />
                      </List.Item>
                    )}
                  />

                  {/* TODO: this alert must disapear if there is at least one seller */}
                  <Alert
                    className={classes.alert}
                    message="Information"
                    description="La création de vendeur est optionnelle."
                    type="info"
                    showIcon
                  />

                  <div className={classes.submitButtonWrapper}>
                    <SubmitButton
                      title="Suite"
                      isAllowed={this.checkDataValidity()}
                      onClick={this.submitHandler}
                    />
                  </div>
                </>
              ) : (
                <>
                  <NewStoreStaff
                    title="VENDEUR"
                    firstname={sellerContactInfo.firstname}
                    lastname={sellerContactInfo.lastname}
                    email={sellerContactInfo.email}
                    onChange={this.handleChange}
                  />
                  <div className={classes.modifyButtons}>
                    <span
                      className={classes.cancel}
                      onClick={this.setIdleSellerPageStatus}
                    >
                      Annuler
                    </span>
                    <SubmitButton
                      title="Valider"
                      isAllowed={this.checkDataValidity()}
                      onClick={this.submitHandler}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedSellers: Actions.setSelectedSellers,
    },
    dispatch,
  );
}

function mapStateToProps({ abaca }) {
  return {
    sellers: abaca.users.sellers,
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewStoreSellerAbacaPage),
);
