import React, { Component } from 'react';
import { css } from 'glamor';
import { FuseAnimate } from '@fuse';
import orderImg from 'static/icons/png/order.png';
import config from 'config/index';

class HomeButton extends Component {
  static defaultProps = {
    icon: orderImg,
    title: 'Carnet de commandes',
    description: 'Voir les demandes de livraison pour la semaine en cours',
    width: '60px',
  };

  btnStyle = css({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    boxShadow:
      '0px 0px 5px 0px rgba(0, 0, 0, 0.09), 0px 2px 0px -1px rgba(0, 0, 0, 0.09)',
    padding: 10,
    height: 'auto',
    minHeight: '129px',
    cursor: 'pointer',
    borderRadius: '5px',
    ':hover': {
      cursor: 'pointer',
      boxShadow:
        '0px 0px 8px 0px rgba(0, 0, 0, 0.12), 0px 4px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    ':before': {
      content: `url(${this.props.icon})`,
      width: 60,
      height: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 5,
    },
  });

  btnText = css({
    width: '200px',
  });

  render() {
    return (
      <FuseAnimate animation="transition.slideUpIn" delay={300}>
        <div {...this.btnStyle} onClick={this.props.onClick}>
          <div {...this.btnText}>
            <h3 className="text-grey-darkest font-700 md:text-16 2xl:text-20">
              {this.props.title}
            </h3>
            <p className="text-grey"> {this.props.description} </p>
          </div>
        </div>
      </FuseAnimate>
    );
  }
}

export default HomeButton;
