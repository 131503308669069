import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Form, Alert, Typography } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';

// Config
import config from 'config/index';
import errorActionNames from 'config/errorActionNames';

// Components
import SubmitButton from 'components/SubmitButton';
import ContactData from 'components/ContactData';

// Utils
import { isAddressValid } from 'utils';

const styles = () => ({
  contactContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  submit: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    alignItems: 'center',
  },
  cancel: {
    cursor: 'pointer',
    color: config.colors.yellowAnzac,
    marginRight: '50px',
  },
  alerts: {
    marginLeft: 60,
    marginTop: 35,
  },
});

class ContactDataContainer extends Component {
  static defaultProps = {
    onSubmit: () => {},
    onCancel: () => {},
    shape: config.contactDataTypes.newCustomer,
  };

  static propTypes = {
    classes: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    shape: PropTypes.string,
    data: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEmptyInput: true,
      isPhoneNumberValid: false,
      isAddressValid: false,
      contact: {
        firstname: '',
        lastname: '',
        phone_number: '',
        email: '',
        civility: '',
        address: {
          city: '',
          country: '',
          id: '',
          number: '',
          postal_code: '',
          way: '',
          source: 'google',
        },
      },
      errors: [],
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.fillContactFields(data);
    }
  }

  componentDidUpdate(prevProps) {
    const { data, common } = this.props;

    if (prevProps.data !== data) {
      this.fillContactFields(data);
    }

    if (common.errors.all.length !== prevProps.common.errors.all.length) {
      this.findContactDataErrors();
    }
  }

  findContactDataErrors = () => {
    const { common } = this.props;
    const allErrors = common.errors.all;
    const postCustomerError = allErrors.find(
      error => error.actionName === errorActionNames.postCustomer,
    );
    const postAddressError = allErrors.find(
      error => error.actionName === errorActionNames.postAddress,
    );
    if (postCustomerError || postAddressError) {
      this.setState({
        errors: [postCustomerError, postAddressError],
        isLoading: false,
      });
    }
  };

  fillContactFields = data => {
    this.setState({ contact: data });
  };

  onDataChange = (label, value, addressLabel) => {
    let contact = _.cloneDeep(this.state.contact);
    console.log('addressLabel =>', addressLabel);

    if (addressLabel) {
      console.log('addressLabel =>', addressLabel);
      contact[label][addressLabel] = value;
    } else {
      contact[label] = value;
    }
    console.log('onDataChange', contact);
    this.setState({ contact });
  };

  isPhoneNumberValid = () => {
    const { contact } = this.state;
    if (contact && contact.phone_number) {
      const phone_number = parsePhoneNumberFromString(
        contact.phone_number,
        'FR',
      );

      if (phone_number && !phone_number.isValid()) {
        // It's not valid
        return false;
      } else {
        // It's valid
        return true;
      }
    }
  };

  isEmptyInput = () => {
    const { contact } = this.state;
    const clonedContact = { ...contact };

    // Don't check for email it isn't mandatory
    delete clonedContact.email;
    delete clonedContact.firstname;
    delete clonedContact.phone_number;

    // It returns true if it finds an empty string in contact
    const isEmptyString = _.some(clonedContact, function(obj) {
      return obj === '';
    });

    return isEmptyString;
  };

  checkDataValidity = () => {
    // if (process.env.NODE_ENV === 'development') {
    //   return true
    // };

    let areFieldsFilled = false;
    let isPhoneOK = false;
    let isAddressOK = false;

    if (this.isEmptyInput()) {
      // If we find an empty input ==> not allowed
      if (!this.state.isEmptyInput) {
        this.setState({ isEmptyInput: true });
      }
      areFieldsFilled = false;
    } else {
      if (this.state.isEmptyInput) {
        this.setState({ isEmptyInput: false });
      }
      areFieldsFilled = true;
    }

    if (!this.isPhoneNumberValid()) {
      // If the number isn't valid ==> not allowed
      if (this.state.isPhoneNumberValid) {
        this.setState({ isPhoneNumberValid: false });
      }
      isPhoneOK = false;
    } else {
      if (!this.state.isPhoneNumberValid) {
        this.setState({ isPhoneNumberValid: true });
      }
      isPhoneOK = true;
    }

    if (isAddressValid(this.state.contact.address) || this.isAddressManual() === true) {
      isAddressOK = true;
      if (!this.state.isAddressValid) {
        this.setState({ isAddressValid: true });
      }
    } else if (this.isAddressManual() === false) {
      isAddressOK = false;
      if (this.state.isAddressValid) {
        this.setState({ isAddressValid: false });
      }
    }

    return areFieldsFilled && isAddressOK;
  };

  handleOnSubmit = contact => {
    const { onSubmit } = this.props;
    this.setState({ isLoading: true });
    onSubmit(contact);
  };

  isAddressManual = () => this.state.contact.source === 'manual'

  changeAddress = changedFields => {

    if (changedFields.hasOwnProperty('address')) {
      for (const [key, field] of Object.entries(changedFields.address)) {
        this.onDataChange('address', field.value, key);
      }
    }
  };

  render() {
    const { classes, onCancel, shape } = this.props;
    const {
      contact,
      isEmptyInput,
      isPhoneNumberValid,
      isAddressValid,
      isLoading,
      errors,
    } = this.state;

    return (
      <div className={classes.contactContainer}>
        <form
          className={classes.form}
          onSubmit={() => this.handleOnSubmit(contact)}
        >
          <ContactData
            firstname={contact.firstname}
            lastname={contact.lastname}
            civility={contact.civility}
            phone_number={contact.phone_number}
            address={contact.address}
            email={contact.email}
            onChange={this.onDataChange}
            onChangeAddress={this.changeAddress}
          />

          {errors.length > 0 &&
            errors.map(error => {
              if (error && error.message) {
                return (
                  <Typography.Text type="danger" key={error}>
                    {error.message}
                  </Typography.Text>
                );
              }
            })}

          {shape === config.contactDataTypes.newCustomer && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SubmitButton
                title="Suite"
                isAllowed={this.checkDataValidity()}
                onClick={() => this.handleOnSubmit(contact)}
                isLoading={isLoading}
              />
            </div>
          )}

          {shape === config.contactDataTypes.management && (
            <div className={classes.submit}>
              <span className={classes.cancel} onClick={() => onCancel()}>
                Annuler
              </span>
              <SubmitButton
                title="Enregistrer"
                isAllowed={this.checkDataValidity()}
                onClick={() => this.handleOnSubmit(contact)}
                isLoading={isLoading}
              />
            </div>
          )}
        </form>

        <div className={classes.alerts}>
          {isEmptyInput && (
            <Alert
              message="Des champs sont encore vides"
              description="Le nom et l'adresse sont obligatoires"
              type="warning"
              showIcon
            />
          )}

          {!isPhoneNumberValid && (
            <Alert
              message="Le numéro de téléphone semble invalide"
              description="Attention les numéros étrangers peuvent être invalides."
              type="warning"
              showIcon
              style={{ marginTop: 5 }}
            />
          )}

          {!isAddressValid && (
            <Alert
              message="L'adresse semble invalide"
              description="Sélectionnez l'adresse en cliquant sur les propositions."
              type="warning"
              showIcon
              style={{ marginTop: 5 }}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ abaca }) {
  return {
    common: abaca.common,
  };
}

const ConnectedContactDataContainer = Form.create()(connect(mapStateToProps)(
  ContactDataContainer,
));

export default withStyles(styles)(ConnectedContactDataContainer);
