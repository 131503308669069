import { combineReducers } from 'redux';
import modal from './modal.reducer';
import newOrder from './newOrder.reducer';
import products from './products.reducer';
import customers from './customers.reducer';
import orders from './orders.reducer';
import shops from './shops.reducer';
import stores from './stores.reducer';
import files from './files.reducer';
import users from './users.reducer';
import common from './common.reducer';

const abacaReducers = combineReducers({
  modal,
  newOrder,
  products,
  customers,
  orders,
  shops,
  stores,
  files,
  users,
  common,
});

export default abacaReducers;
