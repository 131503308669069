import React, { Component } from 'react'
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import { Button } from 'antd'

import config from 'config';

const styles = () => ({
  root: {
    padding: 12,
    fontSize: '1.6rem',
  }
});

class AddComment extends Component {

  render() {
    const {classes} = this.props

    return (
      <div className={classNames(classes.root,"add-comment-wrapper")} >

        <Button onClick={this.props.onClick} type="primary">
          Ajouter un commentaire
        </Button>

      </div>
    )
  }
}

export default withStyles(styles)(AddComment)