import Error500 from './Error500';

export const Error500Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/errors/error-500',
            component: Error500
        }
    ]
};
