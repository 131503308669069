import * as Actions from '../../actions/abaca';

const initialState = {
  sellers: {
    selected: [],
  },
};

const users = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SELECTED_SELLERS: {
      return {
        ...state,
        sellers: {
          ...state.sellers,
          selected: action.payload,
        },
      };
    }
    case Actions.CLEAR_USERS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default users;
