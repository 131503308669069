import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import config from '../config';

/**
 * 
 */
class BackButton extends Component {

  gotToGoTo = () => {
    const { onBackClick, isManual } = this.props

    if (!isManual) {
      if (this.props.route !== null) {
        this.props.history.push(this.props.route);
      } else {
        this.props.history.goBack();
      }
    } else {
      onBackClick();
    }

  }

  render() {

    return (
      <div className='back-button-wrapper' onClick={this.gotToGoTo}>
        <IconButton aria-label='ArrowBack' >
          <ArrowBack />
        </IconButton>
      </div>
    )
    
  };

}

BackButton.defaultProps = {
  route: config.pages.abaca.home,
  onBackClick: null,
  isManual: false
}

BackButton.propTypes = {
  route: PropTypes.string,
  onBackClick: PropTypes.func,
  isManual: PropTypes.bool
}

export default withRouter(BackButton);