import { matchRoutes } from 'react-router-config';
import history from '../history';

import config from 'config';

class Auth {
  redirect = false;
  checkRole = (user, routes) => {
    console.log('route loaded', history.location.pathname);
    const matched = matchRoutes(routes, history.location.pathname)[0];
    console.log('checkAuth', matched, user);

    if (matched && matched.route.auth && matched.route.auth.length > 0) {
      console.log('checkAuth', history.location);

      if (!matched.route.auth.includes(user.role)) {
        this.redirect = true;
        if (user.role === 'guest') {
          history.push({
            pathname: '/login',
            state: {
              redirectUrl: history.location.pathname + history.location.search,
            },
          });

          return false;
        } else {
          history.push({
	    pathname: user.role === config.authRoles.admin ? config.pages.abaca.home : config.pages.store.home,
          });

          return true;
        }
      }
    }

    return true;
  };
}
const instance = new Auth();

export default instance;
