import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import { Statistic, Card, Icon, Button, Alert } from 'antd';
import { FuseAnimate } from '@fuse';
import { PDFViewer } from '@react-pdf/renderer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components
import Title from 'components/Title';
import VolumeDetails from 'components/VolumeDetails';
import PdfMaker from 'components/PdfMaker';

// Store
import * as Actions from 'store/actions';

// Helpers & Config
import config from '../../../../config';
import { getAllOrdersList } from 'utils';

const styles = () => ({
  root: {
    '& .description-wrapper': {
      padding: 60,
      maxWidth: config.responsive.large,
      margin: 'auto',
      '& .alert': {
        width: '335px',
        margin: 'auto',
        textAlign: 'center',
        marginTop: '10px',
      },
      '& iframe': {
        width: '100%',
        height: '500px',
      },
      '& .pdfDownload': {
        textAlign: 'center',
        marginTop: 10,
      },
      '& .detail-wrapper': {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: 80,
      },
      '& .selected-month-wrapper': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: config.fontSizes.h2,
        '& span': {
          margin: '0 20px',
        },
      },
      '& .total-wrapper': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 80,
      },
      '& .volume-detail-wrapper': {
        marginTop: 80,
      },
    },
  },
  orderProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -24,
    marginLeft: -24,
  },
});

class Volume extends Component {
  handleSwitchMonth = direction => {
    const { selectedMonth } = this.state;
    this.setState({ isPdfGenerated: false }, () => {
      switch (direction) {
        case 'next':
          this.setState(
            {
              selectedMonth: selectedMonth.add(1, 'month'),
            },
            () => {
              this.fillStatesAccordingToSelectedMonth();
            },
          );
          break;

        case 'previous':
          this.setState(
            {
              selectedMonth: selectedMonth.subtract(1, 'month'),
            },
            () => {
              this.fillStatesAccordingToSelectedMonth();
            },
          );
          break;

        default:
          break;
      }
    });
  };

  fillStatesAccordingToSelectedMonth() {
    const { selectedMonth, products } = this.state;

    const clonedProducts = { ...products };

    const receivedAndDelivered = [];
    const notReceivedAndNotDelivered = [];
    const receivedOnly = [];
    const deliveredOnly = [];

    for (let i = 0; i < clonedProducts.all.length; i++) {
      //? We don't care about never stored products
      if (
        clonedProducts.all[i].status ===
          config.productStatusCodes.AVAILABLE_AT_STORE ||
        !clonedProducts.all[i].received_at
      ) {
        continue;
      }

      const isReceivedThisMonth = moment(
        clonedProducts.all[i].received_at,
      ).isBetween(
        moment(selectedMonth).startOf('month'),
        moment(selectedMonth).endOf('month'),
      );

      const isDeliveredThisMonth = moment(
        clonedProducts.all[i].delivered_at,
      ).isBetween(
        moment(selectedMonth).startOf('month'),
        moment(selectedMonth).endOf('month'),
      );

      if (isReceivedThisMonth && isDeliveredThisMonth) {
        //? Charge for two cross docking in and out + time storage prorata between received_at and delivered_at
        receivedAndDelivered.push(clonedProducts.all[i]);
      }

      if (isReceivedThisMonth && !isDeliveredThisMonth) {
        //? Charge for 1 cross docking in + time storage prorata from received_at until end Of current MOnth
        receivedOnly.push(clonedProducts.all[i]);
      }

      if (!isReceivedThisMonth && isDeliveredThisMonth) {
        //? Charge for 1 cross docking out + time storage prorata from start of current month until delivered_at
        deliveredOnly.push(clonedProducts.all[i]);
      }

      if (!isReceivedThisMonth && !isDeliveredThisMonth) {
        //? if received_at is before startOfMonth and if delivered_at is after endOfMonth or null
        //? then we should charge for a full month storage without any cross docking
        if (
          moment(clonedProducts.all[i].received_at).isBefore(
            moment(selectedMonth).startOf('month'),
          ) &&
          (moment(clonedProducts.all[i].delivered_at).isAfter(
            moment(selectedMonth).startOf('month'),
          ) ||
            !clonedProducts.all[i].delivered_at)
        ) {
          notReceivedAndNotDelivered.push(clonedProducts.all[i]);
        }
      }
    }

    this.setState(
      {
        products: {
          ...products,
          selectedMonth: {
            receivedAndDelivered: this.getStockInfo(
              'receivedAndDelivered',
              receivedAndDelivered,
            ),
            notReceivedAndNotDelivered: this.getStockInfo(
              'notReceivedAndNotDelivered',
              notReceivedAndNotDelivered,
            ),
            receivedOnly: this.getStockInfo('receivedOnly', receivedOnly),
            deliveredOnly: this.getStockInfo('deliveredOnly', deliveredOnly),
          },
        },
      },
      () => {
        console.log(
          'this.state.products.selectedMonth =====>',
          this.state.products.selectedMonth,
        );
        this.setState({ isLoading: false });
      },
    );
  }

  getStockInfo(typeOfProduct, products) {
    function getEllapsedDays(typeOfProduct, product) {
      switch (typeOfProduct) {
        case 'receivedOnly':
          return (
            Math.abs(
              moment(product.received_at).diff(
                moment(selectedMonth).endOf('month'),
                'days',
              ),
            ) + 1
          );
        case 'deliveredOnly':
          return (
            Math.abs(
              moment(product.delivered_at).diff(
                moment(selectedMonth).startOf('month'),
                'days',
              ),
            ) + 1
          );
        case 'notReceivedAndNotDelivered':
          return (
            Math.abs(
              moment(selectedMonth)
                .startOf('month')
                .diff(moment(selectedMonth).endOf('month'), 'days'),
            ) + 1
          );
        case 'receivedAndDelivered':
          return (
            Math.abs(
              moment(product.received_at).diff(
                moment(product.delivered_at),
                'days',
              ),
            ) + 1
          );
        default:
          break;
      }
    }

    function getSubStockInfo(typeOfProduct, products, crossDocking) {
      for (let i = 0; i < products.length; i++) {
        const dailyCost =
          (config.stockPrices.perMeterPerMonth *
            products[i].storage_occupation) /
          moment().daysInMonth();
        const ellapsedDays = getEllapsedDays(typeOfProduct, products[i]);
        const timeCost = Number((dailyCost * ellapsedDays).toFixed(2));
        const total = crossDocking + timeCost;

        products[i].stockInfo = {
          crossDocking,
          ellapsedDays,
          timeCost,
          total,
        };
      }
      return products;
    }

    const { selectedMonth } = this.state;
    const crossDocking = {
      receivedOnly: 5,
      deliveredOnly: 5,
      notReceivedAndNotDelivered: 0,
      receivedAndDelivered: 10,
    };

    return getSubStockInfo(
      typeOfProduct,
      products,
      crossDocking[typeOfProduct],
    );
  }

  getTotal(typeOfTotal) {
    const { selectedMonth } = this.state.products;

    const arrayOfProducts = Object.values(selectedMonth);
    let total = 0;

    // Wait for a ready component state
    if (arrayOfProducts.length > 0) {
      for (let i = 0; i < arrayOfProducts.length; i++) {
        for (let j = 0; j < arrayOfProducts[i].length; j++) {
          if (typeOfTotal === 'storage_occupation') {
            total += arrayOfProducts[i][j].storage_occupation;
          } else {
            total += arrayOfProducts[i][j].stockInfo[typeOfTotal];
          }
        }
      }
    }

    return total;
  }

  setPdfGenerated = () => {
    this.setState({ isPdfGenerated: true });
  };

  setSelectedClient = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedClientName = urlParams.get('clientName');

    if (selectedClientName) {
      this.setState({ selectedClientName });
    } else {
      this.setState({ error: 'Veuillez sélectionner un magasin client' });
    }
  };

  setSelectedClientOrderProducts = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const shopId = urlParams.get(config.newStoreParam.storeId);

    if (shopId) {
      // Retrieve all selected shop's order products
      const { allOrdersList } = this.props;
      const selectedShopOrders = allOrdersList.filter(
        order => order.store_id === parseInt(shopId),
      );
      const clonedSelectedShopOrders = _cloneDeep(selectedShopOrders);
      let selectedShopOrderProducts = [];
      clonedSelectedShopOrders.forEach(order => {
        order.products.forEach(orderProduct => {
          // Add the delivered_at key to all order products
          orderProduct = {
            ...orderProduct,
            delivered_at: order.delivered_at,
          };

          selectedShopOrderProducts.push(orderProduct);
        });
      });

      console.log('[Volume]', selectedShopOrderProducts);

      this.setState({
        products: {
          ...this.state.products,
          all: selectedShopOrderProducts,
        },
      });
    } else {
      this.setState({ error: 'Identifiant du magasin client manquant' });
    }
  };

  getAllOrders = async () => {
    const { allOrdersList, storeAllOrders } = this.props;
    let orders = [];
    if (allOrdersList.length === 0) {
      const response = await getAllOrdersList();
      if (response && response.data) {
        orders = response.data;
        storeAllOrders(orders);
      } else {
        this.setState({
          error: 'Erreur lors de la récupération des commandes',
        });
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isPdfGenerated: false,
      error: false,
      selectedMonth: moment().subtract('1', 'month'),
      selectedClientName: '',
      products: {
        all: [],
        selectedMonth: {},
      },
    };

    this.fillStatesAccordingToSelectedMonth = this.fillStatesAccordingToSelectedMonth.bind(
      this,
    );
    this.getStockInfo = this.getStockInfo.bind(this);
    this.getTotal = this.getTotal.bind(this);
  }

  async componentDidMount() {
    await this.getAllOrders();
    this.setSelectedClient();
    this.setSelectedClientOrderProducts();
    this.fillStatesAccordingToSelectedMonth();
  }

  componentDidCatch(error) {
    this.setState({ error: error });
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      error,
      selectedMonth,
      products,
      isPdfGenerated,
      selectedClientName,
    } = this.state;
    const total = {
      crossDocking: this.getTotal('crossDocking'),
      timeCost: this.getTotal('timeCost'),
      ellapsedDays: this.getTotal('ellapsedDays'),
      storage_occupation: this.getTotal('storage_occupation'),
    };

    if (isLoading) {
      return <CircularProgress size={68} className={classes.orderProgress} />;
    }

    let isCurrentMonth =
      selectedMonth.format('MMMM YYYY') === moment().format('MMMM YYYY');

    return (
      <div className={classNames('volume-page-wrapper', classes.root)}>
        {!error && (
          <>
            <Title
              text={selectedClientName}
              helperTxt={"Utilisation de l'entrepôt & Coût en stockage"}
            />

            <div className="description-wrapper">
              {/* {isPdfGenerated && <PDFViewer>
                <PdfStockBill month={selectedMonth.format('MMMM YYYY')} products={products.selectedMonth} total={total} />
              </PDFViewer>} */}

              <FuseAnimate animation="transition.slideRightBigIn" delay={200}>
                <div className="selected-month-wrapper">
                  <Icon
                    type="left-circle"
                    onClick={() => this.handleSwitchMonth('previous')}
                  />
                  <span>{selectedMonth.format('MMMM YYYY')}</span>
                  {!isCurrentMonth && (
                    <Icon
                      type="right-circle"
                      onClick={() => this.handleSwitchMonth('next')}
                    />
                  )}
                </div>
              </FuseAnimate>

              {!isCurrentMonth && (
                <FuseAnimate animation="transition.slideRightBigIn" delay={200}>
                  <div className="pdfDownload">
                    {selectedClientName ? (
                      isPdfGenerated ? (
                        <PdfMaker
                          client={selectedClientName}
                          month={selectedMonth.format('MMMM YYYY')}
                          products={products.selectedMonth}
                          total={total}
                        />
                      ) : (
                        <Button onClick={this.setPdfGenerated}>
                          Générer un pdf
                        </Button>
                      )
                    ) : null}
                  </div>
                </FuseAnimate>
              )}

              {isCurrentMonth && (
                <FuseAnimate animation="transition.slideRightBigIn" delay={200}>
                  <Alert
                    message="Les chiffres pour le mois en cours peuvent être faux et ne sont donc pas affichés ici"
                    type="warning"
                    showIcon
                    className="alert"
                  />
                </FuseAnimate>
              )}

              {!isCurrentMonth && (
                <React.Fragment>
                  <FuseAnimate
                    animation="transition.slideRightBigIn"
                    delay={200}
                  >
                    <div className="total-wrapper">
                      <Card
                        style={{ width: 300 }}
                        title={'Coût total à facturer'}
                      >
                        <Statistic
                          title={`${
                            total.crossDocking
                          } + ${total.timeCost.toFixed(2)}`}
                          value={total.crossDocking + total.timeCost}
                          precision={2}
                          valueStyle={{ color: config.colors.greenSuccess }}
                          suffix="€"
                        />
                      </Card>
                    </div>
                  </FuseAnimate>

                  <FuseAnimate
                    animation="transition.slideRightBigIn"
                    delay={400}
                  >
                    <div className="detail-wrapper">
                      <Statistic
                        title="Coùt total des passages à quai"
                        value={total.crossDocking}
                        precision={2}
                        suffix="€"
                      />

                      <Statistic
                        title={`Coût total durée de stockage (${
                          config.stockPrices.perMeterPerMonth
                        }€/mois/m3)`}
                        value={total.timeCost}
                        precision={2}
                        suffix="€"
                      />
                    </div>
                  </FuseAnimate>

                  <FuseAnimate
                    animation="transition.slideRightBigIn"
                    delay={600}
                  >
                    <div className="volume-detail-wrapper">
                      <VolumeDetails products={products.selectedMonth} />
                    </div>
                  </FuseAnimate>
                </React.Fragment>
              )}
            </div>
          </>
        )}

        {error && (
          <Title
            text="Erreur"
            helperTxt={'Impossible de récupérer les volumes :('}
            subtitle={error.toString()}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ abaca }) => {
  return {
    allOrdersList: abaca.orders.all,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      storeAllOrders: Actions.storeAllOrders,
    },
    dispatch,
  );
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Volume),
);
