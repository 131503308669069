import config from 'config';

const { authRoles: roles } = config;
const { admin, owner, staff, guest } = roles;

/**
 * Authorization Roles
 */
const authRoles = {
  admin: [admin],
  owner: [owner],
  staff: [staff, owner],
  any  : [admin, owner, staff],
  onlyGuest: [guest],
};

export default authRoles;
