import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Icon } from 'antd';

import config from 'config';
const orderStatus = config.orderStatus;

class switchFilter extends PureComponent {
  static defaultProps = {
    onChange: () => {},
  };

  static propTypes = {
    onChange: PropTypes.func,
    switchesStatuses: PropTypes.object.isRequired,
    filteredOrders: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { switchesStatuses } = props;

    this.state = {
      switches: {
        [orderStatus.paused]: switchesStatuses[orderStatus.paused],
        [orderStatus.in_progress]: switchesStatuses[orderStatus.in_progress],
        [orderStatus.provider_ordered]:
          switchesStatuses[orderStatus.provider_ordered],
        [orderStatus.canceled]: switchesStatuses[orderStatus.canceled],
        [orderStatus.delivered]: switchesStatuses[orderStatus.delivered],
        [orderStatus.ready]: switchesStatuses[orderStatus.ready],
      },
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { filteredOrders, onChange } = this.props;
    if (prevProps.filteredOrders !== filteredOrders) {
      this.setState({ isLoading: false });
    }

    if (prevState.switches !== this.state.switches) {
      onChange(this.state.switches);
    }
  }

  handleOnSwitchChange = (isChecked, nameId) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({
        switches: {
          ...this.state.switches,
          [nameId]: isChecked,
        },
      });
    }, 100);
  };

  render() {
    const { isLoading } = this.state;

    const switchesToMap = [
      { nameId: orderStatus.delivered, humanized: 'Commandes livrées' },
      { nameId: orderStatus.paused, humanized: 'Commandes en pause' },
      { nameId: orderStatus.in_progress, humanized: 'Commandes en cours' },
      { nameId: orderStatus.canceled, humanized: 'Commandes annulées' },
      {
        nameId: orderStatus.provider_ordered,
        humanized: 'Commandes fournisseur',
      },
      { nameId: orderStatus.ready, humanized: 'Prêtes pour livraison' },
    ];

    return (
      <>
        {switchesToMap.map(item => {
          return (
            <div className="switch-wrapper" key={item.nameId}>
              <span>{item.humanized}</span>
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={this.state.switches[orderStatus[item.nameId]]}
                onChange={isChecked =>
                  this.handleOnSwitchChange(isChecked, item.nameId)
                }
                loading={isLoading}
              />
            </div>
          );
        })}
      </>
    );
  }
}

export default switchFilter;
