import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { List, Card, Descriptions, Button, Icon } from 'antd';
import { FuseAnimate } from '@fuse';
import _capitalize from 'lodash/capitalize';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import Title from 'components/Title';

// Helpers & Config
import config from 'config';
import requestService from 'services/requestService';

// Store
import * as Actions from 'store/actions';

const { responsive, colors, pages, newStoreParam } = config;
const { abaca } = pages;

const styles = () => ({
  root: {
    '& .new-store-wrapper': {
      padding: 30,
      textAlign: 'center',
    },

    '& .list-wrapper': {
      padding: 30,
      maxWidth: '600px',
      margin: '0 auto',
    },

    '& .list-item-wrapper': {
      cursor: 'pointer',
    },

    '& .shop-options-wrapper': {
      paddingTop: 30,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: responsive.medium,
      margin: 'auto',
    },

    '& .extra': {
      color: colors.yellowAnzac,
    },

    '& .connect-under': {
      textAlign: 'center',
      marginTop: 50,
    },
  },
});

class SelectStorePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      shops: [],
      isInShop: false,
      shopName: '',
      ownerUuid: '',
    };
  }

  async componentDidMount() {
    const { allShopsList, storeAllShops } = this.props;
    if (allShopsList.length === 0) {
      const shops = (await this.getShops()).sort((a, b) => {
        let min = Math.min(a.brand_name.length, b.brand_name.length);

        if (a.brand_name.substr(0, min) === b.brand_name.substr(0, min)) {
          return a.brand_name.length === min ? -1 : 1;
        }

        return a.brand_name > b.brand_name ? 1 : -1;
      });

      this.setState({ shops });
      storeAllShops(shops);
    } else {
      this.setState({ shops: allShopsList });
    }
  }

  getShops = async () => {
    try {
      const response = await new requestService().getStores();
      if (response && response.statusText === 'OK' && response.data) {
        return response.data;
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };

  onShopClick(shopName, shopId, ownerUuid) {
    this.props.history.push(
      `${config.pages.abaca.storeMenu}?${newStoreParam.storeName}=${shopName}&${
        newStoreParam.storeId
      }=${shopId}&${newStoreParam.ownerUuid}=${ownerUuid}`,
    );
  }

  handleBackFromShop() {
    const { isInShop } = this.state;

    if (isInShop) {
      this.setState({ isInShop: false });
    } else {
      this.props.history.goBack();
    }
  }

  getExtra() {
    return <span className="extra">Voir</span>;
  }

  goToNewStore = () => {
    this.props.history.push(abaca.newStore);
  };

  render() {
    const { classes } = this.props;
    const { error, isInShop, shops } = this.state;

    return (
      <div className={classNames('select-store-wrapper', classes.root)}>
        {!error && (
          <>
            <Title
              text={'Les magasins clients'}
              helperTxt={''}
              isLarge={true}
              onBackClick={this.handleBackFromShop.bind(this)}
            />

            {!isInShop && (
              <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div className="new-store-wrapper">
                  <Button type="dashed" onClick={this.goToNewStore}>
                    <Icon type="plus" /> Nouveau magasin client
                  </Button>
                </div>
              </FuseAnimate>
            )}

            {!isInShop && (
              <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <div className="list-wrapper">
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                    }}
                    dataSource={shops}
                    renderItem={item => (
                      <div className="list-item-wrapper">
                        <List.Item>
                          <Card
                            title={item.brand_name}
                            onClick={() =>
                              this.onShopClick(
                                item.brand_name,
                                item.id,
                                item.user && item.user[0]
                                  ? item.user[0].uuid
                                  : '',
                              )
                            }
                            extra={this.getExtra()}
                          >
                            <Descriptions
                              layout="horizontal"
                              column={{
                                xxl: 2,
                                xl: 2,
                                lg: 2,
                                md: 2,
                                sm: 2,
                                xs: 1,
                              }}
                            >
                              <Descriptions.Item label="Email">
                                {item.email}
                              </Descriptions.Item>
                              <Descriptions.Item label="Nom du contact">
                                {`${_capitalize(item.firstname)} ${_capitalize(
                                  item.lastname,
                                )}`}
                              </Descriptions.Item>
                            </Descriptions>
                          </Card>
                        </List.Item>
                      </div>
                    )}
                  />
                </div>
              </FuseAnimate>
            )}
          </>
        )}

        {error && (
          <Title
            text="Erreur"
            subtitle={'Impossible de récupérer les magasins :('}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ abaca }) => {
  return {
    allShopsList: abaca.shops,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      storeAllShops: Actions.storeAllShops,
    },
    dispatch,
  );
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SelectStorePage),
);
