import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Input, AutoComplete } from 'antd';
import config from '../config';

const { TextArea } = Input;

const styles = () => ({
  container: {},
  label: {
    color: config.colors.greyDove,
  },
  helperText: {
    color: config.colors.greenSuccess,
  },
  vertical: {
    
  },
  horizontal: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      flexGrow: 1,
      whiteSpace: 'nowrap',
      paddingRight: '10px',
    },
    '& input': {
      height: 'auto !important',
      minHeight: 'inherit !important'
    }
  }
});

class NameInput extends Component {
  static defaultProps = {
    inputType: 'text',
    label: 'Nom',
    placeholder: 'Nom',
    isRequired: false,
    isError: false,
    helperText: '',
    autocomplete: [],
    suffix: null,
    onChange: () => {},
    isTextarea: false,
    orientation: 'vertical',
  };

  handleChange = event => {
    this.props.onChange(event.target.value);
  };

  render() {
    const {
      classes,
      autocomplete,
      onSelect,
      helperText,
      className,
      isTextarea,
      orientation,
    } = this.props;

    const InputTag = isTextarea === true ? TextArea : Input;

    return (
      <div className={classNames('name-input-wrapper', className, classes[orientation])}>
        {this.props.label && (
          <label className={classes.label}>{this.props.label}:</label>
        )}
        {autocomplete.length > 0 ? (
          <AutoComplete
            {...this.props}
            dataSource={autocomplete}
            optionLabelProp={'value'}
            placeholder={this.props.placeholder}
            onSelect={onSelect}
            filterOption={(inputValue, option) => {
              let proposition = option.props.children
              if (Array.isArray(proposition)) {
                if (proposition.length) {
                  proposition = option.props.children[0]
                } else {
                  return true
                }
              }

              return proposition
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }}
          />
        ) : (
          <InputTag
            {...this.props}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.handleChange}
            required={this.props.isRequired}
            label={this.props.label}
            suffix={this.props.suffix}
            min="0"
          />
        )}
        <div className={classes.helperText}>{helperText}</div>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(NameInput));
