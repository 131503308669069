import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import check from 'static/icons/svg/checkYellow.svg';

const styles = theme => ({
	SelectableAddressWrapper: {
		width: '300px',
		minWidth: '200px',
		border: '1px dashed lightgrey',
		borderRadius: '8px',
		padding: '20px',
		cursor: 'pointer',
		display: 'block !important',

		'& .name': {
			color: '#2d2d2d',
      fontSize: '1.2em',
      position: 'relative',
    },
    
    '& .check': {
      position: 'absolute',
      top: '-13px',
      right: '-13px',
    },

		'& hr': {
			margin: '15px 0',
			borderColor: 'silver',
			borderWidth: '1px'
		},

		'& .others': {
			color: 'dimgray',
			fontSize: '1em'
		},

		'&.selected': {
			border: '1px solid '+theme.palette.primary.dark,
			'& hr': {
				borderColor: theme.palette.primary.dark
			}
    },

    '& .others-wrapper': {
      marginBottom: 40
    }
	}
});

class SelectableAddress extends Component {
  static defaultProps = {
    name: '',
    number: 0,
    street: '',
    code: '',
    city: '',
    country: '',
		phoneNumber: '',
		isSelected: false,
		onSelect: () => {}
  };

  render() {
    const {classes, ...props} = this.props;
    
    return (
      <div 
        className={classNames(classes.SelectableAddressWrapper, props.className, {
          'selected': this.props.isSelected
        }) }
        onClick={() => this.props.onSelect(this)} 
      >

					<div className="name" >
						{this.props.name} {this.props.isSelected && <img src={check} className="check" style={{width:'20px'}} alt="checked" /> }
					</div>
					<div className={'others-wrapper'} >
						<hr />
						<span className="others" >{this.props.number}, {this.props.street}</span> <br/>
						<span className="others" >{this.props.city}, {this.props.code} </span> <br/>
						<span className="others" >{this.props.country}</span> <br/>
						<span className="others" >{this.props.moreInfo}</span> <br/>
						<span className="others" >{this.props.phoneNumber}</span>
					</div>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(SelectableAddress);