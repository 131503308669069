import * as Actions from '../../actions/fuse/index';
import config from 'config';

const initialState = {
  state: false,
  options: {
    children: config.dialogContent.switchStatus,
  },
  confirmedReceivedProductId: null,
  canceledReceptionProductId: null,
  receivedProductStorageInfo: {
    stockageZone: null,
    cubicMeter: null,
    numberOfPackages: null,
  },
};

const dialog = function(state = initialState, action) {
  switch (action.type) {
    case Actions.OPEN_DIALOG: {
      return {
        ...state,
        state: true,
        options: {
          ...state.options,
          ...action.options,
        },
      };
    }
    case Actions.CLOSE_DIALOG: {
      return {
        ...state,
        state: false,
        options: {
          ...state.options,
          ...action.options,
        }
      };
    }
    case Actions.CONFIRM_RECEPTION_OF_PRODUCT: {
      return {
        ...state,
        confirmedReceivedProductId: action.payload,
      };
    }
    case Actions.CANCEL_RECEPTION_OF_PRODUCT: {
      return {
        ...state,
        canceledReceptionProductId: action.payload,
      };
    }
    case Actions.CLEAR_CANCELED_RECEPTION_PRODUCT_ID: {
      return {
        ...state,
        confirmedReceivedProductId: null,
        canceledReceptionProductId: null,
        receivedProductStorageInfo: {
          stockageZone: null,
          cubicMeter: null,
          numberOfPackages: null,
        },
      };
    }
    case Actions.UPDATE_RECEIVED_PRODUCT_STORAGE_INFO: {
      return {
        ...state,
        receivedProductStorageInfo: {
          ...state.receivedProductStorageInfo,
          stockageZone: action.payload.stockageZone,
          cubicMeter: action.payload.cubicMeter,
          numberOfPackages: action.payload.numberOfPackages,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default dialog;
