import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FusePageSimple} from '@fuse';
import {List, ListItem, ListItemText, Hidden, Icon, IconButton} from '@material-ui/core';

import BackButton 			  from 'components/BackButton';
import SearchInput 			  from 'components/SearchInput';
import HomeButton  			  from 'components/HomeButton';
import SubmitButton 		  from 'components/SubmitButton';
import SwitchButton 		  from 'components/SwitchButton';
import NameInput 			    from 'components/NameInput';
import PhoneNumberInput 	from 'components/PhoneNumberInput';
import NewProduct 			  from 'components/NewProduct';
import AddNewItem 			  from 'components/AddNewItem';
import SwiperList 			  from 'components/SwiperList';
import SelectableAddress  from 'components/SelectableAddress';
import AccordeonContainer from 'components/AccordeonContainer';
import AlertDialog        from 'components/Modal/AlertDialog';
import NotificationSample from 'components/NotificationSample';

import fakeOrders             from 'fake-db/orders-db';

const styles = theme => ({
	layoutRoot: {}
});

class Components extends Component {

	state = {
    content: 'AccordeonContainer',
    isRightValue: false,
    isSelected: false,
  }
  
  switchButtonHandler = () => {
    this.setState({isRightValue: !this.state.isRightValue})
  }

  selectableAddressHandler = () => {
	  this.setState({isSelected: !this.state.isSelected})
  }

	render()
	{
		const {classes} = this.props;

		let content = null;
    switch (this.state.content) {
      case 'BackButton'         : content = <BackButton />; break;
      case 'SearchInput'        : content = <SearchInput />; break; // Props : placeholder={}
      case 'HomeButton'         : content = <HomeButton />; break; // Props : icon={} title={} description={} 
      case 'SubmitButton'       : content = <SubmitButton isAllowed={true} />; break; // Props : title={} isAllowed={}
      case 'SwitchButton'       : content = <SwitchButton isRightValueChecked={this.state.isRightValue} onClick={() => this.switchButtonHandler()} leftValue={'Non'} leftColor={'#CFCFCF'} rightValue={'Oui'} rightColor={'#DDC23D'} />; break;       
      case 'NameInput'          : content = <NameInput isRequired={true} />; break; // Props : label={} placeholder={} isRequired={} isError={} helperText={}
      case 'PhoneNumberInput'   : content = <PhoneNumberInput />; break; // Props : title={} placeholder={} 
      case 'NewProduct'         : content = <NewProduct />; break;
      case 'AddNewItem'         : content = <AddNewItem />; break; // Props : title={} width={} height={} 
      case 'SwiperList'         : content = <SwiperList />; break;
      case 'SelectableAddress'  : content = <SelectableAddress isSelected={this.state.isSelected} onSelect={this.selectableAddressHandler} />; break; // Props : name street city code country number phoneNumber isSelected onSelect
      case 'AccordeonContainer' : content = <AccordeonContainer data={fakeOrders} />; break;
      case 'AlertDialog'        : content = <AlertDialog />; break;
			case 'Notification'       : content = <NotificationSample />; break;

      default: content = this.state.content;
    }

		return (
			<FusePageSimple
				classes={{
					root: classes.layoutRoot
				}}
				header={
					<div className="flex flex-col flex-1">
						<div className="flex items-center pl-12 lg:pl-24 p-24">
							<Hidden lgUp>
								<IconButton
									onClick={(ev) => this.pageLayout.toggleLeftSidebar()}
									aria-label="open left sidebar"
								>
									<Icon>menu</Icon>
								</IconButton>
							</Hidden>
							<div className="flex-1"><h4>Mes composants</h4></div>
						</div>
					</div>
				}
				content={
					<div className="p-24">
						{content}
					</div>
				}
				leftSidebarContent={
					<div className="p-24">
						<h4>Choisissez un composant</h4>
						<br/>
						<List dense={true}>
							<ListItem button>
								<ListItemText primary="Back Button" onClick={() => this.setState({content:'BackButton'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="Search input" onClick={() => this.setState({content:'SearchInput'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="Home Button" onClick={() => this.setState({content:'HomeButton'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="Submit Button" onClick={() => this.setState({content:'SubmitButton'})} />
							</ListItem>
                <ListItem button>
								<ListItemText primary="Switch Button" onClick={() => this.setState({content:'SwitchButton'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="Name Input" onClick={() => this.setState({content:'NameInput'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="Phone Number Input" onClick={() => this.setState({content:'PhoneNumberInput'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="New Product" onClick={() => this.setState({content:'NewProduct'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="Add New Item" onClick={() => this.setState({content:'AddNewItem'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="Swiper List" onClick={() => this.setState({content:'SwiperList'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="Selectable Address" onClick={() => this.setState({content:'SelectableAddress'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="AccordeonContainer" onClick={() => this.setState({content:'AccordeonContainer'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="AlertDialog" onClick={() => this.setState({content:'AlertDialog'})} />
							</ListItem>
							<ListItem button>
								<ListItemText primary="Notification" onClick={() => this.setState({content:'Notification'})} />
							</ListItem>
							
						</List>
					</div>
				}
				onRef={instance => {
					this.pageLayout = instance;
				}}
			/>
		)
	};
}

export default withStyles(styles, {withTheme: true})(Components);