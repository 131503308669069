import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Comment, Tooltip, List, Spin, Avatar } from 'antd';
import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from 'prop-types';

import config from 'config';

import avatarPlaceholder from '../../../static/icons/jpeg/yy.jpg';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      minWidth: 32,
    },
  },
  comment: {
    fontSize: config.fontSizes.h4,
    fontStyle: 'italic',
  },
});

class AbacaComment extends PureComponent {
  static propTypes = {
    comment: PropTypes.string,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    comment: '',
    onDelete: () => {},
  };

  getBetterDateTime = date => {
    return (
      <Tooltip title={date.format('DD/MM/YYYY HH:mm:ss')}>
        <span>{date.fromNow()}</span>
      </Tooltip>
    );
  };

  getDeleteAction = id => {
    const { onDelete } = this.props;

    return [
      <span key={`comment-list-delete-${id}`} onClick={() => onDelete(id)}>
        Supprimer
      </span>,
    ];
  };

  constructor(props) {
    super(props);
    moment.locale('fr');
  }

  render() {
    const { classes, comment, isLoading } = this.props;

    return (
      <div className={classNames(classes.root, 'abaca-comment-wrapper')}>
        <Spin spinning={isLoading} size="large">
          <List
            className="comment-list"
            header={null}
            itemLayout="horizontal"
            dataSource={[{ comment }]}
            renderItem={item => {
              return (
                <li>
                  <Comment
                    actions={this.getDeleteAction()}
                    // author={item.author}
                    avatar={<Avatar
                      src={avatarPlaceholder}
                      alt="profile placeholder"
                    />}
                    content={item.comment}
                    // datetime={this.getBetterDateTime(item.date)}
                  />
                </li>
              );
            }}
          />
        </Spin>
      </div>
    );
  }
}

export default withStyles(styles)(AbacaComment);
