import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  title: {
    fontSize: 24,
  },
  abacaSection: {
    marginTop: 20,
  },
  abacaSubInfo: {
    fontSize: 16,
  },
  clientSection: {
    marginTop: 20,
    textAlign: 'right',
  },
  clientSubInfo: {
    fontSize: 16,
  },
  month: {
    marginTop: 15,
  },

  dataTable: {
    marginTop: 15,
    borderTop: 1,
  },
  typeOfMerch: {
    marginTop: 20,
    fontSize: 16,
  },
  itemDetails: {
    marginTop: 2,
    paddingLeft: 15,
    fontSize: 12,
  },

  globalTotal: {
    borderTop: 1,
    marginTop: 40,
    fontSize: 16,
    textAlign: 'right',
  },
  subTotal: {
    marginTop: 10,
  },
});

const PdfStockBill = ({ client, month, products, total }) => {
  console.log('PdfStockBill', client, month, products, total);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.title}>
          <Text>FACTURE</Text>
        </View>
        <View style={styles.abacaSection}>
          <Text>ABACA TRANSPORT</Text>
          <Text style={styles.abacaSubInfo}>6 chemin Alphonse Daudet</Text>
          <Text style={styles.abacaSubInfo}>06800, Cagnes sur Mer</Text>
          <Text style={styles.abacaSubInfo}>06 03 02 03 67</Text>
        </View>
        <View style={styles.clientSection}>
          <Text>Facturé à</Text>
          <Text style={styles.clientSubInfo}>{`${client}`}</Text>
          <Text style={styles.clientSubInfo}>[Adresse du client]</Text>
          <Text style={styles.clientSubInfo}>[Numéro tél du client]</Text>
        </View>

        <Text style={styles.month}>{`Pour le mois de ${month}`}</Text>

        <View style={styles.dataTable}>
          <View style={styles.typeOfMerch}>
            <Text>Marchandises réceptionnées:</Text>
            <View
              render={() =>
                products.receivedOnly.length !== 0 ? (
                  products.receivedOnly.map(product => (
                    <Text style={styles.itemDetails}>{`- ${
                      product.product.description
                    }  /  ${product.storage_occupation}m3  /  ${
                      product.stockInfo.ellapsedDays
                    } j de stockage = ${
                      product.stockInfo.timeCost
                    }€  /  1 passage à quai (5€)  /  Total: ${product.stockInfo.total.toFixed(
                      2,
                    )}€`}</Text>
                  ))
                ) : (
                  <Text style={styles.itemDetails}>Aucune</Text>
                )
              }
            />
          </View>
          <View style={styles.typeOfMerch}>
            <Text>Marchandises sorties:</Text>
            <View
              render={() =>
                products.deliveredOnly.length !== 0 ? (
                  products.deliveredOnly.map(product => (
                    <Text style={styles.itemDetails}>{`- ${
                      product.product.description
                    }  /  ${product.storage_occupation}m3  /  ${
                      product.stockInfo.ellapsedDays
                    } j de stockage = ${
                      product.stockInfo.timeCost
                    }€  /  1 passage à quai (5€)  /  Total: ${product.stockInfo.total.toFixed(
                      2,
                    )}€`}</Text>
                  ))
                ) : (
                  <Text style={styles.itemDetails}>Aucune</Text>
                )
              }
            />
          </View>
          <View style={styles.typeOfMerch}>
            <Text>Marchandises réceptionnées et sorties:</Text>
            <View
              render={() =>
                products.receivedAndDelivered.length !== 0 ? (
                  products.receivedAndDelivered.map(product => (
                    <Text style={styles.itemDetails}>{`- ${
                      product.product.description
                    }  /  ${product.storage_occupation}m3  /  ${
                      product.stockInfo.ellapsedDays
                    } j de stockage = ${
                      product.stockInfo.timeCost
                    }€  /  2 passages à quai (10€)  /  Total: ${product.stockInfo.total.toFixed(
                      2,
                    )}€`}</Text>
                  ))
                ) : (
                  <Text style={styles.itemDetails}>Aucune</Text>
                )
              }
            />
          </View>
          <View style={styles.typeOfMerch}>
            <Text>
              Marchandises toujours en stock depuis le mois précédent:
            </Text>
            <View
              render={() =>
                products.notReceivedAndNotDelivered.length !== 0 ? (
                  products.notReceivedAndNotDelivered.map(product => (
                    <Text style={styles.itemDetails}>{`- ${
                      product.product.description
                    }  /  ${product.storage_occupation}m3  /  ${
                      product.stockInfo.ellapsedDays
                    } j de stockage = ${
                      product.stockInfo.timeCost
                    }€  /  Total: ${product.stockInfo.total.toFixed(
                      2,
                    )}€`}</Text>
                  ))
                ) : (
                  <Text style={styles.itemDetails}>Aucune</Text>
                )
              }
            />
          </View>

          <View style={styles.globalTotal}>
            <View style={styles.subTotal}>
              <Text>Total des passages à quai:</Text>
              <Text>{`${total.crossDocking.toFixed(2)} €`}</Text>
            </View>
            <View style={styles.subTotal}>
              <Text>Total durée de stockage:</Text>
              <Text>{`${total.timeCost.toFixed(2)} €`}</Text>
            </View>
            <View style={styles.subTotal}>
              <Text>Coût total à facturer:</Text>
              <Text>{`${(total.timeCost + total.crossDocking).toFixed(
                2,
              )} €`}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfStockBill;
