import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Input } from 'antd';

import config from 'config';

const styles = theme => ({
  container: {
    '& label': {
      color: config.colors.greyDove,
    },
  },
});

class PhoneNumberInput extends Component {
  static defaultProps = {
    placeholder: 'Numéro de téléphone',
    label: 'Téléphone',
    isRequired: true,
    isError: false,
    helperText: '',
    onChange: () => {},
  };

  constructor() {
    super();
    this.state = {
      value: '',
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });

    this.props.onChange(event.target.value);
  };

  render() {
    const { classes, isRequired, isError, label, placeholder } = this.props;
    const { value } = this.state;

    return (
      <div className={classNames('phone-input-wrapper', classes.container)}>
        {this.props.label && <label>{this.props.label}:</label>}
        <Input
          type="tel"
          value={this.props.value}
          onChange={this.handleChange('value')}
          required={isRequired}
          label={label}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PhoneNumberInput);
