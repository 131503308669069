import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import SearchInput from 'components/SearchInput';
import HomeButton from 'components/HomeButton';
import store from 'static/icons/svg/store.svg';
import items from 'static/icons/svg/items.svg';
import calendar from 'static/icons/svg/calendar.svg';
import { Button } from '@material-ui/core';
import config from 'config/index';


const styles = theme => ({
    root: {
        minHeight: '100%',
    },
    searchWrapper: {
        color          : theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
    }
});

class Dashboard extends Component {

    render()
    {
      const {classes} = this.props;
      return (
        <div className={classNames('flex flex-col flex-1 items-stretch', classes.root)}>

          <div className="logo container mx-auto flex flex-1">          
            <Button onClick={() => this.props.history.push(config.pages.abaca.home)}>Homepage</Button>
            <img width="128" src="assets/images/logos/logo-v2-shadow.svg" alt="logo" />
          </div>

          <div className="flex-1">
            <div className={classNames('flex justify-center items-center w-full py-32', classes.searchWrapper)}>
              <SearchInput placeholder='Rechercher un client, une contremarque...' />
            </div>
          </div>

          <div className="flex flex-wrap my-8 justify-center">
            <HomeButton title={'CARNET DE COMMANDES'} width={'80px'} />
            <HomeButton icon={store} title={'CLIENTS'} description={'Voir les magasins clients, leurs livraisons et leurs volumes de stockage'} width={'85px'} />
            <HomeButton icon={items} title={'CONTREMARQUES'} description={'Voir les produits en attente,  accuser réception, et gestion de l\'entreposage'} width={'90px'} />
            <HomeButton icon={calendar} title={'PLANNING'} description={'Gérer l\'agenda des rendez-vous et des livraisons'} width={'90px'} />
          </div>

        </div>
      )
    }
}

export default withStyles(styles, {withTheme: true})(Dashboard);