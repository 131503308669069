import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { AsYouType } from 'libphonenumber-js';
import { Radio, Checkbox, Select, Form, Input, Icon } from 'antd';
import classNames from 'classnames';

// Components
import NameInput from './NameInput';
import PhoneNumberInput from './PhoneNumberInput';

// Config
import config from 'config';
import rules from 'config/rules';

import 'styles/components/autocomplete.css';
import 'styles/components/contact-data.css';


const FormItem = Form.Item
const Option = Select.Option

const styles = () => ({
  fieldset: {
    border: `1px solid ${config.colors.greyBorder}`,
    borderRadius: '5px',
    margin: '35px 0',
    padding: 20,
    boxShadow: `0px 1px 1px ${config.colors.greyDove}`,

    '& .legend': {
      borderBottom: `1px solid ${config.colors.greyBorder}`,
      padding: 10,
      fontWeight: 100,
      fontSize: config.fontSizes.h3,
    },
  },

  iconColor: { color: 'rgba(0,0,0,.25)' },

  contactWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    '& .name-input-wrapper:nth-child(2)': {
      marginLeft: 10,
    },
  },

  emailWrapper: {
    marginTop: 10,
  },

  adressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
  },

  label: {
    color: config.colors.greyDove,
  },

  radioGroup: {
    width: '100%',
    display: 'block !important',
  },

  street: {
    marginTop: '4px !important',
    '& .ant-form-item-children': {
      display: 'flex',
  
      '& > :nth-child(1)': {
        width: 'calc(23.333333% - 6px)',
        marginRight: '6px'
      },
  
      '& > :nth-child(2)': {
        width: '76.6666%'
      }

    }
  }
});

class ContactData extends Component {
  state = { address: '' };

  assocAddressFields = {
    street_number: 'number',
    route: 'way',
    locality: 'city',
    administrative_area_level_1: 'region',
    country: 'country',
    postal_code: 'postal_code',
  };

  addressFieldsTypes = {
    street_number: 'long_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
  };

  civilities = [
    { label: 'Monsieur', value: 1 },
    { label: 'Madame', value: 2 },
  ];

  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }

  componentDidMount() {
    const searchOptions = {
      types: ['geocode'],
      location: new global.google.maps.LatLng(43.6970823, 7.1456501),
      radius: 2000,
    };

    this.autocomplete = new global.google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      searchOptions,
    );

    this.autocomplete.setFields(['address_component', 'place_id']);
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);

    // Trigger a change at start with the initial civility value
    this.handleChange('civility', 1);
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    console.log('handlePlaceChanged', place);

    if (place && place.hasOwnProperty('address_components')) {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (this.addressFieldsTypes[addressType]) {
          let label = this.assocAddressFields[addressType];
          let value =
            place.address_components[i][this.addressFieldsTypes[addressType]];

          console.log('address', value, label);
          this.props.onChange('address', value, label);
        }
      }

      this.props.onChange('address.google_place_id', place.place_id);
    }
  }

  handleChange = (label, value) => {
    this.props.onChange(label, value);
  };

  getDefaultAddress = () => {
    const { address } = this.props;
    // Check for a filled address
    if (address && address.city) {
      return `${address.number}, ${address.way}, ${address.postal_code}, ${address.city}`;
    } else {
      return undefined;
    }
  };

  render() {
    const { classes, form } = this.props;
    const { getFieldDecorator } = form;

    const readOnly = form.getFieldValue('manual') === false

    return (
      <React.Fragment>
        <div className={classes.fieldset}>
          <h4 className="legend">Informations de contact</h4>

          <div className={classes.contactWrapper}>
            <label className={classNames('pr-8', classes.label)}>Civilité:</label>
            <Radio.Group defaultValue={1} className={classes.radioGroup} onChange={e => this.handleChange('civility', e.target.value)}>
              {this.civilities.map(civility => (
                <Radio.Button value={civility.value} className="w-1/2">{civility.label}</Radio.Button>
              ))}
            </Radio.Group>
          </div>

          <div className={classes.contactWrapper}>
            <NameInput
              value={this.props.firstname}
              label={'Prénom'}
              placeholder={'Prénom'}
              isRequired={false}
              isError={false}
              helperText={''}
              onChange={value => this.handleChange('firstname', value)}
            />

            <NameInput
              value={this.props.lastname}
              label={'Nom'}
              placeholder={'Nom'}
              isRequired={true}
              isError={false}
              helperText={''}
              onChange={value => this.handleChange('lastname', value)}
            />
          </div>

          <PhoneNumberInput
            value={new AsYouType('FR').input(this.props.phone_number)}
            label={'Téléphone'}
            placeholder={'Numéro de téléphone Français'}
            isRequired={false}
            isError={false}
            helperText={''}
            onChange={value => this.handleChange('phone_number', value)}
          />

          <div className={classes.emailWrapper}>
            <NameInput
              value={this.props.email}
              label={'Email'}
              placeholder={'Email'}
              isRequired={true}
              isError={false}
              helperText={''}
              onChange={value => this.handleChange('email', value)}
            />
          </div>
        </div>

        <div className={classes.fieldset}>
          <h4 className="legend">Adresse de livraison</h4>

          <div className={classes.adressWrapper}>
            <label className={classes.label}>Adresse:</label>
            <input
              ref={this.autocompleteInput}
              defaultValue={this.getDefaultAddress()}
              id="autocomplete"
              className="ant-input"
              placeholder="Entrer l'adresse"
              type="text"
              disabled={!readOnly}
            ></input>

            {getFieldDecorator('manual', {
              valuePropName: 'checked',
              initialValue: false
            })(
              <Checkbox onChange={(e) => this.handleChange('source', e.target.checked ? 'manual' : 'google')}>Renseigner l'adresse manuellement</Checkbox>
            )}

            { !readOnly && (
            <div className="address-manual">
              <FormItem hasFeedback>
                {getFieldDecorator('address[country]', {rules: rules.address.country})(
                  <Select
                    optionFilterProp="children"
                    placeholder="Pays *"
                    showSearch
                    allowClear={false}
                    disabled={readOnly}
                    notFoundContent="Aucun résultat"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="france">France</Option>
                    <Option value="monaco">Monaco</Option>
                    <Option value="belgium">Belgique</Option>
                    <Option value="switzerland">Suisse</Option>
                    <Option value="luxembourg">Luxembourg</Option>
                    <Option value="spain">Espagne</Option>
                    <Option value="italy">Italie</Option>
                    <Option value="england">Angleterre</Option>
                  </Select>
                )}
              </FormItem>
              <FormItem hasFeedback>
                {getFieldDecorator('address[postal_code]', {rules: rules.address.zip_code, validateFirst: true})(
                  <Input id="postal_code" prefix={<Icon type="compass" style={this.iconColor} />} disabled={readOnly} placeholder="Code postal *" autoComplete="off" />
                )}
              </FormItem>
              <FormItem hasFeedback>
                {getFieldDecorator('address[city]', {rules: rules.address.city, validateFirst: true})(
                  <Input id="city" prefix={<Icon type="environment" style={this.iconColor} />} disabled={readOnly} placeholder="Ville *" autoComplete="off" />
                )}
              </FormItem>
              <input type="hidden" name="address[country_code]" id="country_code" value={'FR'} disabled={readOnly} />
              <FormItem hasFeedback className={classes.street}>
                {getFieldDecorator('address[number]', {rules:rules.address.street, validateFirst: true})(
                  <Input id="street" prefix={<Icon type="number" style={this.iconColor} />} disabled={readOnly} placeholder="Numéro *" autoComplete="off" />
                )}
                {getFieldDecorator('address[way]', {rules:rules.address.street, validateFirst: true})(
                  <Input id="street" prefix={<Icon type="environment" style={this.iconColor} />} disabled={readOnly} placeholder="Rue *" autoComplete="off" />
                )}
              </FormItem>
            </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const ContactDataForm = Form.create({
  onFieldsChange(props, changedFields) {
    props.onChangeAddress(changedFields)
  },
})(ContactData)

export default withStyles(styles)(
  connect(null, null)(ContactDataForm),
);
