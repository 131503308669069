import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';

// Redux
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Config & services
import config from 'config';

const styles = () => ({
  hr: {
    height: 3,
    border: 0,
    marginBottom: 22,
    backgroundImage: `linear-gradient(to right, #fff, ${config.colors.yellowGoldenDream}, ${config.colors.yellowAnzac}, #fff)`,
  },
  formControl: {
    margin: 15,
    maxWidth: 200,
    [`@media(max-width: ${config.responsive.small}px)`]: {
      margin: 10,
    },
  },
  error: {
    color: config.colors.redFailed,
    textAlign: 'center',
  },
});

class ProductReceived extends React.Component {
  static propTypes = {
    onCloseDialog: PropTypes.func.isRequired,
    orderProductId: PropTypes.number.isRequired,
    subItemInfo: PropTypes.object.isRequired,
  };

  handleChange = (type, event) => {
    this.setState(
      {
        [type]: event.target.value.toString(),
        isFormError: false,
      },
      () => {
        
        const { updateReceivedProductStorageInfo } = this.props;
        const { cubicMeter } = { ...this.state };
        updateReceivedProductStorageInfo({
          stockageZone: this.state.stockageZone,
          cubicMeter: cubicMeter.toString().replace(',', '.'),
          numberOfPackages: this.state.numberOfPackages,
        });
      },
    );
  };

  handleConfirm = async () => {
    const { cubicMeter, numberOfPackages } = this.state;
    const atLeastOneAndOnlyDigits = /^\d{1,}$/;
    const floatNumbers = /^\d{1,}[\.,]\d{1,2}$/g;
    const comaFollowingStartingZero = /^0[,\.]\d{1,2}$/g;

    let isCubicMeterValid = false;
    const hasOnlyDigits = atLeastOneAndOnlyDigits.test(cubicMeter);
    if (hasOnlyDigits) {
      isCubicMeterValid =  cubicMeter.charAt(0) === '0' ? comaFollowingStartingZero.test(cubicMeter) : true;
    } else {
      isCubicMeterValid =  cubicMeter.charAt(0) === '0' ? comaFollowingStartingZero.test(cubicMeter) : floatNumbers.test(cubicMeter);
    }

    if (
      parseInt(numberOfPackages) > 0 &&
      isCubicMeterValid &&
      atLeastOneAndOnlyDigits.test(numberOfPackages)
    ) {
      const {
        orderProductId,
        confirmReceptionOfProduct,
        subItemInfo,
      } = this.props;

      confirmReceptionOfProduct(orderProductId);

      this.props.onCloseDialog();
      setTimeout(() => {
        this.props.openDialog({
          children: config.dialogContent.confirmUpload,
          orderProductId: orderProductId,
          subItemInfo: subItemInfo,
        });
      }, 200);
    } else {
      this.setState({ isFormError: true });
    }
  };

  handleCancel = () => {
    const { cancelReceptionOfProduct, orderProductId } = this.props;
    cancelReceptionOfProduct(orderProductId);
    this.props.onCloseDialog();
  };

  constructor(props) {
    super(props);

    this.state = {
      stockageZone: props.subItemInfo.storage_area || '',
      cubicMeter: props.subItemInfo.storage_occupation || '',
      numberOfPackages: props.subItemInfo.number_of_packages || '1',
      isFormError: false,
    };
  }

  render() {
    const { classes } = this.props;
    const {
      stockageZone,
      cubicMeter,
      numberOfPackages,
      isFormError,
    } = this.state;

    return (
      <div className="product-received-wrapper">
        <DialogTitle id="alert-dialog-title"> Réception produit </DialogTitle>

        <hr className={classes.hr} />

        <DialogContent>
          <FormControl className={classes.formControl}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="stockage-zone">Zone de stockage</InputLabel>
              <Input
                id="stockage-zone"
                placeholder={'Zone A'}
                value={stockageZone}
                onChange={event => this.handleChange('stockageZone', event)}
                inputProps={{
                  name: 'stockage',
                  id: 'stockage-simple',
                }}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="cubic-simple">Mètres cubes</InputLabel>
              <Input
                id="adornment-cubic"
                value={cubicMeter}
                onChange={event => this.handleChange('cubicMeter', event)}
                endAdornment={
                  <InputAdornment position="end">
                    m<sup>3</sup>
                  </InputAdornment>
                }
                inputProps={{
                  id: 'cubic-simple',
                  type: 'number',
                }}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="number-of-packages">
                Nombre de colis
              </InputLabel>
              <Input
                id="number-of-packages"
                value={numberOfPackages}
                onChange={event => this.handleChange('numberOfPackages', event)}
                inputProps={{
                  id: 'number-of-packages',
                  type: 'number',
                }}
              />
            </FormControl>
          </FormControl>

          {isFormError && (
            <div className={classes.error}>
              Vérifiez que tous les champs <br /> sont corrects
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCancel} color="default">
            Annuler
          </Button>
          <Button onClick={this.handleConfirm} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openDialog: Actions.openDialog,
      confirmReceptionOfProduct: Actions.confirmReceptionOfProduct,
      cancelReceptionOfProduct: Actions.cancelReceptionOfProduct,
      updateReceivedProductStorageInfo:
        Actions.updateReceivedProductStorageInfo,
    },
    dispatch,
  );
}

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(ProductReceived),
);
