import SelectStoreAbaca from './select-store-abaca';
import config from 'config';

export const SelectStoreAbacaConfig = {
  routes : [
    {
      path      : config.pages.abaca.selectStore,
      component : SelectStoreAbaca
    }
  ]
}