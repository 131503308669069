import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

// Conf & utils
import config from 'config';
import { checkIsOrderReady, getFullShippingAddress } from 'utils';

// components
import Pin from 'components/Pin';

const styles = () => ({
  touringItem: {
    border: `1px solid ${config.colors.greyBorder}`,
    padding: 10,
    marginBottom: 10,
    borderRadius: '0 0 25px 0',
    display: 'flex',
    boxShadow: '3px 3px 15px -8px grey',
  },
  orderReadyBorder: {
    backgroundColor: config.colors.greenSuccess,
    position: 'absolute',
    top: 0,
    left: 0,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    width: 5,
    height: '100%',
  },
  cell: {
    marginLeft: 20,
  },
  label: {
    color: config.colors.greySilver,
    fontSize: '0.9em',
  },
  large: {
    flexBasis: '33.33%',
  },
  medium: {
    flexBasis: '16.66%',
  },
  small: {
    flexBasis: '8.33%',
  },
  pinWrapper: {
    position: 'absolute',
    right: 7,
    bottom: 10,
  },
});

class TouringItem extends PureComponent {
  static defaultProps = {};

  state = {
    isOrderReady: false,
  };

  handleReadyOrder = () => {
    const { order } = this.props;

    this.setState({
      isOrderReady: checkIsOrderReady(order.products),
    });
  };

  componentDidMount() {
    this.handleReadyOrder();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.products !== this.props.products) {
      this.handleReadyOrder();
    }
  }

  getItemsCells = () => {
    const { order } = this.props;
    const { customer, shipping_address, products } = order;

    return [
      {
        label: 'Livraison à',
        value: customer ? `${customer.firstname} ${customer.lastname}` : 'n/a',
        size: 'medium',
      },
      {
        label: 'Où',
        value: shipping_address ? shipping_address.city : 'n/a',
        size: 'medium',
      },
      {
        label: 'Adresse complète',
        value: shipping_address
          ? getFullShippingAddress(shipping_address)
          : 'n/a',
        size: 'medium',
      },
      {
        label: 'Contremarques',
        value: products.length ? products : 'n/a',
        size: 'large',
      },
    ];
  };

  render() {
    const { classes, isPinned, onPinOrder, onUnpinOrder } = this.props;
    const { isOrderReady } = this.state;

    return (
      <div className={classes.touringItem}>
        <div className={isOrderReady ? classes.orderReadyBorder : undefined} />
        {this.getItemsCells().map(itemCell => {
          return (
            <div className={classnames(classes.cell, classes[itemCell.size])}>
              <div className={classes.label}>{itemCell.label}</div>
              {Array.isArray(itemCell.value) ? (
                itemCell.value.map(product => {
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${product.quantity}x ${
                          product.product.designation
                        } (${product.product.description})</br>`,
                      }}
                    />
                  );
                })
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: itemCell.value,
                  }}
                />
              )}
            </div>
          );
        })}
        <div className={classes.pinWrapper}>
          <Pin
            onClick={isPinned ? onUnpinOrder : onPinOrder}
            isActive={isPinned}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TouringItem);
