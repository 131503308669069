import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {FuseAnimate, FuseSelectedTheme} from '@fuse';
import classNames from 'classnames'

import notAllowed from '../static/icons/svg/notAllowed.svg';
import allowed from '../static/icons/svg/allowed.svg';

const styles = theme => ({
  submitButton: {
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
  }
});

class SubmitButton extends Component {
  static defaultProps = {
    title: 'Enregistrer',
    isAllowed: false,
    onClick: () => {},
    isLoading: false,
  };
  
  constructor() {
    super();

    this.state = {
      icon: notAllowed,
      bgColor: 'grey-lightest',
      cursor: 'default'
    }
  }

  allow = () => {
    this.setState({
      icon: allowed,
      bgColor: 'white',
      cursor: 'pointer'
    })
  }

  forbid = () => {
    this.setState({
      icon: notAllowed,
      bgColor: 'grey-lightest',
      cursor: 'default'
    })
  }

  componentDidMount() {
    if (this.props.isAllowed) {
      this.allow();
    }
  }

  componentDidUpdate(prevProps) {
    const { isAllowed, isLoading } = this.props;
    if (prevProps.isAllowed !== isAllowed) {
      isAllowed ? this.allow() : this.forbid();
    }

    if (prevProps.isLoading !== isLoading) {
      isLoading ? this.forbid() : this.allow();
    }
  }

  handleClick = (e) => {
    if (this.props.isAllowed) {
      this.props.onClick();
    }
  }

  render() {
    const { classes, isLoading }  = this.props

    return (
      <div 
        className='submit-button-wrapper' 
        style={{cursor: this.state.cursor, minWidth:'140px'}}  
        onClick={this.handleClick} 
      >
        <MuiThemeProvider theme={FuseSelectedTheme} >
          <FuseAnimate animation="transition.slideUpIn" delay={300}>
              <div className={classNames(`flex items-center w-full max-w-192 px-8 py-4 rounded-4 h-48 m-8 justify-around bg-${this.state.bgColor}`, classes.submitButton)} elevation={1}>
                <img src={this.state.icon} style={{width: '20px'}} alt='icon_img' />
                <span className="text-grey font-500 text-20" >{isLoading ? <div>Chargement...</div> : this.props.title}</span>
              </div>
          </FuseAnimate>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(withRouter(SubmitButton));