import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List } from 'antd';

// Components
import Title from 'components/Title';
import SubmitButton from 'components/SubmitButton';
import Stepper from 'components/Stepper';
import CardUpload from 'components/CardUpload';

// Misc
import config from 'config';
import requestService from 'services/requestService';
import Notification from 'services/Notification';
import { getShopById } from 'utils';

const listGrid = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 2,
  xl: 2,
  xxl: 2,
};

const styles = () => ({
  root: {
    height: '100vh',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepperWrapper: {
    marginTop: 15,
    maxWidth: config.responsive.medium,
    margin: 'auto',
  },
  uploadList: {
    marginTop: '45px !important',
  },
});

const { newStoreParam, pages } = config;

class NewStoreLegalsAbacaPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      storeId: '',
      error: false,
      CGV: null,
      LOGO: null,
      currentFiles: {
        cgvFileName: '',
        cgvFileDisplayUri: '',
        logoFileName: '',
        logoFileDisplayUri: '',
      },
    };
  }

  async componentDidMount() {
    const storeId = new URLSearchParams(window.location.search).get(
      newStoreParam.storeId,
    );
    const response = await new requestService().getStoreFiles(storeId);
    console.log(response.data.files);
    let currentFiles = {};
    if (response.data && response.data.files.length > 0) {
      const files = response.data.files;
      const cgvFile = files.find(
        file => file.type === config.legalDocumentsFileType.cgv,
      );
      const logoFile = files.find(
        file => file.type === config.legalDocumentsFileType.logo,
      );

      currentFiles = {
        cgvFileName: cgvFile && cgvFile.name,
        cgvFileDisplayUri: cgvFile && cgvFile.uri,
        logoFileName: logoFile && logoFile.name,
        logoFileDisplayUri: logoFile && logoFile.uri,
      };
    }

    const shop = await getShopById(storeId);

    this.setState({
      storeId: storeId,
      storeName: shop.brand_name,
      currentFiles,
    });
  }

  submitHandler = () => {
    const { history } = this.props;
    const { CGV, LOGO, storeId, storeName } = this.state;
    const files = [{ type: 'cgv', file: CGV }, { type: 'logo', file: LOGO }];

    try {
      files.forEach(async item => {
        if (item && item.file) {
          let formData = new FormData();
          formData.append('name', item.file.name);
          formData.append('store_id', storeId);
          formData.append('type', item.type);
          formData.append('files[]', item.file);

          await new requestService().postFile(formData);
        }
      });

      Notification.display({
        title: 'Documents légaux sauvegardés 😸',
        content: 'Redirection sur la page du magasin dans quelques instants...',
        level: 'SUCCESS',
      });

      setTimeout(() => {
        history.push(
          `${pages.abaca.storeMenu}?${newStoreParam.storeName}=${storeName}&${
            newStoreParam.storeId
          }=${storeId}`,
        );
      }, 3000);
      
    } catch (error) {
      this.setState({ error: error.toString() });
    }
  };

  setFile = (file, title) => {
    this.setState({ [title]: file });
  };

  render() {
    const { classes } = this.props;
    const { error, currentFiles } = this.state;

    return (
      <div className={classes.root}>
        <Title
          text={error ? 'Erreur' : 'CGV & Logo'}
          helperTxt={error || ''}
          isLarge={true}
        />

        {!error && (
          <div className={classes.formWrapper}>
            <div className={classes.stepperWrapper}>
              <Stepper current={3} steps={config.stepper.newStore} />
            </div>

            <List
              className={classes.uploadList}
              grid={listGrid}
              dataSource={[
                {
                  title: 'CGV',
                  currentFileName: currentFiles.cgvFileName || '',
                  fileDisplayUri: currentFiles.cgvFileDisplayUri || '',
                  description: 'Télécharger les conditions générales de vente',
                },
                {
                  title: 'LOGO',
                  currentFileName: currentFiles.logoFileName || '',
                  fileDisplayUri: currentFiles.logoFileDisplayUri || '',
                  description: 'Télécharger le logo de la marque / magasin',
                },
              ]}
              renderItem={cardUpload => (
                <List.Item>
                  <CardUpload
                    key={cardUpload.title}
                    title={cardUpload.title}
                    description={cardUpload.description}
                    onFileSelected={file =>
                      this.setFile(file, cardUpload.title)
                    }
                    currentFileName={cardUpload.currentFileName}
                    displayUri={cardUpload.fileDisplayUri}
                  />
                </List.Item>
              )}
            />

            <SubmitButton
              title="Terminer"
              isAllowed={true}
              onClick={this.submitHandler}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(NewStoreLegalsAbacaPage);
