import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import {FuseAnimate, FuseSelectedTheme} from '@fuse';
import Notification from 'services/Notification'
import Button from '@material-ui/core/Button';

const styles = theme => ({

});

class NotificationSample extends Component {
  static defaultProps = {

 };

  notice = () => {
	  Notification.display({content: 'Coucou, tvvmb', title: 'HELLO', level: 'INFO'});
  }

  success = () => {
      Notification.display({content: 'Tu as gagné ton poids en malabar crick-crock', title: 'Bravo', level: 'SUCCESS'});
  }

  modal = () => {
      Notification.display({content: 'You #FAILS', title: 'null', level: 'ERROR'});
  }

  render() {

    return (
      <div className='notification-sample-wrapper' style={{cursor:'pointer'}} >
        <Button variant="contained" color="primary" onClick={this.notice}>
          Notice
        </Button>
        <Button variant="contained" color="default" onClick={this.success}>
          Success
        </Button>
        <Button variant="contained" color="default" onClick={this.modal}>
          Modal
        </Button>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(withRouter(NotificationSample));