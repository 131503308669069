const mainColors = {
  light: '#EFDD36',
  dark: '#DDC23D',
  highContrastSuccess: '#44c95b',
  lowContrastSuccess: '#f1ffe9',
  highContrastFailed: '#ef3934',
  lowContrastFailed: '#fff6f3',
  highContrastProvider: '#2f86eb',
  lowContrastProvider: '#E2F2FF',
};

export default {
  // #region ----------- COLORS & STYLES
  colors: {
    yellowGoldenRod: '#c1b033',
    yellowGoldenDream: mainColors.light,
    yellowAnzac: mainColors.dark,
    yellowMilkPunch: '#FFF8D5',
    pinkSalmon: mainColors.lowContrastFailed,
    bluePattens: mainColors.lowContrastProvider,
    greenTea: mainColors.lowContrastSuccess,
    greenSuccess: '#66C43E',
    greenPastelSuccess: '#BDFFDA',
    redFailed: '#FC100D',
    greySeashell: '#F1F1F1',
    greyGallery: '#EFEFEF',
    greyBorder: '#dedede',
    greySilver: '#c0c0c0',
    greyDove: 'rgba(0, 0, 0, 0.54)',
    blackMineShaft: '#2D2D2D',
  },
  gradients: {
    main: `linear-gradient(to right, ${mainColors.light}, ${
      mainColors.light
    }, ${mainColors.dark})`,
    success: `linear-gradient(to right, ${mainColors.lowContrastSuccess}, ${
      mainColors.lowContrastSuccess
    }, ${mainColors.highContrastSuccess})`,
    failed: `linear-gradient(to right, ${mainColors.lowContrastFailed}, ${
      mainColors.lowContrastFailed
    }, ${mainColors.highContrastFailed})`,
    provider: `linear-gradient(to right, ${mainColors.lowContrastProvider}, ${
      mainColors.lowContrastProvider
    }, ${mainColors.highContrastProvider})`,
  },
  fontSizes: {
    large: '3.2rem',
    h1: '2.4rem',
    h2: '2rem',
    h3: '1.8rem',
    h4: '1.4rem',
    p: '1.2rem',
    small: '1rem',
    extraSmall: '0.8rem',
  },
  responsive: {
    extraLarge: 1920,
    large: 1280,
    medium: 960,
    small: 600,
  },
  // #endregion
  // #region ----------- VARIABLES
  stockPrices: {
    perMeterPerMonth: 11,
    crossDocking: 5,
  },
  minimumAdvancePaymentPercentage: 25,
  // #endregion
  // #region ----------- ENDPOINTS
  endpoints: {
    login: '/user/login',
    postUser: '/user',
    putUser: '/user/:id',
    loginAs: '/user/login-as',
    getProducts: '/products',
    postProduct: '/product',
    putProduct: '/product/:id',
    getOrders: '/orders',
    postOrder: '/order/',
    putOrder: '/order/:id',
    showBill: '/order/:id/bill', // get
    displayBill: '/order/:id/bill/display', // get
    deleteComment: '/order/comment/:id',
    postComment: '/order/comment/:id',
    getStores: '/stores',
    getStoreFiles: '/store/:id/files?type[]=logo&type[]=cgv',
    postStore: '/store',
    getStoreUsers: '/store/:id/users', // ?role[]=staff&role[]=owner
    postCustomer: '/customer',
    getCustomers: '/customers',
    getAddress: '/address/customer/:id',
    deleteAddress: '/address/:id',
    putAddress: '/address/:id',
    postAddress: '/address',
    getProviders: '/providers',
    postProvider: '/provider',
    postOrderStatus: '/order_status',
    getFiles: '/files/',
    postFile: '/file',
    showFile: '/file/:id/display',
    deleteFile: '/file/:id',
    putOrderProduct: '/order_product/:id',
    getHomeSuggestions: '/search/:text',
    getCategories: '/categories',
    postCategory: '/category',
  },
  // #endregion
  // #region ----------- PATH / PAGES
  pages: {
    store: {
      home: '/',
      orders: '/orders',
      newOrder: '/orders/new',
      summary: '/orders/summary',
      customer: '/customers/',
      newCustomer: '/customers/new',
      addresses: '/adresses/',
      selectAddress: '/addresses/select',
      documents: '/countermarks/documents', // param ?productId=
      stock: '/stocks',
      newStock: '/stocks/new',
    },
    abaca: {
      home: '/admin/',
      orders: '/admin/orders',
      summary: '/admin/orders/summary',
      selectStore: '/admin/stores/select',
      storeMenu: '/admin/stores/menu',
      newStore: '/admin/stores/new',
      newStoreOwner: '/admin/store/owner',
      newStoreSeller: '/admin/stores/seller',
      newStoreLegals: '/admin/stores/legals',
      volume: '/admin/stores/storage-details', // param ?store=
      documents: '/admin/countermarks/documents', // param ?productId=
      products: '/admin/countermarks/',
      stock: '/admin/stocks',
      newStock: '/admin/stocks/new',
    },
  },
  // #endregion
  // #region ----------- STEPPER
  stepper: {
    newOrder: [
      { title: 'Contact', description: 'Créer un nouveau client' },
      { title: 'Produits', description: 'Renseigner les produits à livrer' },
      { title: 'Adresse', description: "Sélectionner l'adresse de livraison" },
      {
        title: 'Récap',
        description: 'Vérifier les données et confirmer la livraison',
      },
    ],
    newStore: [
      { title: 'Magasin', description: 'Créer un nouveau Magasin client' },
      { title: 'Gérant', description: 'Créer un gérant pour ce magasin' },
      { title: 'Vendeur', description: 'Ajouter des vendeurs' },
      { title: 'CGV & logo', description: 'Ajouter les informations légales' },
    ],
  },
  // #endregion
  // #region ----------- ENUMS
  origins: {
    store: 'store',
    abaca: 'abaca',
  },
  orderStatus: {
    paused: 'paused',
    provider_ordered: 'provider_ordered',
    in_progress: 'in_progress',
    canceled: 'canceled',
    delivered: 'delivered',
    ready: 'ready',
  },
  contactDataTypes: {
    newCustomer: 'newCustomer',
    management: 'management',
  },
  itemCellSizes: {
    large: 'large',
    medium: 'medium',
    small: 'small',
  },
  commenterSteps: {
    showingComment: 'showingComment',
    addComment: 'addComment',
    typeComment: 'typeComment',
  },
  dialogContent: {
    switchStatus: 'switchStatus',
    productReceived: 'productReceived',
    confirmUpload: 'confirmUpload',
    providerOrder: 'providerOrder',

    customerContact: 'customerContact',
    orderDelivery: 'orderDelivery',
  },
  receivedInputsType: {
    cubicMeter: 'cubicMeter',
    stockageZone: 'stockageZone',
  },
  productStatusCodes: {
    MUST_BE_ORDERED: 'must_be_ordered',
    RECEIVED: 'received',
    AVAILABLE_AT_STORE: 'available_at_store',
    DELIVERED: 'delivered',
  },
  backHomeNewOrderParams: {
    sent: 'sent',
    canceled: 'canceled',
  },
  backHomeNewShopParam: {
    ok: 'ok',
  },
  newStoreParam: {
    storeId: 'storeId',
    storeName: 'storeName',
    ownerUuid: 'ownerUuid',
  },
  sellerPageStatus: {
    idle: 'idle',
    modify: 'modify',
    new: 'new',
  },
  legalDocumentsFileType: {
    cgv: 'cgv',
    logo: 'logo',
  },
  authRoles: {
    admin: 'admin',
    owner: 'owner',
    staff: 'staff',
    guest: 'guest',
  },
  authRolesNames: {
    admin: 'Administrateur',
    owner: 'Gérant',
    staff: 'Vendeur',
    guest: 'Invité',
  },
  searchSections: {
    customers: 'Clients',
    orders: 'Commandes',
    order_products: 'Contremarques',
  },
  paymentMethods: {
    card: 'Carte bancaire',
    check: 'Chèque',
    transfer: 'Virement',
    cash: 'Espèces',
  },
  orderListMode: {
    accordeon: 'accordeon',
    touring: 'touring',
  },
};
//#endregion
