import {combineReducers} from 'redux';
import fuse from './fuse';
import abaca from './abaca';
import auth from 'auth/store/reducers';
import quickPanel from 'main/quickPanel/store/reducers';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        abaca,
        quickPanel,
        ...asyncReducers
    });

export default createReducer;
