import Error404 from './Error404';

export const Error404Config = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/errors/error-404',
            component: Error404
        }
    ]
};
