import { notification as NotificationLib, Modal } from 'antd'
import _ from 'lodash'

import 'antd/lib/notification/style/css';
import 'antd/lib/modal/style/css';

const defaultSettings = {contentType: 'native', noTitle: false}
const defaultOptions =  {className: '', duration: 4.5}
const Notification = {
  instance: null,
  type: null,
  key: null,

  // https://tools.ietf.org/html/rfc5424
  levels: {
    DEBUG    : 'Information technique', 
    INFO     : 'Information générale', 
    CRITICAL : 'Information critique',
    ALERT    : 'Alerte',
    NOTICE   : 'Notification',
    ERROR    : 'Erreur',
    SUCCESS  : 'Information valide',
  },


  // Specific display function
  display: function({content, title, level = 'INFO', isModal = true, options = defaultOptions}, settings = {...defaultSettings}) {
    let type = 'info'

    let _level = level
    switch(settings.contentType) {
      default:
      case 'native':
        break
      case 'modal':
      case 'iframe':
        _level = 'MODAL'
        break
    }

    switch (_level) {
      // Niveau ok à valide
      case 'INFO': case 'NOTICE': case 'SUCCESS':

        if (level === 'SUCCESS') {
            type = 'success'
        }
        
        this.type = 'notification'
        NotificationLib[type]({
          message: title || _.capitalize(this.levels[level]),
          description: content,
          ...options,
        });     

        break;

      // Niveau d'alerte à critique ou modal/iframe
      case 'CONFIRM':
        type = 'confirm'
      case 'MODAL':
      default:

        let delay = false
        if (this.instance) {
          this.hide()
          delay = true
        }

        if (type !== 'confirm') {
          type = level === 'ALERT' ? 'warning' : (_level === 'MODAL' ? _.lowerCase(level) : 'error')
        }
        
        let _class = options.className + ' item-center-both item-center-wrapper'
                                           
        if (settings.noTitle === true) {
          _class += ' no-title'
        }

        this.type = 'modal'

        let display = () => {
          this.instance = Modal[type]({
            title: title || _.capitalize(this.levels[level]),
            content: content,
            maskClosable: !isModal,
            ...options,
            className: _class,
          });
        }

        if (delay === true) {
          setTimeout(display, 500)
        } else {
          display()
        }
        break;
    }

    return this.instance
  },
  
  hide: function() {
    if (this.instance) {
      this.instance.destroy()
      this.instance = null
    }
  }
}

export default Notification