import React, { Component } from 'react';
import { Table, Input, Button, Icon, Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import { withStyles } from '@material-ui/core/styles';
import _cloneDeep from 'lodash/cloneDeep';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import { connect } from 'react-redux';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';

// Components
import Title from 'components/Title';
import ProductStatusDisplayer from 'components/ProductStatusDisplayer';

// Helpers & Config
import config from 'config/index';
import { getAllOrdersList, putOrderProduct } from 'utils';

const conf = config.productStatusCodes;

const styles = theme => ({
  productListPage: {},
  spinner: {
    textAlign: 'center',
    marginTop: 100,
  },
  tableWrapper: {
    marginTop: 50,
    paddingBottom: 70,
    paddingLeft: 20,
    paddingRight: 20,
    '& .eye-wrapper': {
      padding: 10,
    },
    '& .document-cell-wrapper': {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      '& span': {
        marginBottom: 10,
      },
    },
  },
});

class ProductList extends Component {
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Chercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Chercher
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      let path = record[dataIndex];
      if (dataIndex.split('.').length > 1) {
        // TODO can't make a search in deep nested category
      }
      return (
        path &&
        path
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => {
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text && text.toString()}
        />
      );
    },
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnProductFilterProps = () => ({
    filters: [
      { text: 'En attente', value: conf.MUST_BE_ORDERED },
      { text: 'Reçu', value: conf.RECEIVED },
      { text: 'En boutique', value: conf.AVAILABLE_AT_STORE },
      { text: 'Livré', value: conf.DELIVERED },
    ],
    filterMultiple: false,
    sortOrder: 'descend',
    onFilter: (value, record) => record.status === value,
    render: (text, record, index) => {
      return (
        <ProductStatusDisplayer
          status={record.status}
          onSwitchChange={isRightValueChecked =>
            this.handleSwitchChange(isRightValueChecked, record)
          }
        />
      );
    },
  });

  handleSwitchChange = (isRightValueChecked, record) => () => {
    const description = record.product.description;
    const provider = record.product.provider.name;
    const id = record.id;

    if (!isRightValueChecked) {
      // The product isn't received yet
      // Trigger the receiving process
      this.setState({ productIdToBeReceived: id });
      this.props.openDialog({
        children: config.dialogContent.productReceived,
        orderProductId: id,
        subItemInfo: { description, provider },
      });
    }
  };

  storeAllOrders = async () => {
    const { storeAllOrders } = this.props;

    try {
      const response = await getAllOrdersList();
      if (response && response.data) {
        const orders = response.data;
        storeAllOrders(orders);
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };

  setCountermarks = () => {
    const { allOrdersList } = this.props;

    let countermarks = [];
    const clonedAllOrderList = _cloneDeep(allOrdersList);
    clonedAllOrderList.forEach(order => {
      order.products.forEach(product => {
        // Add the storeBrandname to the product object
        product = {
          ...product,
          storeBrandname: order.store.brandname,
        };
        countermarks.push(product);
      });
    });

    console.log(countermarks);

    this.setState({
      products: countermarks,
      isLoading: false,
    });
  };

  updateOrderProductReceptionWithStorageInfo = async () => {
    const {
      receivedProductStorageInfo,
      clearCanceledReceptionProductId,
      storeAllOrders,
    } = this.props;
    const { productIdToBeReceived } = this.state;

    if (productIdToBeReceived && receivedProductStorageInfo.cubicMeter) {
      await putOrderProduct(
        productIdToBeReceived,
        receivedProductStorageInfo.stockageZone,
        receivedProductStorageInfo.cubicMeter,
      );

      // An order product has been updated
      // Update the countermark list
      const response = await getAllOrdersList();
      if (response && response.data) {
        const orders = response.data;
        storeAllOrders(orders);
      }

      clearCanceledReceptionProductId();
    }
  };

  resetToBeReceivedProductId = () => {
    this.setState({ productIdToBeReceived: '' });
  };

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      pagination: {
        pageSize: 15,
        current: 1,
      },
      products: [],
      error: false,
      isLoading: true,
      productIdToBeReceived: '',
    };
  }

  async componentDidMount() {
    const { allOrdersList } = this.props;

    if (allOrdersList.length === 0) {
      await this.storeAllOrders();
    }

    this.setCountermarks();
  }

  componentDidUpdate(prevProps) {
    const {
      confirmedReceivedProductId,
      canceledReceptionProductId,
      clearCanceledReceptionProductId,
    } = this.props;
    const { productIdToBeReceived } = this.state;

    const hasReceptionProductBeenCanceled =
      prevProps.canceledReceptionProductId !== canceledReceptionProductId &&
      canceledReceptionProductId === productIdToBeReceived;

    const hasReceptionProductBeenConfirmed =
      prevProps.confirmedReceivedProductId !== confirmedReceivedProductId &&
      confirmedReceivedProductId === productIdToBeReceived;

    if (hasReceptionProductBeenCanceled) {
      this.resetToBeReceivedProductId();
      clearCanceledReceptionProductId();
    } else if (hasReceptionProductBeenConfirmed) {
      this.updateOrderProductReceptionWithStorageInfo(productIdToBeReceived);
    }

    const hasOrdersBeenUpdated =
      prevProps.allOrdersList !== this.props.allOrdersList;
    if (hasOrdersBeenUpdated) {
      this.setCountermarks();
    }
  }

  componentDidCatch(error) {
    this.setState({ error: error });
  }

  render() {
    const { classes } = this.props;
    const { products, error, isLoading } = this.state;

    const columns = [
      // {
      //   title: 'Image',
      //   dataIndex: 'product.photo_src',
      //   key: 'photo_src',
      //   render: (text, record, index) => (
      //     <img style={{ width: 60, height: 60 }} src={record.photo_src} />
      //   ),
      // },
      {
        title: 'Désignation',
        dataIndex: 'product.designation',
        key: 'designation',
        ...this.getColumnSearchProps('designation'),
      },
      {
        title: 'Description',
        dataIndex: 'product.description',
        key: 'description',
        ...this.getColumnSearchProps('description'),
      },
      {
        title: 'Documents',
        dataIndex: 'document',
        key: 'document',
        render: (text, record, index) => {
          return (
            <div className="document-cell-wrapper">
              <Button
                type="primary"
                onClick={() => {
                  this.props.history.push(
                    `${config.pages.abaca.documents}?productId=${
                      record.id
                    }&designation=${record.product.designation}&provider=${
                      record.product.provider.name
                    }`,
                  );
                }}
                icon="eye"
                size="small"
              >
                Voir
              </Button>
            </div>
          );
        },
      },
      {
        title: 'Prix',
        dataIndex: 'price',
        key: 'price',
        ...this.getColumnSearchProps('price'),
      },
      {
        title: 'Fournisseur',
        dataIndex: 'product.provider.name',
        key: 'provider',
        ...this.getColumnSearchProps('provider'),
      },
      {
        title: 'Magasin demandeur',
        dataIndex: 'storeBrandname',
        key: 'storeBrandname',
        ...this.getColumnSearchProps('storeBrandname'),
      },
      {
        title: 'Statut',
        dataIndex: 'status',
        key: 'status',
        ...this.getColumnProductFilterProps(),
      },
    ];

    return (
      <div className={classes.productListPage}>
        {error ? (
          <Title
            text="Erreur"
            helperTxt={'Impossible de récupérer la liste des contremarques :('}
            subtitle={String(error)}
          />
        ) : (
          <>
            <Title
              text={'contremarques'.toUpperCase()}
              subtitle={null}
              isLarge={true}
              helperTxt={null}
            />
            {isLoading ? (
              <div className={classes.spinner}>
                <CircularProgress size={68} />
              </div>
            ) : (
              <div className={classes.tableWrapper}>
                <Table
                  columns={columns}
                  dataSource={products}
                  pagination={{
                    pageSize: this.state.pagination.pageSize,
                    position: 'both',
                  }}
                  onChange={() => {}}
                  rowKey={record => `${record.id} ${record.created_at}`}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openDialog: Actions.openDialog,
      storeAllOrders: Actions.storeAllOrders,
      clearCanceledReceptionProductId: Actions.clearCanceledReceptionProductId,
    },
    dispatch,
  );
}

const mapStateToProps = ({ abaca, fuse }) => ({
  allOrdersList: abaca.orders.all,
  products: abaca.products,
  confirmedReceivedProductId: fuse.dialog.confirmedReceivedProductId,
  receivedProductStorageInfo: fuse.dialog.receivedProductStorageInfo,
  canceledReceptionProductId: fuse.dialog.canceledReceptionProductId,
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProductList),
);
