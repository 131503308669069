import React, { Component } from 'react'
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import BackButton from 'components/BackButton';
import config from 'config/index';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import 'styles/index.css';

const styles = () => ({
  initialRoot: {

    maxWidth       : config.responsive.small,
    position       : 'sticky',
    top            : '0px',
    zIndex         : '2',
    backgroundColor: 'white',
    margin         : 'auto',
    padding        : '0px 10px 10px',
    '&.largeState': {
      minWidth: '100%'
    }, 
  },
  shadow: {
    boxShadow: '0px 1px 8px rgba(200, 200, 200, 0.1)', 
  },
  backWrapper: {
    position: 'absolute',
    top: '8px',
    left: '-48px',
    [`@media(max-width: ${config.responsive.small}px)`]: {
      position: 'initial'
    },
    '&.largeState': {
      left: 20,
    }, 
  },
  titleWrapper: {
    display        : 'flex',
    flexDirection  : 'column',
    justifyContent : 'center',
    alignItems     : 'center',
    paddingTop     : '10px', 
    '& .text': {
      fontFamily: 'Poppins',
      fontSize: config.fontSizes.large,
      fontWeight: 700,
      color: config.colors.greyDove
    },
    '& .helperTxt': {
      fontSize: config.fontSizes.h2
    }
  },
  subtitleWrapper: {
    textAlign      :'center', 
    marginTop      : '10px',
    fontSize       : config.fontSizes.h3,
    marginBottom   : '0px',
  },
  hr: {
    height: '4px',
    border: 0,
    marginBottom: 0,
    backgroundImage: `linear-gradient(to right, #fff, ${config.colors.yellowGoldenDream}, ${config.colors.yellowAnzac}, #fff)`
  },
});

class Title extends Component {

  onScroll() {
    const { hasJustbeenMounted } = this.state;

    // Avoid onScoll to be active from start
    if (!hasJustbeenMounted) {
      this.setState({
        isScrollActive: true
      })
    } else {
      this.setState({
        hasJustbeenMounted: false
      })
    }
  }

  constructor(props) {
    super(props) 
    this.state = {
      isScrollActive: false,
      hasJustbeenMounted: true
    }
    
    this.onScroll = this.onScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true)
  }

  render() {
    const { classes, backRoute, text, subtitle, isLarge, helperTxt, onBackClick } = this.props;
    const { isScrollActive } = this.state;
    const isLargeState = isLarge ? 'largeState' : 'initial';

    return (
      <div className={classNames(classes.initialRoot, isLargeState, isScrollActive && classes.shadow)} >
  
        <div className={classNames(classes.backWrapper, isLargeState) } >
          <BackButton 
            route={ backRoute } 
            isManual={ onBackClick !== null }
            onBackClick={ onBackClick } />
        </div>
  
        <div className={classes.titleWrapper}>           
          <span className="text" >{ text }</span>
          <span className="helperTxt" > { helperTxt } </span>
        </div>
  
        <hr className={classes.hr} />
          
        { subtitle &&
          <p className={classes.subtitleWrapper} >{ subtitle }</p> }
  
      </div>    
    )
  }
}


Title.defaultProps = {
  subtitle: '',
  helperTxt: '',
  backRoute: null,
  isLarge: true,
  onBackClick: null
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  helperTxt: PropTypes.string,
  backRoute: PropTypes.string,
  isLarge: PropTypes.bool,
  onBackClick: PropTypes.func, // Override what the goBack button should do
}

export default withStyles(styles)(withRouter(Title));