import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import config from '../config';

const styles = () => ({
  hidden: {
    display: 'none',
  },
  show: {
    display: 'block',
  },
  goldenText: {
    color: config.colors.yellowAnzac,
    fontWeight: 600,
  },
  idleLabel: {
    color: config.colors.greySilver,
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      width: 50,
    },
  },
});

class OrderListModeSwitcherButton extends PureComponent {
  static defaultProps = {};

  state = {};

  render() {
    const {
      classes,
      imageSrcActive,
      imageSrc,
      onItemClick,
      label,
      isItemActive,
    } = this.props;

    return (
      <div className={classes.itemWrapper} onClick={onItemClick}>
        <img
          className={classnames(
            { [classes.show]: isItemActive },
            { [classes.hidden]: !isItemActive },
          )}
          src={imageSrcActive}
        />
        <img
          className={classnames(
            { [classes.hidden]: isItemActive },
            { [classes.show]: !isItemActive },
          )}
          src={imageSrc}
        />
        <span
          className={classnames(
            { [classes.goldenText]: isItemActive },
            { [classes.idleLabel]: !isItemActive },
          )}
        >
          {label}
        </span>
      </div>
    );
  }
}

export default withStyles(styles)(OrderListModeSwitcherButton);
