import StoreMenuAbaca from './store-menu-abaca';
import config from 'config';

export const StoreMenuAbacaConfig = {
  routes: [
    {
      path: config.pages.abaca.storeMenu,
      component: StoreMenuAbaca,
    },
  ],
};
