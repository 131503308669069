export const SET_ERROR_ACTION = '[COMMON] SET_ERROR_ACTION';
export const REMOVE_ERRORS_ACTION = '[COMMON] REMOVE_ERRORS_ACTION';

export function setErrorAction(payload = null) {
  return {
    type: SET_ERROR_ACTION,
    payload,
  };
}

export function removeErrorsAction() {
  return {
    type: REMOVE_ERRORS_ACTION,
  };
}
