import VolumeAbaca from './volume-abaca';
import config from 'config';

export const VolumeAbacaConfig = {
  routes : [
    {
      path      : config.pages.abaca.volume,
      component : VolumeAbaca
    }
  ]
}