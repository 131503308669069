import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEqual from 'lodash/isEqual';

// Store
import * as Actions from 'store/actions/abaca';

// Components
import SubmitButton from 'components/SubmitButton';
import ProductData from 'components/ProductData';
import Title from 'components/Title';
import Stepper from 'components/Stepper';

// Config
import config from 'config';

// Helpers
import {
  getCustomerById,
  getProviderByName,
  getCategoryByName,
  isNewProductsValid,
} from 'utils';

// Services
import requestService from 'services/requestService';

const actions = {
  newOrder: {
    title: 'NOUVELLE COMMANDE',
    subtitle: 'Renseignez les produits à livrer',
  },
  newStock: {
    title: 'NOUVELLE COMMANDE DE STOCK',
    subtitle: 'Renseignez les produits à ajouter au stock',
  },
};

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto',
    marginTop: 0,
  },
  form: {
    maxWidth: '100%',
  },
  stepperWrapper: {
    maxWidth: config.responsive.medium,
    margin: '15px auto 60px',
    boxShadow: '0px -1px 0 0 #e8e8e8 inset',
  },
});

class NewOrderPage extends Component {
  action = 'newOrder';

  checkDataValidity = () => {
    const { products } = this.state;
    const isValid = isNewProductsValid(products);
    return isValid;
  };

  submitHandler = async () => {
    const { customerData, products, doOrder } = this.state;
    const { user } = this.props;

    this.setState({ isLoading: true });
    console.log('products ====> ', products);

    const isCreationSuccess = await this.createProducts(products);

    if (isCreationSuccess) {
      var link;
      if (this.action === 'newOrder') {
        link = `${config.pages.store.selectAddress}?customerId=${
          customerData.id
        }`;
      } else {
        link = `${
          config.pages[user.role === 'admin' ? 'abaca' : 'store'].summary
        }`;

        if (!doOrder) {
          link += '?doOrder=false';
        }
      }

      this.props.history.push(link);
    }
  };

  createProducts = async products => {
    const {
      allProvidersList,
      storeOrderProduct,
      allCategoriesList,
      user,
    } = this.props;

    for (let i = 0; i < products.length; i++) {
      // If the product's provider is unknown, create a new one
      const provider = getProviderByName(
        products[i].provider,
        allProvidersList,
      );
      let providerId = provider ? provider.id : null;
      if (provider === null) {
        try {
          const providerToCreate = {
            name: products[i].provider,
            mobile_number: 0,
            phone_number: 0,
          };
          const response = await new requestService().postProvider(
            providerToCreate,
          );
          // Retrieve its id
          providerId = response.data.provider.id;
        } catch (error) {
          console.log(error);
          this.setState({ error: error.toString() });
          return false;
        }
      }

      // If the category's product is unknown, create a new one
      let categoryId = null;
      if (products[i].category && products[i].category.length) {
        const category = getCategoryByName(
          products[i].category,
          allCategoriesList,
        );
        categoryId = category ? category.id : null;
        if (category === null) {
          try {
            const categoryToCreate = {
              store_id: user.store_id,
              name: products[i].category,
            };
            const response = await new requestService().postCategory(
              categoryToCreate,
            );
            // Retrieve its id
            categoryId = response.data.category.id;
          } catch (error) {
            console.log(error);
            this.setState({ error: error.toString() });
            return false;
          }
        }
      }

      // If the product is unknown, create one
      const isNewProduct = !products[i].isDisabled;
      let product = null;

      if (isNewProduct) {
        const productToCreate = {
          provider_id: providerId,
          category_id: categoryId,
          designation: products[i].designation,
          price: products[i].price,
          description: products[i].description,
        };

        try {
          const response = await new requestService().postProduct(
            productToCreate,
          );

          product = response.data.product;
        } catch (error) {
          this.setState({ error: error.toString() });
          return false;
        }
      } else {
        product = products[i];
      }

      // Add order product infos to product object
      Object.assign(product, {
        quantity: products[i].quantity,
        mustBeOrdered: products[i].mustBeOrdered,
        provider: products[i].provider,
        category: products[i].category,
      });

      // Store product waiting for final submit
      product.isNewProvider = false;
      product.isNewCategory = false;
      product.isDisabled = true;
      storeOrderProduct(product);
    }

    return true;
  };

  constructor(props) {
    super(props);

    this.state = {
      target: 'customer',

      customerName: '',
      customerData: {},
      products: [
        {
          designation: '',
          description: '',
          provider: '',
          category: '',
          price: '',
          mustBeOrdered: false,
        },
      ],
      error: null,
      doOrder: true,
      isLoading: false,
      preFillDesignation: '',
    };

    this.action = document.location.pathname.match(/stock/)
      ? 'newStock'
      : 'newOrder';
  }

  async componentDidMount() {
    const {
      storeAllProductList,
      storeAllProvidersList,
      storeAllCategoriesList,
      storeAllCustomers,
      storeNewOrderAction,
      user,
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);

    try {
      // Get all customers
      const response = await new requestService().getCustomers();
      const allCustomers = response && response.data;
      allCustomers && storeAllCustomers(allCustomers);

      if (urlParams.get('customerId')) {
        console.log('urlParams', urlParams);
        const customer = await getCustomerById(
          urlParams.get('customerId'),
          allCustomers,
        );
        this.setState({
          customerName: `${customer.firstname} ${customer.lastname}`,
          customerData: customer,
        });
      } else if (['admin', 'owner'].includes(user.role)) {
        this.action = 'newStock';
        this.setState({
          target: 'stock',
        });
      } else {
        throw new Error('customerId must be loaded');
      }
    } catch (error) {
      console.log(error);
      this.setState({
        error:
          "Impossible de récupérer l'identifiant client, celui-ci est erroné, ou un problème serveur est survenu",
      });
    }

    try {
      const response = await new requestService().getProducts(true);

      if (response.data && response.status) {
        response.data = response.data.sort((a, b) => {
          return a.designation.toLowerCase() > b.designation.toLowerCase()
            ? 1
            : -1;
        });

        storeAllProductList(response);
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({ error: 'Erreur lors de la récupération des produits' });
    }

    try {
      const providers = await new requestService().getProviders();
      storeAllProvidersList(providers);
    } catch (error) {
      this.setState({
        error: 'Erreur lors de la récupération des fournisseurs',
      });
    }

    try {
      const categories = await new requestService().getCategories();
      storeAllCategoriesList(categories);
    } catch (error) {
      this.setState({
        error: 'Erreur lors de la récupération des catégories de produits',
      });
    }

    storeNewOrderAction(this.action);
    this.setProductInStateFromProps();

    const preFillDesignation = urlParams.get('designation');
    if (preFillDesignation) {
      this.setState({ preFillDesignation });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { products } = this.state;

    // Check if action is new stock and all the products are already in stock
    if (this.action === 'newStock' && !_isEqual(prevState.products, products)) {
      let doOrder = false;
      for (let i in products) {
        if (products[i].mustBeOrdered === true) {
          doOrder = true;
        }
      }

      this.setState({
        doOrder,
      });
    }
  }

  setProductInStateFromProps = () => {
    const { storedNewOrderProducts, clearNewOrderProducts } = this.props;
    let propsProductsToInit = [
      {
        designation: '',
        description: '',
        provider: '',
        category: '',
        price: '',
        mustBeOrdered: false,
      },
    ];

    if (storedNewOrderProducts.length !== 0) {
      propsProductsToInit = storedNewOrderProducts.map(product => ({
        designation: product.designation,
        description: product.description,
        provider: product.provider,
        category: product.category,
        price: product.price,
        mustBeOrdered: product.mustBeOrdered,
        isDisabled: product.isDisabled,
        quantity: product.quantity,
        id: product.id,
        isNewProvider: product.isNewProvider,
        isNewCategory: product.isNewCategory,
      }));
    }

    this.setState({
      products: propsProductsToInit,
    });

    clearNewOrderProducts();
  };

  render() {
    const { classes, user } = this.props;
    const {
      products,
      customerName,
      error,
      target,
      doOrder,
      isLoading,
      preFillDesignation,
    } = this.state;

    const steps = Stepper.getConfig(this.action, target, user.role);

    const current = this.action === 'newStock' ? 0 : 1;

    const submitTitle = doOrder ? 'Suite' : 'Valider';

    return (
      <div className={classes.root}>
        {!error && (
          <>
            <Title
              text={customerName.toUpperCase()}
              helperTxt={actions[this.action].title}
              isLarge
            />

            <div className={classes.stepperWrapper}>
              <Stepper current={current} steps={steps} />
            </div>

            <form className={classes.form}>
              <ProductData
                items={products}
                onProductChange={items => this.setState({ products: items })}
                action={this.action}
                preFillDesignation={preFillDesignation}
              />

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SubmitButton
                  title={submitTitle}
                  isAllowed={this.checkDataValidity()}
                  onClick={this.submitHandler}
                  isLoading={isLoading}
                />
              </div>
            </form>
          </>
        )}

        {error && <Title text="Erreur" subtitle={error} />}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      storeOrderProduct: Actions.storeOrderProduct,
      storeAllProductList: Actions.storeAllProductList,
      storeAllProvidersList: Actions.storeAllProvidersList,
      storeAllCategoriesList: Actions.storeAllCategoriesList,
      storeAllCustomers: Actions.storeAllCustomers,
      clearNewOrderProducts: Actions.clearNewOrderProducts,
      storeNewOrderAction: Actions.storeNewOrderAction,
    },
    dispatch,
  );
}

function mapStateToProps({ abaca, auth }) {
  return {
    allProvidersList: abaca.products.allProvidersList,
    allCategoriesList: abaca.products.allCategoriesList,
    user: auth.user,
    storedNewOrderProducts: abaca.newOrder.products,
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewOrderPage),
);
