import NewOrderPage from './new-order';
import config from 'config';

export const NewOrderConfig = {
  routes  : [
    {
      path     : config.pages.store.newOrder,
      component: NewOrderPage
    },
  ]
};
