import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import moment from 'moment';
import { Divider, Table, Form, DatePicker, Icon, Input } from 'antd';
import EditableCell from 'components/EditableCell';
import frFR from 'antd/lib/date-picker/locale/fr_FR';
import FormService from 'services/Form';

// Redux
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Config
import config from 'config';
// Utils
import { updateProviderOrder } from 'utils';

const editableContext = EditableCell.getEditableContext()

const EditableRow = ({ form, index, ...props }) => (
  <editableContext.Provider value={form}>
    <tr {...props} />
  </editableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

const FormItem = Form.Item

const styles = () => ({
  hr: {
    height: 3,
    border: 0,
    marginBottom: 22,
    backgroundImage: `linear-gradient(to right, #fff, ${config.colors.yellowGoldenDream}, ${config.colors.yellowAnzac}, #fff)`,
  },
  formControl: {
    margin: 15,
    [`@media(max-width: ${config.responsive.small}px)`]: {
      margin: 10,
    },
  },
  error: {
    color: config.colors.redFailed,
    textAlign: 'center',
  },
  providerName: {
    color: config.colors.yellowAnzac,
  }
})

class ProviderOrder extends React.Component {
  static defaultProps = {
    onCloseDialog: () => {},
    order: null,
  }

  static propTypes = {
    onCloseDialog: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      providerProducts: {},
      providerInfos: {},
      isFormError: false,
    };

    this.columns = [
      {
        title: 'Désignation',
        dataIndex: 'designation',
      },
      {
        title: 'Qté',
        dataIndex: 'quantity',
      },
      {
        title: 'Prix unitaire',
        dataIndex: 'provider_price',
        editable: true,
      },
      // {
      //   title: 'Date de livraison',
      //   dataIndex: 'delivery_date',
      //   editable: true,
      // },
    ];

    this.dateFormat = 'DD/MM/YYYY';

    this.form = new FormService(this.props.form)
  }

  componentDidMount() {
    const {
      order: {
        products
      }
    } = this.props;

    let providerProducts = {};
    let providerInfos = {};

    products.forEach(orderProduct => {
      if (!providerProducts.hasOwnProperty(orderProduct.product.provider.name)) {
        providerProducts[orderProduct.product.provider.name] = [];
        providerInfos[orderProduct.product.provider.id] = {
          provider_voucher_number: orderProduct.provider_voucher_number,
          provider_predicted_delivery_at: orderProduct.provider_predicted_delivery_at,
        };
      }

      let data = {
        key: orderProduct.id,
        designation: orderProduct.product.designation,
        quantity: orderProduct.quantity,
        provider_price: orderProduct.provider_price ? orderProduct.provider_price : orderProduct.price,
        provider_id: orderProduct.product.provider.id,
        provider_name: orderProduct.product.provider.name,
      }

      providerProducts[orderProduct.product.provider.name].push(data);
    })

    this.setState({
      providerProducts,
      providerInfos,
    });
  }

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('values', values)
        this.setState({
          providerInfos: values.providerInfos
        }, () => {
          this.handleConfirm();
        });

        
      }
    });
  };

  handleConfirm = async () => {

    const { onCloseDialog } = this.props;

    let orderProducts = [];
    _map(this.state.providerProducts, _orderProducts => {
      _orderProducts.forEach(orderProduct => {
        orderProducts.push({
          id: orderProduct.key,
          provider_price: orderProduct.provider_price,
          ...this.state.providerInfos[orderProduct.provider_id],
          provider_predicted_delivery_at: this.state.providerInfos[orderProduct.provider_id].provider_predicted_delivery_at.format('YYYY-MM-DD')
        })
      })
    })

    // API CALL
    const updateProviderOrderResponse = await updateProviderOrder(orderProducts);
    if (updateProviderOrderResponse) {
      onCloseDialog();
      
      this.handleCancel();
    }
  };

  handleCancel = () => {
    this.props.closeDialog({onCloseDialog: null});
  };

  handleSave = row => {
    const newOrderProducts = this.state.providerProducts[row.provider_name];
    const index = newOrderProducts.findIndex(item => row.key === item.key);
    const item = newOrderProducts[index];
    newOrderProducts.splice(index, 1, {
      ...item,
      ...row,
    });

    this.setState({
      providerProducts: {
        ...this.state.providerProducts,
        [row.provider_name]: newOrderProducts
      }
    });
  };

  render() {
    const {
      classes
    } = this.props;

    const { getFieldDecorator } = this.props.form;

    const { providerProducts, providerInfos } = this.state

    const {
      isFormError,
    } = this.state;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    
    return (
      <div className="provider-order-wrapper">
        <DialogTitle id="alert-dialog-title">Commande fournisseur</DialogTitle>
        <div className="italic px-24 pb-12 text-center">Remplir les champs ci-dessous pour valider<br /> le nouveau status de commande fournisseur</div>

        <hr className={classes.hr} />

        <DialogContent>

          <Form layout="inline" onSubmit={this.handleSubmit}>
          {_map(providerProducts, (orderProducts, providerName) => (
            <React.Fragment key={providerName}>
              <Divider orientation="left">
                <span className={classes.providerName}>{providerName}</span>
              </Divider>

              <div className="flex">
              <FormItem hasFeedback label="Numéro de commande fournisseur">
                {getFieldDecorator(`providerInfos[${orderProducts[0].provider_id}][provider_voucher_number]`, {rules: [
                  {required: true, message: 'Numéro de commande obligatoire'}
                ], initialValue: providerInfos[orderProducts[0].provider_id].provider_voucher_number, validateFirst: true})(
                  <Input prefix={<Icon type="folder" />} placeholder="BA12345" autoComplete="off" />
                )}
              </FormItem>
            
              <FormItem hasFeedback label="Date de livraison prévue">
                {getFieldDecorator(`providerInfos[${orderProducts[0].provider_id}][provider_predicted_delivery_at]`, {
                  rules: [
                    {required: true, message: 'Date obligatoire'},
                    {required: true, type: 'date', validator: this.form.validateField, message: ['Date non valide']}
                  ], initialValue: providerInfos[orderProducts[0].provider_id].provider_predicted_delivery_at && moment(providerInfos[orderProducts[0].provider_id].provider_predicted_delivery_at) || null, validateFirst: true})(
                  <DatePicker
                    locale={frFR}
                    format={this.dateFormat}
                    disabledDate={this.disabledDate}
                  />
                )}
              </FormItem>
              </div>

              <br />
              <Table
                components={components}
                rowClassName={() => 'editable-row'}
                columns={columns}
                dataSource={orderProducts}
                size="middle"
                pagination={false}
              />
            </React.Fragment>
          )
        )}
        </Form>

        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCancel} color="default">
            Annuler
          </Button>
          <Button onClick={this.handleSubmit} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </div>
    );
 }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeDialog: Actions.closeDialog,
    },
    dispatch,
  );
}

const ProviderOrderForm = Form.create()(ProviderOrder)

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(ProviderOrderForm),
);
