const abaca = {
    50  : '#FFFFFF',
    100 : '#e7e0ad',
    200 : '#9EA1A9',
    300 : '#9EA1A9',
    400 : '#cbbb43',
    500 : '#EFDD36',
    600 : '#dfc92a',
    700 : '#DDC23D',
    800 : '#ddc513',
    900 : '#d8bf0b',
    A100: '#e1c73c',
    A200: '#9EA1A9',
    A400: '#5C616F',
    A700: '#2D323E'
};

export default abaca;