import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import _deburr from 'lodash/deburr';
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import HomeButton from 'components/HomeButton';

// Utils & Config & Services
import { isMobileByUserAgent } from 'utils';
import config from 'config/index.js';
import Notification from 'services/Notification';
import requestService from 'services/requestService';

// Icons
import store from 'static/icons/png/store.png';
import product from 'static/icons/png/product.png';
import man from 'static/icons/png/greyManIcon.png';
import plus from 'static/icons/png/plus.png';
import plusStock from 'static/icons/png/stock.png';
import order from 'static/icons/png/order.png';

// Styles
import 'styles/components/autosuggest.css';

const styles = theme => ({
  root: {
    height: '90vh',
    justifyContent: 'space-between',
    '& .logo-search-wrapper': {
      marginTop: 70,
      '& .logo.container': {
        textAlign: 'center',
      },
    },
  },
  brandName: {
    fontSize: config.fontSizes.h2,
    fontWeight: 300,
    color: config.colors.yellowGoldenDream,
    margin: '10px 0',
  },
  searchWrapper: {
    color: theme.palette.primary.contrastText,
    backgroundImage: `linear-gradient(to right, ${
      config.colors.yellowGoldenDream
    }, ${config.colors.yellowGoldenDream}, ${config.colors.yellowAnzac})`,
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  suggestItems: {
    cursor: 'pointer',
    padding: '10px 20px',
    fontSize: config.fontSizes.h3,
    alignItems: 'center',
    '& .phone': {
      fontSize: config.fontSizes.h4,
    },
  },
  diaryWrapper: {
    position: 'absolute',
    top: 15,
    right: 25,
  },
  marginWrapper: {
    marginLeft: '10px',
  },
});

class HomeAbaca extends Component {
  getSuggestions = async value => {
    const inputValue = _deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength < 3) {
      return [];
    }

    let response;
    try {
      response = await new requestService().getHomeSuggestions(value);
    } catch (error) {
      throw new Error(error);
    }

    var count = 10;
    let rows = _map(response.data, (collection, table) => {
      let length = count - collection.length > 0 ? collection.length : count;
      count -= length;

      console.log('forEach', collection, table, length);

      return {
        title: table,
        suggestions: collection.slice(0, length),
      };
    }).filter(section => section.suggestions.length > 0);

    console.log('rows', rows);
    return rows;
  };

  getSuggestionValue = suggestion => {
    console.log('getSuggestionValue', suggestion);
    return suggestion.lastname
      ? `${suggestion.firstname} ${suggestion.lastname}`
      : suggestion.voucher_number;
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const { classes, user } = this.props;

    const key = suggestion.lastname
      ? 'lastname'
      : suggestion.voucher_number
        ? 'voucher_number'
        : 'provider_voucher_number';

    const matches = match(suggestion[key], query);
    const parts = parse(suggestion[key], matches);
    console.log('renderSuggestion', suggestion, query, matches, parts);

    let icon =
      key === 'lastname' ? man : key === 'voucher_number' ? order : product;

    return (
      <div className={classNames('flex justify-between', classes.suggestItems)}>
        <img src={icon} style={{ marginRight: 5, maxWidth: 25 }} alt="icon" />
        <div className="flex justify-between w-full">
          <div>
            {parts.map((part, index) => {
              return part.highlight ? (
                <span key={String(index)} style={{ fontWeight: 600 }}>
                  {part.text}
                </span>
              ) : (
                <strong key={String(index)} style={{ fontWeight: 300 }}>
                  {part.text}
                </strong>
              );
            })}
            {suggestion.firstname && <span>, {suggestion.firstname}</span>}
            {suggestion.mobile_number && (
              <div className="phone">{suggestion.mobile_number}</div>
            )}
          </div>
          {user.role === 'admin' && (
            <span className="float-right text-xs">
              {suggestion.store.brand_name}
            </span>
          )}
        </div>
      </div>
    );
  };

  handleSuggestionsFetchRequested = async ({ value }) => {
    console.log('handleSuggestionsFetchRequested', value);
    let suggestionsValue = await this.getSuggestions(value);

    this.setState({
      suggestions: suggestionsValue,
    });
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    event.stopPropagation();
    if (!suggestion.id) {
      return false;
    }

    const key = suggestion.lastname
      ? 'lastname'
      : suggestion.voucher_number
        ? 'voucher_number'
        : 'provider_voucher_number';
    var link;
    switch (key) {
      default:
      case 'lastname':
        link = `${config.pages.store.customer}?customerId=${suggestion.id}`;
        break;

      case 'voucher_number':
        link = `${config.pages.store.orders}?orderId=${suggestion.id}`;

        if (suggestion.customerId) {
          link += `&customerId=${suggestion.customer_id}`;
        }
        break;
    }

    this.props.history.push(link);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderSectionTitle = section => {
    return (
      <div className="px-24 py-12 italic">
        {config.searchSections[section.title]}
      </div>
    );
  };

  getSectionSuggestions = section => {
    return section.suggestions;
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      searchValue: newValue,
    });
  };

  handleReturnFromNewOrder = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderStatus = urlParams.get('order');
    const updateShopStatus = urlParams.get(config.backHomeNewShopParam.ok);
    if (!orderStatus && !updateShopStatus) {
      return null;
    }
    if (orderStatus === config.backHomeNewOrderParams.sent) {
      if (urlParams.get('stock')) {
        Notification.display(
          {
            title: 'Nouveau stock',
            content: 'Votre ajout de stock été prise en compte',
            level: 'SUCCESS',
          },
          {
            contentType: 'modal',
          },
        );
      } else {
        // If an order has just been sent notify a success !
        Notification.display(
          {
            content: 'Votre demande de livraison a bien été prise en compte',
            title: "C'est parti 😸",
            level: 'SUCCESS',
          },
          {
            contentType: 'modal',
          },
        );
      }
    } else if (orderStatus === config.backHomeNewOrderParams.canceled) {
      Notification.display({
        content: 'La demande de livraison a été annulée',
        title: 'Annulation',
        level: 'NOTICE',
      });
    } else if (updateShopStatus === config.backHomeNewShopParam.ok) {
      // Update Shop info success
      Notification.display({
        title: 'Sauvegarde',
        content: 'Informations de boutique mise à jour',
        level: 'SUCCESS',
      });
    }

    // Clean url
    this.props.history.replace(config.pages.store.home);
  };

  constructor(props) {
    super(props);
    this.state = {
      suggestionData: [],
      searchValue: '',
      suggestions: [],
      isUserOnMobile: false,
    };

    this.handleSuggestionsFetchRequested = _debounce(
      this.handleSuggestionsFetchRequested,
      600,
      { leading: true },
    );
  }

  componentDidMount() {
    if (isMobileByUserAgent()) {
      this.setState({ isUserOnMobile: true });
    }

    this.handleReturnFromNewOrder();
  }

  render() {
    const { classes, isAdmin, user } = this.props;
    const { isUserOnMobile } = this.state;

    const autosuggestProps = {
      multiSection: true,
      highlightFirstSuggestion: true,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.handleSuggestionSelected,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
      renderSectionTitle: this.renderSectionTitle,
      getSectionSuggestions: this.getSectionSuggestions,
    };

    return (
      <div
        className={classNames(
          'flex flex-col flex-1 items-stretch',
          classes.root,
        )}
      >
        <div className="logo-search-wrapper">
          {isUserOnMobile ? (
            <div style={{ minHeight: 290 }} />
          ) : (
            <div className="logo container mx-auto">
              <img
                width="128"
                src="assets/images/logos/logo-v2-shadow.svg"
                alt="logo"
              />
              <h1 className={classes.brandName}> Abaca System </h1>
            </div>
          )}
          <div className={classes.searchWrapper}>
            <Autosuggest
              {...autosuggestProps}
              inputProps={{
                placeholder: 'Rechercher le nom du client, le numéro du BL...',
                value: this.state.searchValue,
                onChange: this.handleChange,
                inputlabelprops: { shrink: true },
              }}
            />
          </div>
        </div>

        <div className="flex flex-wrap my-8 justify-center">
          {isAdmin ? (
            <>
              <HomeButton
                title={'COMMANDES'}
                description={
                  'Gestion et visualisation de toutes les demandes de livraison en cours'
                }
                onClick={() =>
                  this.props.history.push(config.pages.abaca.orders)
                }
              />
              <div className={classes.marginWrapper}>
                <HomeButton
                  icon={store}
                  title={'CLIENTS'}
                  description={
                    'Voir les magasins clients, leurs livraisons et leurs volumes de stockage'
                  }
                  onClick={() =>
                    this.props.history.push(config.pages.abaca.selectStore)
                  }
                />
              </div>
              <div className={classes.marginWrapper}>
                <HomeButton
                  icon={product}
                  title={'CONTREMARQUES'}
                  description={
                    'Voir les produits en attente, accuser réception, charger des documents...'
                  }
                  onClick={() =>
                    this.props.history.push(config.pages.abaca.products)
                  }
                />
              </div>
              <div className={classes.marginWrapper}>
                <HomeButton
                  icon={plusStock}
                  title={'STOCK'}
                  description={'Voir et Ajouter votre Stock'}
                  onClick={() =>
                    this.props.history.push(config.pages.abaca.stock)
                  }
                />
              </div>
            </>
          ) : (
            <>
              <HomeButton
                title={'COMMANDES'}
                description={
                  "Suivre les commandes en cours de livraison ou voir l'historique"
                }
                onClick={() =>
                  this.props.history.push(config.pages.store.orders)
                }
              />
              <div className={classes.marginWrapper}>
                <HomeButton
                  icon={plus}
                  title={'NOUVEAU CLIENT'}
                  description={'Créer un nouveau client pour sa commande'}
                  width={'70px'}
                  onClick={() =>
                    this.props.history.push(config.pages.store.newCustomer)
                  }
                />
              </div>
              {user.role !== config.authRoles.staff && (
                <div className={classes.marginWrapper}>
                  <HomeButton
                    icon={plusStock}
                    title={'STOCK'}
                    description={'Voir et Ajouter votre Stock'}
                    onClick={() =>
                      this.props.history.push(config.pages.store.stock)
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

export default withRouter(
  withStyles(styles)(
    connect(
      mapStateToProps,
      null,
    )(HomeAbaca),
  ),
);
