import React, {Component} from 'react'
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles/index';
import {Card, CardContent, Typography} from '@material-ui/core';
import classNames from 'classnames';
import {FuseAnimate} from '@fuse';
import RegularLoginTab from './tabs/RegularLoginTab';

const styles = theme => ({
    root : {
        background    : "url('/assets/images/backgrounds/abackground.jpg') no-repeat", // /assets/images/backgrounds/dark-material-bg.jpg
        backgroundSize: 'cover',
    },
    intro: {
        color: '#ffffff'
    },
    card : {
        width   : '100%',
        maxWidth: 400
    }
});

class Login extends Component {
    render()
    {
        const {classes} = this.props;

        return (
            <div className={classNames(classes.root, "flex flex-col flex-auto flex-no-shrink items-center justify-center p-32")}>

                <div className="flex flex-col items-center justify-center w-full">

                    <FuseAnimate animation="transition.expandIn">

                        <Card className={classes.card}>

                            <CardContent className="flex flex-col items-center justify-center p-32">

                                <img className="w-64 m-32" src="assets/images/logos/logo-v2-shadow.svg" alt="logo"/>

                                <Typography  className="mt-16 mb-32">Authentification</Typography>

                                <RegularLoginTab/>

                            </CardContent>
                        </Card>
                    </FuseAnimate>
                </div>
            </div>
        )
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(Login));
