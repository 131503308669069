import * as Actions from '../../actions/abaca';

const initialState = {
  all: [],
  selected: {},
};

const customers = function(state = initialState, action) {
  switch (action.type) {
    case Actions.STORE_ALL_CUSTOMERS: {
      return {
        ...state,
        all: [...action.payload],
      };
    }

    case Actions.SET_SELECTED_CUSTOMER: {
      return {
        ...state,
        selected: action.payload,
      };
    }

    case Actions.CLEAR_CUSTOMERS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default customers;
