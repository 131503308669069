export const STORE_ALL_FILES = '[FILES] STORE_ALL_FILES';
export const CLEAR_FILES = '[FILES] CLEAR_FILES';

export function storeAllFiles(payload = null) {
  return {
    type: STORE_ALL_FILES,
    payload,
  };
}

export function clearFiles() {
  return {
    type: CLEAR_FILES,
  };
}
