import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Redux
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Config & services
import config from 'config';

const styles = () => ({
  hr: {
    height: 3,
    border: 0,
    marginBottom: 22,
    backgroundImage: `linear-gradient(to right, #fff, ${config.colors.yellowGoldenDream}, ${config.colors.yellowAnzac}, #fff)`,
  },
});

class ConfirmUpload extends React.Component {
  static propTypes = {
    onCloseDialog: PropTypes.func.isRequired,
    orderProductId: PropTypes.number.isRequired,
    dialogOptions: PropTypes.object.isRequired,
  };

  handleConfirm = () => {
    const {
      onCloseDialog,
      history,
      orderProductId,
      dialogOptions,
    } = this.props;
    onCloseDialog();
    setTimeout(() => {
      history.push(
        `${config.pages.abaca.documents}?productId=${orderProductId}&designation=${dialogOptions.subItemInfo.description}&provider=${dialogOptions.subItemInfo.provider}`,
      );
    }, 200);
  };

  handleCancel = () => {
    this.props.onCloseDialog();
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="confirm-wrapper">
        <DialogTitle id="alert-dialog-title">
          Documents de réception
        </DialogTitle>
        <hr className={classes.hr} />

        <DialogContent>
          Souhaitez-vous charger les documents de réception pour ce produit ?
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCancel} color="default">
            plus tard
          </Button>
          <Button onClick={this.handleConfirm} color="primary">
            oui
          </Button>
        </DialogActions>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openDialog: Actions.openDialog,
    },
    dispatch,
  );
}

const mapStateToProps = ({ fuse }) => ({
  dialogOptions: fuse.dialog.options,
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmUpload)),
);
