import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Table } from 'antd';

// Config
import config from '../config';

const { Column, ColumnGroup } = Table;

const styles = () => ({});

class VolumeDetails extends PureComponent {
  static propTypes = {
    products: PropTypes.object.isRequired,
  };

  fillStateWithProducts(products) {
    const arrayOfArrays = Object.values(products);
    let uniqueArray = [];
    for (let i = 0; i < arrayOfArrays.length; i++) {
      uniqueArray.push(...arrayOfArrays[i]);
    }

    this.setState({ products: uniqueArray });
  }

  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    const { products } = this.props;

    if (products) {
      this.fillStateWithProducts(products);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.products !== this.props.products) {
      this.fillStateWithProducts(this.props.products);
    }
  }

  render() {
    const { products } = this.state;

    return (
      <div>
        <Table dataSource={products} rowKey="id">
          <Column
            title="Désignation"
            dataIndex="designation"
            key="designation"
            render={(text, record) => <span>{record.product.designation}</span>}
          />

          <Column
            title="Description"
            dataIndex="description"
            key="description"
            render={(text, record) => <span>{record.product.description}</span>}
          />

          <Column
            title="Volume"
            dataIndex="cubicMeter"
            key="cubicMeter"
            render={(text, record) => {
              return (
                <span>
                  {record.storage_occupation} m<sup>3</sup>
                </span>
              );
            }}
          />

          <ColumnGroup title="Coûts">
            <Column
              title="Docking"
              dataIndex="stockInfo.crossDocking"
              key="crossDocking"
              render={(text, record) => (
                <span>{record.stockInfo.crossDocking} €</span>
              )}
            />
            <Column
              title="Durée"
              dataIndex="stockInfo.timeCost"
              key="timeCost"
              render={(text, record) => (
                <span>{record.stockInfo.timeCost} €</span>
              )}
            />
            <Column
              title="Total"
              dataIndex="stockInfo.total"
              key="total"
              render={(text, record) => (
                <span>{record.stockInfo.total.toFixed(2)} €</span>
              )}
            />
          </ColumnGroup>

          <Column
            title="Statut"
            dataIndex="status"
            key="status"
            render={(text, record) => {
              let humanizedStatus = '';
              if (record.status === config.productStatusCodes.DELIVERED) {
                humanizedStatus = 'Sorti';
              } else {
                humanizedStatus = 'Au dépôt';
              }
              return <span>{humanizedStatus}</span>;
            }}
          />

          <Column
            title="Jours au dépôt"
            dataIndex="stockInfo.ellapsedDays"
            key="ellapsedDays"
            render={(text, record) => (
              <span>{record.stockInfo.ellapsedDays} j</span>
            )}
          />
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(VolumeDetails);
