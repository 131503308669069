import React from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer';

import PdfStockBill from 'components/PdfStockBill';

const PdfMaker = ({month, products, total, client}) => {
  
  return (
    <PDFDownloadLink 
      document={<PdfStockBill client={client} month={month} products={products} total={total} />} 
      fileName={`facture_stockage_${month}.pdf`}>
      {({ blob, url, loading, error }) => {
        console.warn('PDFDownloadLink ERROR: ',error)
        return loading ? 'Chargement du pdf...' : 'Télécharger la facture'}}
    </PDFDownloadLink>
  )
}

export default PdfMaker