import * as Actions from '../../actions/abaca';

const initialState = {
  products: [],
  selectedAddress: {},
  billAddress: {},
  orderCheckout: {},
  orderComment: '',
  action: null,
};

const newOrder = function(state = initialState, action) {
  switch (action.type) {
    case Actions.STORE_ORDER_PRODUCT: {
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    }

    case Actions.STORE_SELECTED_ADDRESS: {
      return {
        ...state,
        selectedAddress: { ...action.payload },
      };
    }

    case Actions.STORE_BILL_ADDRESS: {
      return {
        ...state,
        billAddress: { ...action.payload },
      };
    }

    case Actions.STORE_ORDER_CHECKOUT_DATA: {
      return {
        ...state,
        orderCheckout: { ...action.payload },
      };
    }

    case Actions.STORE_ORDER_COMMENT: {
      return {
        ...state,
        orderComment: action.payload,
      };
    }

    case Actions.CLEAR_ORDER_COMMENT: {
      return {
        ...state,
        orderComment: '',
      };
    }

    case Actions.CLEAR_STORE_ORDER: {
      return initialState;
    }

    case Actions.CLEAR_NEW_ORDER_PRODUCTS: {
      return {
        ...state,
        products: [],
      };
    }

    case Actions.STORE_NEW_ORDER_ACTION: {
      return {
        ...state,
        action: action.payload,
      };
    }

    default:
      return state;
  }
};

export default newOrder;
