import NewStoreLegalsAbaca from './new-store-legals-abaca';
import config from 'config';

export const NewStoreLegalsAbacaConfig = {
  routes: [
    {
      path: config.pages.abaca.newStoreLegals,
      component: NewStoreLegalsAbaca,
    },
  ],
};
