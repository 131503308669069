
import validator        from 'validator'
import moment           from 'moment'

class Form {
  constructor(form) {
    this.form = form
  }

  validateField = async (rule, value, callback) => {
    console.log('validateField', this)
    let form = this.form
    let ruleMessage = [].concat(rule.message)
          
    switch(rule.type) {
      default:
      case 'string':
        if (!validator.isAlphanumeric(value, 'fr-FR')) {
          return callback(ruleMessage)
        }
        break
        
      case 'date':
        let _date = moment(value, 'DD/MM/YYYY', true)

        if (rule.field === 'date') {
          if (!validator.isAfter(_date.format(), moment().format('YYYY-MM-DD'))) {
            return callback(ruleMessage)
          }
        }
        
        if (!_date.isValid() || !validator.isISO8601(_date.format())) {
          return callback(ruleMessage)
        }
        break;

      case 'time':
        ruleMessage = 'Heure non valide'
        if (!value) {
          return form.isFieldTouched('time') ? callback(ruleMessage) : callback()
        }

        let [hours, minutes] = value.format('HH:mm').split(':')

        if (form.getFieldValue('date') && form.getFieldValue('date').isValid() && moment().isSame(form.getFieldValue('date'), 'day')) {

          if (value.isSameOrBefore(moment(), 'minute') && [hours, minutes].join(':') > '04:00') {
            return callback('L\'heure ne peut être dans le passé')
          }
        } else if (hours > 23 || minutes > 59 || ([hours, minutes].join(':') > '04:00' && [hours, minutes].join(':') < '07:00')) {
          return callback(ruleMessage)
        }

        break
    }

    return callback()
  }


  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }
}

export default Form;