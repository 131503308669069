import * as Actions from '../../actions/abaca';

const initialState = [];

const filesReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.STORE_ALL_FILES: {
      return [...state, ...action.payload];
    }

    case Actions.CLEAR_FILES: {
      return initialState;
    }

    default:
      return state;
  }
};

export default filesReducer;
