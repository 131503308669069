import OrdersAbaca from './orders-abaca';
import config from 'config';

export const OrdersAbacaConfig = {
  routes : [
    {
      path      : config.pages.abaca.orders,
      component : OrdersAbaca
    }
  ]
}