import React, { Component } from 'react';
import { Drawer, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles/index';
import { DatePicker } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Component
import SwitchFilter from 'components/SwitchFilter';

// Store
import * as ActionsAbaca from 'store/actions';
import * as Actions from './store/actions/index';

// Config
import config from '../../config';

const { RangePicker } = DatePicker;

// Styles
const styles = theme => ({
  root: {
    width: 280,

    '& .subtitle': {
      color: config.colors.blackMineShaft,
    },

    '& .filters-header': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .headband': {
        width: '100%',
        textAlign: 'center',
        background: config.gradients.main,
        marginTop: 10,
        padding: '6px 0',
        boxShadow: '0px 2px 10px -6px',
        '& p': {
          color: 'white',
          fontSize: '2rem',
        },
      },
    },

    '& .filters-content': {
      marginTop: 10,
      '& .section-wrapper': {
        padding: 10,
      },
      '& .by-date': {
        '& .range-picker': {
          margin: '10px 0',
        },
      },
      '& .by-status': {
        '& .switches': {
          paddingLeft: 25,
          '& .switch-wrapper': {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '35px 0',
          },
        },
        '& .switches > div:first-of-type': {
          marginTop: 10,
        },
        '& .switches > div:last-of-type': {
          marginBottom: 0,
        },
      },
    },
  },
});

class QuickPanel extends Component {
  constructor(props) {
    super(props);
    this.onPickerChange = this.onPickerChange.bind(this);
    this.handleOnSwitchChange = this.handleOnSwitchChange.bind(this);
  }

  onPickerChange(dates) {
    const { setActiveFilters } = this.props;
    const from = dates[0];
    const to = dates[1];

    setActiveFilters({
      filterName: 'dates',
      filterValue: { from: from, to: to },
    });
  }

  handleOnSwitchChange(switchesState) {
    const { setActiveFilters } = this.props;
    setActiveFilters({ filterName: 'statuses', filterValue: switchesState });
  }

  render() {
    const {
      classes,
      state,
      toggleQuickPanel,
      activeFilters,
      filteredOrders,
    } = this.props;

    return (
      <Drawer
        classes={{ paper: classes.root }}
        open={state}
        anchor="right"
        onClose={() => toggleQuickPanel(false)}
      >
        <div className="filters-header">
          <img
            width="100"
            src="assets/images/logos/logo-v2-shadow.svg"
            alt="logo"
          />
          <div className="headband">
            <Typography>FILTRES</Typography>
          </div>
        </div>

        <div className="filters-content">
          {/* --------------------------- BY DATE */}
          <div className="section-wrapper by-date">
            <span className="subtitle">PAR DATE DE CREATION</span>
            <div className="range-picker">
              <RangePicker
                defaultValue={[
                  activeFilters.dates.from,
                  activeFilters.dates.to,
                ]}
                ranges={{
                  "Aujourd'hui": [moment().subtract('day', 1), moment()],
                  'Cette semaine': [
                    moment().startOf('week'),
                    moment().endOf('week'),
                  ],
                  'Ce mois': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  "L'année dernière": [
                    moment()
                      .subtract('year', 1)
                      .startOf('year'),
                    moment()
                      .subtract('year', 1)
                      .endOf('year'),
                  ],
                }}
                format="DD/MM/YYYY"
                onChange={this.onPickerChange}
                placeholder={['Début', 'Fin']}
                size="large"
                allowClear={false}
              />
            </div>
          </div>

          {/* --------------------------- BY STATUS */}
          <div className="section-wrapper by-status">
            <span className="subtitle">PAR STATUT</span>
            <div className="switches">
              <SwitchFilter
                onChange={this.handleOnSwitchChange}
                switchesStatuses={activeFilters.statuses}
                filteredOrders={filteredOrders}
              />
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleQuickPanel: Actions.toggleQuickPanel,
      setActiveFilters: ActionsAbaca.setActiveFilters,
    },
    dispatch,
  );
}

function mapStateToProps({ quickPanel, abaca }) {
  return {
    state: quickPanel.state,
    activeFilters: abaca.orders.activeFilters,
    filteredOrders: abaca.orders.filtered,
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(QuickPanel),
);
