import productList from './product-list-abaca';
import config from 'config';

export const ProductListAbacaConfig = {
  routes : [
    {
      path      : config.pages.abaca.products,
      component : productList
    }
  ]
}