export const OPEN_DIALOG = '[DIALOG] OPEN';
export const CLOSE_DIALOG = '[DIALOG] CLOSE';
export const CONFIRM_RECEPTION_OF_PRODUCT =
  '[DIALOG] CONFIRM_RECEPTION_OF_PRODUCT';
export const CANCEL_RECEPTION_OF_PRODUCT =
  '[DIALOG] CANCEL_RECEPTION_OF_PRODUCT';
export const CLEAR_CANCELED_RECEPTION_PRODUCT_ID =
  '[DIALOG] CLEAR_CANCELED_RECEPTION_PRODUCT_ID';
export const UPDATE_RECEIVED_PRODUCT_STORAGE_INFO =
  '[DIALOG] UPDATE_RECEIVED_PRODUCT_STORAGE_INFO';

export function closeDialog(options = {}) {
  return {
    type: CLOSE_DIALOG,
    options,
  };
}

export function openDialog(options) {
  return {
    type: OPEN_DIALOG,
    options,
  };
}

export function confirmReceptionOfProduct(orderProductId) {
  return {
    type: CONFIRM_RECEPTION_OF_PRODUCT,
    payload: orderProductId,
  };
}

export function cancelReceptionOfProduct(orderProductId) {
  return {
    type: CANCEL_RECEPTION_OF_PRODUCT,
    payload: orderProductId,
  };
}

export function clearCanceledReceptionProductId() {
  return {
    type: CLEAR_CANCELED_RECEPTION_PRODUCT_ID,
  };
}

export function updateReceivedProductStorageInfo(storageInfo) {
  return {
    type: UPDATE_RECEIVED_PRODUCT_STORAGE_INFO,
    payload: storageInfo,
  };
}
