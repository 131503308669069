import axios from 'axios';
import config from 'config';
import _ from '@lodash';

import Notification from 'services/Notification';

const {
  postUser,
  putUser,
  getProducts,
  postProduct,
  putProduct,
  getOrders,
  showBill,
  displayBill,
  postComment,
  deleteComment,
  getStores,
  getStoreFiles,
  getStoreUsers,
  postStore,
  postCustomer,
  getCustomers,
  getAddress,
  deleteAddress,
  putAddress,
  postAddress,
  getProviders,
  postProvider,
  postOrderStatus,
  getFiles,
  postFile,
  putOrderProduct,
  getHomeSuggestions,
  getCategories,
  postCategory,
  loginAs,
} = config.endpoints;

class requestService {
  constructor() {
    this.jwtToken = window.localStorage.getItem('jwt_access_token');
  }

  getOrders = (withFullDetails = true, withFilter = false) => {
    let url = getOrders;
    if (withFullDetails) {
      url += '?include[store]&include[product]&include[provider]';
    }

    if (withFilter) {
      url += withFullDetails ? '&' : '?';
      url += `filter[search]=${withFilter}`;
    }

    return this.callAPI(url);
  };

  postOrder = orderInfo => {
    return this.callAPI(config.endpoints.postOrder, orderInfo, 'post');
  };

  putOrder = orderInfo => {
    return this.callAPI(
      config.endpoints.putOrder,
      orderInfo,
      'put',
      orderInfo.id,
    );
  };

  showBill = orderInfo => {
    return this.callAPI(showBill, orderInfo, 'get', orderInfo.id);
  };

  displayBill = orderInfo => {
    return this.callAPI(displayBill, orderInfo, 'get', orderInfo.id);
  };

  getProducts = (withStock = false, withFilter = false) => {
    let url = getProducts;
    if (withStock) {
      url += '?include[stock]';
    }

    if (withFilter) {
      url += withStock ? '&' : '?';
      url += `filter[search]=${withFilter}`;
    }

    return this.callAPI(url);
  };

  postProduct = productsInfo => {
    return this.callAPI(postProduct, productsInfo, 'post');
  };

  putProduct = productsInfo => {
    return this.callAPI(putProduct, productsInfo, 'put', productsInfo.id);
  };

  getStores = () => {
    return this.callAPI(getStores);
  };

  getStoreFiles = storeId => {
    return this.callAPI(getStoreFiles, {}, 'get', storeId);
  };

  createStore = storeInfo => {
    return this.callAPI(postStore, storeInfo, 'post');
  };

  getCustomers = () => {
    return this.callAPI(getCustomers);
  };

  postCustomer = contactInfo => {
    return this.callAPI(postCustomer, contactInfo, 'post');
  };

  getAddress = customerId => {
    return this.callAPI(getAddress, {}, 'get', customerId);
  };

  postAddress = addressInfo => {
    return this.callAPI(postAddress, addressInfo, 'post');
  };

  putAddress = addressInfo => {
    return this.callAPI(putAddress, addressInfo, 'put', addressInfo.id);
  };

  deleteAddress = addressId => {
    return this.callAPI(deleteAddress, {}, 'delete', addressId);
  };

  getProviders = () => {
    return this.callAPI(getProviders);
  };

  postProvider = providerInfo => {
    return this.callAPI(postProvider, providerInfo, 'post');
  };

  postOrderStatus = orderStatusInfo => {
    return this.callAPI(postOrderStatus, orderStatusInfo, 'post');
  };

  getFiles = () => {
    return this.callAPI(getFiles);
  };

  postFile = formData => {
    return this.callAPI(postFile, formData, 'post', null, {
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  };

  putOrderProduct = orderProductInfo => {
    return this.callAPI(
      putOrderProduct,
      orderProductInfo,
      'put',
      orderProductInfo.id,
    );
  };

  updateProviderOrder = orderProducts => {
    let requests = [];

    for (const index in orderProducts) {
      requests.push(this.putOrderProduct(orderProducts[index]));
    }

    return Promise.all(requests).then(responses => {
      return _.every(responses, { data: { status: true } });
    });
  };

  postComment = (commentInfo, orderId) => {
    return this.callAPI(postComment, commentInfo, 'post', orderId);
  };

  deleteComment = orderId => {
    return this.callAPI(deleteComment, {}, 'delete', orderId);
  };

  createUser = userInfo => {
    return this.callAPI(postUser, userInfo, 'post');
  };

  updateUser = userInfo => {
    return this.callAPI(putUser, userInfo, 'put', userInfo.id);
  };

  getStoreUsers = (storeId, role) => {
    const filter = { role: role };
    return this.callAPI(getStoreUsers, {}, 'get', storeId, {}, filter);
  };

  getHomeSuggestions = text => {
    return this.callAPI(getHomeSuggestions.replace(/:text/, text));
  };

  getCategories = () => {
    return this.callAPI(getCategories);
  };

  postCategory = categoryInfo => {
    return this.callAPI(postCategory, categoryInfo, 'post');
  };

  loginAs = userUuid => {
    const postUuid = { uuid: userUuid };
    return this.callAPI(loginAs, postUuid, 'post');
  };

  setJWTaccessToken = newToken => {
    window.localStorage.setItem('jwt_access_token', newToken);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
    this.jwtToken = newToken;
  };

  callAPI = (
    endpoint,
    payload = {},
    method = 'get',
    id,
    headers = {},
    filter = {},
  ) => {
    let fullApiURL = `${process.env.REACT_APP_API_URL}${endpoint.replace(
      ':id',
      id,
    )}`;

    // Map filter params in api url
    if (Object.keys(filter).length) {
      fullApiURL +=
        '?' +
        Object.keys(filter)
          .map(key => key + '=' + filter[key])
          .join('&');
    }

    // Id only used in url don't send it in payload
    if (id && payload.id) {
      payload = _.cloneDeep(payload);
      delete payload.id;
    }

    console.log(
      '[callAPI] fullApiURL =====>',
      method.toUpperCase(),
      fullApiURL,
    );

    console.log('[callAPI] payload =====>', payload);

    headers = _.merge(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.jwtToken}`,
      },
      headers,
    );

    console.log('[callAPI] headers =====>', headers);

    return new Promise((resolve, reject) => {
      axios[method](fullApiURL, payload, { headers })
        .then(response => {
          if (response) {
            console.log('[callAPI] response OK 😸 ===>', response);
            resolve(response);
          } else {
            reject('');
          }
        })
        .catch(error => {
          console.log('[callAPI] response KO 💩 ===>', error);
          const errorMessage =
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.length
              ? error.response.data.error[0].message
              : '';
          const errorMessage2 =
            error.response && error.response.data && error.response.data['0']
              ? error.response.data['0'].message
              : '';
          const errorMessage3 =
            error.response && error.response.data
              ? error.response.data.error
              : '';
          const finalErrorMessage =
            errorMessage ||
            errorMessage2 ||
            errorMessage3 ||
            "Une erreur inconnue s'est produite";

          Notification.display({
            content: finalErrorMessage,
            title: 'Oops !',
            level: 'warning',
          });
          return reject(finalErrorMessage);
        });
    });
  };
}

export default requestService;
