import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { Select, Popover, Icon, DatePicker, Button, Tooltip } from 'antd';
import frFR from 'antd/lib/date-picker/locale/fr_FR';
import moment from 'moment';

// Components
import NameInput from './NameInput';

// Config
import config from 'config/index';

const { Option } = Select;

const styles = theme => ({
  root: {
    color: '#666666',
    width: '100%',
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontWeight: 700,
  },
  products: {
    borderTopColor: theme.palette.primary.dark + ' !important',
  },
  infoContent: {
    marginRight: '8px',
  },
  label: {
    color: config.colors.greyDove,
  },
  pointer: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      color: config.colors.yellowAnzac,
    },
  },
  discountWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .name-input-wrapper': {
      maxWidth: 75,
    },
  },
  okWrapper: {
    marginTop: 15,
  },
  ok: {
    color: config.colors.yellowAnzac,
  },
  equal: {
    margin: '0 10px',
  },
  ecoContribution: {
    '& .ant-input-affix-wrapper': {
      width: '120px',
      minWidth: '120px',
    }
  },
  deliveryAt: {
    width: '100%'
  },
});

class NewOrderSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      advancePaymentAmount: 0,
      payment_method: 'card',
      type: 'deposit',
      products: {
        totalPrice: 0,
        quantity: 0,
      },
      discount: {
        isPopoverVisible: false,
        percentage: 0,
        priceCut: 0,
        isValidated: false,
      },
      order_number: null,
      eco_contribution: 0,
      estimated_delivery_at: moment(),
      editing_order_number: false,
    };

    this.dateFormat = 'DD/MM/YYYY';
  }

  static defaultProps = {
    classes: PropTypes.object,
    info: PropTypes.object,
    products: PropTypes.object,
    onChangeOrderCheckout: PropTypes.func,
    action: PropTypes.string,
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  updateInputValue = (label, value) => {
    this.setState(
      {
        [label]: value,
      },
      () => {
        this.updateOrderCheckout();
      },
    );
  };

  updateOrderCheckout = () => {
    const { advancePaymentAmount, payment_method, type, discount, eco_contribution, delivery_at, order_number, editing_order_number } = this.state;
    const orderCheckout = {
      withoutDiscountAmount: {
        amount: advancePaymentAmount,
        payment_method: payment_method,
        type: type,
      },
      discount_amount: discount.priceCut,
      eco_contribution,
      store_delivery_at: delivery_at,
      order_number: editing_order_number && order_number ? order_number : null,
    };

    this.props.onChangeOrderCheckout(orderCheckout);
  };

  setProductsTotalPriceAndQuantity = (discountPercentage = 0, ecoContribution = this.state.eco_contribution) => {
    const percentage = (percent, total) => {
      return ((percent / 100) * total).toFixed(2);
    };

    const { products: productsFromProps } = this.props;

    let total = 0;
    const productsQuantity = _reduce(
      productsFromProps,
      function(quantity, product) {
        total += parseInt(product.quantity) * product.price;
        return parseInt(product.quantity) + quantity;
      },
      0,
    );

    const discountedTotal = total - percentage(discountPercentage, total) + parseFloat(ecoContribution);
    const minimumAdvancePayment =
      (discountedTotal * config.minimumAdvancePaymentPercentage) / 100;

    this.setState(
      {
        advancePaymentAmount: minimumAdvancePayment.toFixed(2),
        products: {
          totalPrice: discountedTotal.toFixed(2),
          quantity: productsQuantity,
        },
        eco_contribution: ecoContribution
      },
      () => {
        this.updateOrderCheckout();
      },
    );
  };

  setEcoContribution = (value) => {
    this.setProductsTotalPriceAndQuantity(this.state.discount.percentage, value)
  };

  handlePopoverVisibleChange = isVisible => {
    const { discount } = this.state;
    this.setState({
      discount: {
        ...discount,
        isPopoverVisible: isVisible,
      },
    });
  };

  submitDiscount = () => {
    const { discount } = this.state;
    this.setState({
      discount: {
        ...discount,
        isPopoverVisible: false,
        isValidated: true,
      },
    });
  };

  setDiscountValue = value => {
    const { discount, products } = this.state;
    this.setState({
      discount: {
        ...discount,
        percentage: value,
        priceCut: ((products.totalPrice * value) / 100).toFixed(2),
      },
    });
  };

  getDiscountHandlerContent = () => {
    const { classes } = this.props;
    const { discount } = this.state;
    return (
      <div>
        <div className={classes.discountWrapper}>
          <NameInput
            type={'number'}
            label=""
            placeholder={'2'}
            suffix={<Icon type="percentage" />}
            value={discount.percentage}
            onChange={this.setDiscountValue}
          />
          <div className={classes.equal}>=</div>
          <div>-{discount.priceCut}€</div>
        </div>
        <div className={classes.okWrapper}>
          <a className={classes.ok} onClick={this.submitDiscount}>
            OK
          </a>
        </div>
      </div>
    );
  };

  cancelDiscount = () => {
    this.setState({
      discount: {
        isPopoverVisible: false,
        percentage: 0,
        priceCut: 0,
        isValidated: false,
      },
    });
  };

  editOrderNumber = () => {
    this.setState({
      editing_order_number: !this.state.editing_order_number,
    })
  }

  componentDidMount() {
    this.setProductsTotalPriceAndQuantity();
  }

  componentDidUpdate(prevProps, prevState) {
    const { discount } = this.state;
    if (prevState.discount.isValidated !== discount.isValidated) {
      this.setProductsTotalPriceAndQuantity(discount.percentage);
    }
  }

  render() {
    const { classes, products: productsFromProps, info, action } = this.props;
    const {
      advancePaymentAmount,
      payment_method,
      products,
      discount,
      eco_contribution,
      editing_order_number,
      order_number,
    } = this.state;
    const { isPopoverVisible } = discount;
    const { billAddress, selectedAddress } = info;
    const {
      firstname,
      lastname,
      number,
      way,
      postal_code,
      city,
      phone_number,
      additional_information,
    } = selectedAddress;
    const balanceUponDelivery = products.totalPrice - advancePaymentAmount;

    return (
      <div className={classNames(classes.root, 'flex flex-col pt-24')}>
        <div className="flex flex-row">
          <div className="flex-1 border-t-2 border-black border-opacity-25">
            <div className="border-t-1 border-black border-opacity-25 py-12">
              <div
                className={classNames(
                  classes.subtitle,
                  'pt-12 uppercase text-black',
                )}
              >
                Adresse de livraison
              </div>

              <div>
                <span className="text-black">{`${firstname || ''} ${lastname ||
                  ''}`}</span>
                <br />
                <span className={classes.margin}>
                  {`${number}, ${way}`} <br />
                  {`${postal_code} ${city}`}
                </span>
                {phone_number && (
                  <>
                    <br />
                    <span className={classes.margin}>{phone_number}</span>
                  </>
                )}
                {additional_information && (
                  <>
                    <br />
                    <span className={classes.margin}>
                      {additional_information}
                    </span>
                  </>
                )}
              </div>
            </div>

            <div className="border-t-1 border-black border-opacity-25 py-12">
              <div
                className={classNames(
                  classes.subtitle,
                  'pt-12 uppercase text-black',
                )}
              >
                Adresse de facturation
              </div>

              <div>
                <span className={classes.margin}>
                  {_isEmpty(billAddress) ? (
                    'Identique'
                  ) : (
                    <>
                      <span className="text-black">{`${billAddress.firstname ||
                        ''} ${billAddress.lastname || ''}`}</span>
                      <br />
                      {`${billAddress.number}, ${billAddress.way}`}
                      <br />
                      {`${billAddress.postal_code} ${billAddress.city}`}
                    </>
                  )}
                </span>
              </div>
            </div>

            <div className="border-t-1 border-black border-opacity-25 py-12">
              <div className={classNames(classes.subtitle, 'pt-12 uppercase')}>
                Règlement
              </div>
              <label className={classes.label}>Moyen de paiement:</label>
              <Select
                optionFilterProp="children"
                defaultValue={payment_method || 'card'}
                onChange={value =>
                  this.updateInputValue('payment_method', value)
                }
                style={{ width: '100%' }}
              >
                {_map(config.paymentMethods, (value, key) => (
                  <Option key={key}>{value}</Option>
                ))}
              </Select>
              <br />
              <NameInput
                type={'number'}
                label={"Montant de l'acompte"}
                placeholder={'Ex: 50% de la commande'}
                suffix={'€'}
                isRequired={true}
                value={advancePaymentAmount}
                onChange={value =>
                  this.updateInputValue('advancePaymentAmount', value)
                }
              />
              <div>
                <i>Solde à la livraison: {balanceUponDelivery.toFixed(2)}€</i>
              </div>
              <br />
              <div className="flex items-end">
                <div className="mr-6">
                  <label className={classes.label}>Date de livraison :</label>
                  <DatePicker
                    className={classes.deliveryAt}
                    locale={frFR}
                    format={this.dateFormat}
                    disabledDate={this.disabledDate}
                    isRequired={true}
                    onChange={value =>
                      this.updateInputValue('delivery_at', value)
                    }
                  />
                </div>
                {action && (
                <Tooltip title={editing_order_number === false ? 'Saisir le numéro de commande' : 'Générer un numéro de commande automatiquement'} placement="topRight">
                  <Button className={classes.editOrderNumber} onClick={this.editOrderNumber} type="primary" icon={editing_order_number === false ? 'diff' : 'font-colors'} />
                </Tooltip>
                )}
              </div>
            </div>

            {action && editing_order_number === true && (
            <div className="flex items-end">
              <div className="mr-6">
                <NameInput
                  label={"Numéro de commande"}
                  placeholder={'Ex: 22034'}
                  isRequired={true}
                  value={order_number}
                  onChange={value =>
                    this.updateInputValue('order_number', value)
                  }
                />
              </div>
            </div>
            )}
          </div>

          <div
            className={classNames(
              classes.products,
              ' flex flex-col flex-1 border-1 border-opacity-25 border-black border-t-8 border-black border-opacity-100 ml-24 px-24 py-12',
            )}
          >
            <div
              className={classNames(classes.subtitle, 'uppercase text-black')}
            >
              {products.quantity} produit
              {products.quantity > 1 ? 's' : ''}
            </div>

            <div className="flex-1 border-t-1 border-b-1 border-black border-opacity-25 py-12">
              {productsFromProps.map((item, index) => {
                return (
                  <div key={index} className="flex justify-around">
                    <div className="flex-1">
                      <div className="font-bold text-base text-black">
                        {item.designation}
                      </div>
                      <div className="font-semibold text-xs text-black italic">
                        {item.provider}
                      </div>
                      <div className="font-semibold text-black">
                        <span color="text-grey-light">Qté:</span>{' '}
                        {item.quantity}
                      </div>
                      <div className={classes.margin}>
                        <strong>
                          {item.mustBeOrdered
                            ? 'A commander'
                            : 'Disponible en magasin'}
                        </strong>
                      </div>
                    </div>

                    <span className="text-right">{item.price} €</span>
                  </div>
                );
              })}
            </div>
            
            <NameInput
                type={'number'}
                label={"Eco-participation"}
                placeholder={''}
                suffix={'€'}
                isRequired={false}
                value={eco_contribution}
                onChange={this.setEcoContribution}
                orientation="horizontal"
                className={classNames(classes.ecoContribution, 'pt-12')}
              />

            {action === 'newOrder' &&
              (discount.isValidated ? (
                <span className={classes.pointer} onClick={this.cancelDiscount}>
                  <div>
                    <Icon type="stop" style={{ marginRight: 5 }} />
                    Annuler la remise
                  </div>
                  <span>-{discount.priceCut}€</span>
                </span>
              ) : (
                <Popover
                  content={this.getDiscountHandlerContent()}
                  title="Remise en pourcentage"
                  trigger="click"
                  visible={isPopoverVisible}
                  onVisibleChange={this.handlePopoverVisibleChange}
                >
                  <span className={classes.pointer}>
                    <div>
                      <Icon type="percentage" style={{ marginRight: 5 }} />
                      Remise
                    </div>
                  </span>
                </Popover>
              ))
            }

            <div className="text-black uppercase">
              <span className="text-righ">T.V.A.</span>
              <div className="float-right">{(products.totalPrice - (products.totalPrice / 1.2)).toFixed(2)} €</div>
            </div>

            <div className="text-black text-xl uppercase">
              <span className="text-righ">TOTAL TTC</span>
              <span className="float-right">{products.totalPrice} €</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect()(NewOrderSummary),
);
