import NewStoreAbaca from './new-store-abaca';
import config from 'config';

export const NewStoreAbacaConfig = {
  routes: [
    {
      path: config.pages.abaca.newStore,
      component: NewStoreAbaca,
    },
  ],
};
