const firstnamesRegExp = new RegExp(/^[^\s,.'-]+[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð,.'-]*[^\s,'-]+$/u)
const namesRegExp      = new RegExp(/^[^\s,.'-]+[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\s,.'-]*[^\s,'-]+$/u)

const rules = {
  // Rules
  identity: {
    civility:     [{required: true, message: 'Civilité obligatoire'}],
    firstname:    [{required: true, message: 'Prénom valide obligatoire', min: 3, max: 50}, {required: true, pattern: firstnamesRegExp, message: 'Prénom incorrect'}],
    lastname:     [{required: true, message: 'Nom valide obligatoire', min: 2, max: 50}, {required: true, pattern: namesRegExp, message: 'Nom incorrect'}],
    maiden_name:  [{required: false, message: 'Minimum 2 caractères, max 50', min: 2, max: 50}, {required: false, pattern: namesRegExp, message: 'Nom incorrect'}],
  },
  
  address: {
    zip_code: [{required: true, message: 'Code postal obligatoire'}, {required: true, min: 4, pattern: /[0-9]{3,}/, message: 'Code postal invalide'}],
    local:    [{required: false, min: 2, message: 'Lieu-dit incorrect'}],
    city:     [{required: true, message: 'Ville obligatoire', min: 3}],
    street_1: [{required: true, message: 'Rue obligatoire', min: 3}],
    street_2: [{required: false}],
    street_3: [{required: false}],
    country:  [{type: 'string', required: false, message: 'Pays obligatoire'}]
  },
  
  telephone: {
    mobile: [{required: false, type: 'mobile_number', validator: 'validateField', message: ['Téléphone personnel non valide'] }],
    home:   [{required: false, type: 'phone_number', validator: 'validateField', message: ['Téléphone fixe non valide'] }],
  },

  email: {
    email:  [{ required: true, type: 'email', message: 'Email obligatoire' }, {type: 'email', validator: 'validateField', message: ['Email non valide']}],
    dna:    [{ required: false}],
    none:   [{ required: false}],
    reason: [{ required: false}],
  },
  
  CSP: {
    csp:        [{ required: true, message: 'Profession obligatoire' }],
    detail:     [{ required: false }],
    dna:        [{ required: false}],
    protection: [{ required: true, message: 'Mesure de protection obligatoire' }],
  }
}

export default rules