import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

// Components
import Title from 'components/Title';
import AccordeonContainer from 'components/AccordeonContainer';
import OrdersListModeSwitcher from 'components/OrdersListModeSwitcher';
import TouringContainer from 'components/TouringContainer';

// Helpers
import { filterOrders } from 'utils/filters';
import {
  getCustomerById,
  getAllOrdersList,
  getShopById,
  getOrderById,
} from 'utils';

// Store
import * as Actions from 'store/actions';

// config
import config from 'config';

const styles = theme => ({
  orderProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -24,
    marginLeft: -24,
  },
  ordersListModeSwitcherWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

class OrdersPage extends Component {
  static defaultProps = {
    store: 'default',
  };

  constructor(props) {
    super(props);

    let title = this.props.store === 'default' ? '' : this.props.store;

    this.state = {
      title: `Commandes ${title}`,
      isLoading: true,
      customerId: null,
      shopId: null,
    };
  }

  async componentDidMount() {
    const { allOrdersList, storeAllOrders, activeFilters } = this.props;
    let orders = [];
    if (allOrdersList.length === 0) {
      try {
        const response = await getAllOrdersList();
        if (response && response.data) {
          orders = response.data;
          await storeAllOrders(orders);
        }
      } catch (error) {
        this.setState({
          error: 'Erreur lors de la récupération des commandes',
          isLoading: false,
        });
      }
    } else {
      orders = allOrdersList;
    }

    const customerId = new URLSearchParams(window.location.search).get(
      'customerId',
    );
    const orderId = new URLSearchParams(window.location.search).get('orderId');
    if (customerId || orderId) {
      await this.handleFiltered(customerId, orderId);
    }

    const storeId = new URLSearchParams(window.location.search).get(
      config.newStoreParam.storeId,
    );
    if (storeId) {
      await this.handleFilteredByShop(storeId);
    }

    this.updateOrdersWithActiveFilters(
      activeFilters,
      orders,
      customerId,
      storeId,
    );

    this.setState({ isLoading: false });
  }

  componentDidUpdate(prevProps) {
    const { allOrdersList, activeFilters } = this.props;
    const { customerId, shopId } = this.state;

    if (
      activeFilters !== prevProps.activeFilters ||
      allOrdersList !== prevProps.allOrdersList
    ) {
      this.updateOrdersWithActiveFilters(
        activeFilters,
        allOrdersList,
        customerId,
        shopId,
      );
    }
  }

  handleFiltered = async (customerId, orderId) => {
    const { allCustomers, allOrdersList } = this.props;
    try {
      let title = `Commande`;
      if (customerId) {
        const customer = await getCustomerById(customerId, allCustomers);

        if (!orderId) {
          title += 's';
        }

        title += ` de ${customer.firstname} ${customer.lastname}`;
      }

      if (orderId) {
        let order = await getOrderById(orderId, allOrdersList);

        if (order) {
          title += ` #${order.voucher_number}`;
        }
      }

      this.setState({
        customerId,
        title,
      });
    } catch (error) {
      this.setState({ error: error.toString() });
    }
  };

  handleFilteredByShop = async shopId => {
    const { allShops } = this.props;
    try {
      const shop = await getShopById(shopId, allShops);
      this.setState({
        shopId,
        title: `Commandes de ${shop.brand_name}`,
      });
    } catch (error) {
      this.setState({ error: error.toString() });
    }
  };

  updateOrdersWithActiveFilters = (
    activeFilters,
    orders,
    customerId = null,
    storeId = null,
  ) => {
    const { setFilteredOrders } = this.props;
    const filteredOrders = filterOrders(
      activeFilters,
      orders,
      customerId,
      storeId,
      new URLSearchParams(window.location.search).get('orderId'),
    );
    setFilteredOrders(filteredOrders);
  };

  render() {
    const { title, isLoading } = this.state;
    const {
      filteredOrders,
      activeFilters,
      isAdmin,
      classes,
      listMode,
    } = this.props;
    const { accordeon, touring } = config.orderListMode;
    return (
      <div>
        {isLoading && (
          <CircularProgress size={68} className={classes.orderProgress} />
        )}

        {!isLoading && (
          <>
            <Title text={title} subtitle={null} isLarge={true} />

            {isAdmin && (
              <div className={classes.ordersListModeSwitcherWrapper}>
                <OrdersListModeSwitcher />
              </div>
            )}

            {listMode === accordeon && (
              <AccordeonContainer
                data={filteredOrders}
                activeFilters={activeFilters}
                origin={isAdmin ? config.origins.abaca : config.origins.store}
              />
            )}

            {listMode === touring && (
              <TouringContainer
                data={filteredOrders}
                activeFilters={activeFilters}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ abaca }) => {
  return {
    listMode: abaca.orders.listMode,
    allOrdersList: abaca.orders.all,
    filteredOrders: abaca.orders.filtered,
    activeFilters: abaca.orders.activeFilters,
    allCustomers: abaca.customers.all,
    allShops: abaca.shops,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      storeAllOrders: Actions.storeAllOrders,
      setFilteredOrders: Actions.setFilteredOrders,
    },
    dispatch,
  );
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OrdersPage),
);
