import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import IconButton from '@material-ui/core/IconButton';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import 'react-id-swiper/src/styles/css/swiper.css'
import 'styles/components/swiper.css'

import config from 'config/index';

let swiperOptions = {
  slidesPerView: 'auto',
  spaceBetween: 10,
  centeredSlides: true,
  shouldSwiperUpdate : true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  breakpoints: {
    [config.responsive.extraLarge]: {
      slidesPerView: 2,
      spaceBetween: 40
    },
    [config.responsive.large]: {
      slidesPerView: 2,
      spaceBetween: 40
    },
    [config.responsive.medium]: {
      slidesPerView: 1,
      spaceBetween: 30
    },
    [config.responsive.small]: {
      slidesPerView: 1,
      spaceBetween: 20
    }
  },
  renderPrevButton: () => <IconButton className="swiper-button-prev" aria-label="NavigateBefore"><NavigateBefore /></IconButton>,
  renderNextButton: () => <IconButton className="swiper-button-next" aria-label="NavigateNext"><NavigateNext /></IconButton>,
};

class SwiperList extends Component {
  
  constructor(props) {
    super(props)
    this.swiper = null
  }

  goNext = () => {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev = () => {
    if (this.swiper) this.swiper.slidePrev()
  }

  render() {
    if (this.props.slidesPerView) {
      swiperOptions.slidesPerView = this.props.slidesPerView;
    }

    return (
      <div className="swiper-list-wrapper">
          
        <Swiper 
          {...swiperOptions} 
          ref={node => { 
            if(node) this.swiper = node.swiper 
          }} >

          {this.props.children}
        </Swiper>

      </div>
    );
  }
}

export default SwiperList;