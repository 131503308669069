import React, { Component } from 'react';
import _ from 'lodash';
import { Steps } from 'antd';
import { FuseAnimate } from '@fuse';
import PropTypes from 'prop-types';

// Config
import config from 'config';

import 'styles/components/stepper.css';

const { Step } = Steps;

class Stepper extends Component {
  static defaultProps = {
    current: 0,
    steps: [],
  };

  static propTypes = {
    current: PropTypes.number,
    steps: PropTypes.array,
  };

  static getConfig = (action, target, role = 'staff') => {
    let stepperConfig = _.cloneDeep(config.stepper.newOrder);

    let steps = [1, 3];

    switch(action) {
      default:
      case 'newOrder':

        switch(target) {
          default:
          case 'customer':
            
            steps.push(0)
            steps.push(2);
            break;
            
          case 'stock':
            
            break;

        }
        break;

      case 'newStock':
        steps = [1, 3, 4];

        stepperConfig[1].description = 'Renseigner les produits à ajouter au stock'

        break;
    }
        
    stepperConfig = stepperConfig.filter((value, index) => steps.includes(index))

    return stepperConfig;
  };

  onChange = current => {
    this.setState({ current });
  };

  render() {
    const { steps } = this.props;

    return (
      <FuseAnimate animation="transition.slideDownIn" delay={300}>
        <Steps type="navigation" direction="horizontal" size="small" current={this.props.current} onChange={this.onChange}>
          {steps.map(step => (
            <Step
              key={step.title}
              title={step.title}
              description={step.description}
            />
          ))}
        </Steps>
      </FuseAnimate>
    );
  }
}

export default Stepper;
