export const STORE_ORDER_PRODUCT = '[NEW_ORDER] STORE PRODUCT';
export const STORE_SELECTED_ADDRESS = '[NEW_ORDER] STORE SELECTED ADDRESS';
export const CLEAR_STORE_ORDER = '[NEW_ORDER] CLEAR STORE ORDER';
export const STORE_ORDER_CHECKOUT_DATA =
  '[NEW_ORDER] STORE ORDER CHECKOUT DATA';
export const STORE_BILL_ADDRESS = '[NEW_ORDER] STORE BILL ADDRESS';
export const STORE_ORDER_COMMENT = '[NEW_ORDER] STORE_ORDER_COMMENT';
export const CLEAR_ORDER_COMMENT = '[NEW_ORDER] CLEAR_ORDER_COMMENT';
export const CLEAR_NEW_ORDER_PRODUCTS = '[NEW_ORDER] CLEAR_NEW_ORDER_PRODUCTS';
export const STORE_NEW_ORDER_ACTION = '[NEW_ORDER] STORE_NEW_ORDER_ACTION';

export function storeOrderProduct(payload = null) {
  return {
    type: STORE_ORDER_PRODUCT,
    payload,
  };
}

export function clearStoreOrder() {
  return {
    type: CLEAR_STORE_ORDER,
  };
}

export function storeSelectedAddress(payload = null) {
  return {
    type: STORE_SELECTED_ADDRESS,
    payload,
  };
}

export function storeBillAddress(payload = null) {
  return {
    type: STORE_BILL_ADDRESS,
    payload,
  };
}

export function storeOrderCheckoutData(payload = null) {
  return {
    type: STORE_ORDER_CHECKOUT_DATA,
    payload,
  };
}

export function storeOrderComment(payload = '') {
  return {
    type: STORE_ORDER_COMMENT,
    payload,
  };
}

export function clearOrderComment(payload = null) {
  return {
    type: CLEAR_ORDER_COMMENT,
    payload,
  };
}

export function clearNewOrderProducts() {
  return {
    type: CLEAR_NEW_ORDER_PRODUCTS,
  };
}

export function storeNewOrderAction(payload = null) {
  return {
    type: STORE_NEW_ORDER_ACTION,
    payload,
  };
}
