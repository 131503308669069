import HomeAbaca from './home-abaca';
import config from 'config';
import {authRoles} from 'auth';

export const HomeAbacaConfig = {
  routes : [
    {
      path      : config.pages.abaca.home,
      component : HomeAbaca
    }
  ]
}