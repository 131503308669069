import AddressesPage from './addresses';
import config from 'config';

export const AddressesPageConfig = {
  routes  : [
    {
      path     : config.pages.store.addresses,
      component: AddressesPage
    },
  ]
};
