import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

// Components
import ProductReceived from './ProductReceived/ProductReceived';
import SwitchStatus from './SwitchStatus/SwitchStatus';
import ConfirmUpload from './ConfirmUpload/ConfirmUpload';
import ProviderOrder from './ProviderOrder/ProviderOrder';
import OrderDelivery from './OrderDelivery/OrderDelivery';
import CustomerContact from '../CustomerContact';

import config from 'config';

class AlertDialog extends React.Component {
  static defaultProps = {
    isOpen: false,
    onCloseDialog: () => {},
  };

  static propTypes = {
    requestedContent: PropTypes.oneOf([
      config.dialogContent.switchStatus,
      config.dialogContent.productReceived,
      config.dialogContent.confirmUpload,
      config.dialogContent.providerOrder,
      config.dialogContent.orderDelivery,
      config.dialogContent.customerContact,
    ]).isRequired,
    isOpen: PropTypes.bool,
    onCloseDialog: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      dialogContent: 'default',
    };
    this.setDialogContent = this.setDialogContent.bind(this);
  }

  setDialogContent(requestedContent) {
    const {
      onCloseDialog,
      order,
      customer,
      orderProductId,
      subItemInfo,
      isAdmin,
    } = this.props;

    switch (requestedContent) {
      case config.dialogContent.switchStatus:
        this.setState({
          dialogContent: (
            <SwitchStatus
              onCloseDialog={onCloseDialog}
              order={order}
              isAdmin={isAdmin}
            />
          ),
        });
        break;

      case config.dialogContent.productReceived:
        this.setState({
          dialogContent: (
            <ProductReceived
              onCloseDialog={onCloseDialog}
              order={order}
              orderProductId={orderProductId}
              subItemInfo={subItemInfo}
            />
          ),
        });
        break;

      case config.dialogContent.confirmUpload:
        this.setState({
          dialogContent: (
            <ConfirmUpload
              orderProductId={orderProductId}
              subItemInfo={subItemInfo}
              onCloseDialog={onCloseDialog}
            />
          ),
        });
        break;

      case config.dialogContent.providerOrder:
        this.setState({
          dialogContent: (
            <ProviderOrder onCloseDialog={onCloseDialog} order={order} />
          ),
        });
        break;

      case config.dialogContent.customerContact:
        this.setState({
          dialogContent: (
            <CustomerContact onCloseDialog={onCloseDialog} order={order} customer={order.customer} />
          ),
        });
        break;

      case config.dialogContent.orderDelivery:
        this.setState({
          dialogContent: (
            <OrderDelivery onCloseDialog={onCloseDialog} order={order} />
          ),
        });
        break;

      default:
        this.setState({ dialogContent: "Une erreur s'est produite :(" });
    }
  }

  componentDidMount() {
    this.setDialogContent(this.props.requestedContent);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.requestedContent !== this.props.requestedContent ||
      prevProps.order !== this.props.order ||
      prevProps.orderProductId !== this.props.orderProductId
    ) {
      this.setDialogContent(this.props.requestedContent);
    }
  }

  render() {
    const { isOpen, onCloseDialog, requestedContent } = this.props;
    const { dialogContent } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={
          requestedContent === config.dialogContent.providerOrder
        }
        disableEnforceFocus={true}
      >
        {dialogContent}
      </Dialog>
    );
  }
}

export default AlertDialog;
