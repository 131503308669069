import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _every from 'lodash/every';
import Spin from 'antd/lib/spin';

// Components
import SwitchStatusItem from './SwitchStatusItem';
// Services
import Notification from 'services/Notification';
// Config
import config from 'config';
// Utils
import { postNewOrderStatus, getAllOrdersList } from 'utils';
// Store
import * as Actions from 'store/actions';

const styles = () => ({
  statusItemsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  hr: {
    height: 3,
    border: 0,
    marginBottom: 22,
    backgroundImage: `linear-gradient(to right, #fff, ${
      config.colors.yellowGoldenDream
    }, ${config.colors.yellowAnzac}, #fff)`,
  },
});

class SwitchStatus extends React.Component {
  static propTypes = {
    onCloseDialog: PropTypes.func.isRequired,
    order: PropTypes.object,
    isAdmin: PropTypes.bool,
  };

  handleSwitchStatus = async status => {
    const { onCloseDialog, order, storeAllOrders } = this.props;

    this.setState({ isLoading: true });

    let content = '';
    let level = '';
    switch (status) {
      case config.orderStatus.delivered:
        content = 'livrée';
        level = 'SUCCESS';
        break;

      case config.orderStatus.paused:
        content = 'en pause';
        level = 'INFO';
        break;

      case config.orderStatus.in_progress:
        content = 'en cours';
        level = 'INFO';
        break;

      case config.orderStatus.provider_ordered:
        content = 'commandée fournisseur';
        level = 'INFO';
        break;

      case config.orderStatus.canceled:
        content =
          'annulée. Vous pouvez toujours modifier son statut en cas de besoin';
        level = 'ALERT';
        break;

      default:
        throw new Error('Order status undefined or unknown');
    }

    // API CALL
    const postNewOrderStatusResponse =
      order && (await postNewOrderStatus(order.id, status));
    if (postNewOrderStatusResponse && postNewOrderStatusResponse.data.status) {
      // An order status has been changed successfully we need to update the order list
      const response = await getAllOrdersList();
      if (response && response.data) {
        Notification.display({
          title: 'Changement de statut',
          content: `Commande ${content}`,
          level: level,
        });
        const orders = response.data;
        storeAllOrders(orders);
      }
    }

    this.setState({ isLoading: false });

    if ([config.orderStatus.provider_ordered, config.orderStatus.delivered].indexOf(status) === -1) {
      onCloseDialog();
    }
  };

  openExtraDialog = (status = 'provider_ordered') => {
    const { openDialog, onCloseDialog, order } = this.props;
    
    var content
    switch(status) {
      default:
      case 'provider_ordered':
        content = 'providerOrder'
        break
      case 'delivered':
        content = 'orderDelivery'
        break
    }

    var options = {
      children: config.dialogContent[content],
      order: order,
      onCloseDialog: async () => {
        status == 'provider_ordered' && await this.handleSwitchStatus(config.orderStatus[status]);
      },
    };

    onCloseDialog();

    // Open
    setTimeout(() => {
      openDialog(options);
    }, 200);
  };


  state = {
    isLoading: false,
  };

  checkIsProviderOrderDisabled = () => {
    const { order } = this.props;
    const { orderStatus } = config;
    const { provider_ordered, paused, canceled } = orderStatus;

    let allProductsAreInStock = _every(order.products, orderProduct => {
      return orderProduct.status !== config.productStatusCodes.MUST_BE_ORDERED;
    });

    let isProviderOrderDisabled =
      [provider_ordered, canceled].includes(order.status) ||
      allProductsAreInStock;

    const isOrderPaused = paused === order.status;
    const hasBeenProviderOrderBefore = order.events.find(
      event => event.status === provider_ordered,
    );

    if (isOrderPaused && hasBeenProviderOrderBefore) {
      isProviderOrderDisabled = false;
    }

    return isProviderOrderDisabled;
  };

  checkIsInProgressDisabled = () => {
    const { order } = this.props;
    const { orderStatus } = config;
    const { in_progress, provider_ordered, paused } = orderStatus;

    let isInProgressDisabled = [in_progress, provider_ordered].includes(
      order.status,
    );

    const isOrderPaused = paused === order.status;
    const hasBeenProviderOrderBefore = order.events.find(
      event => event.status === provider_ordered,
    );

    if (isOrderPaused && hasBeenProviderOrderBefore) {
      isInProgressDisabled = true;
    }

    return isInProgressDisabled;
  };

  render() {
    const { classes, onCloseDialog, order, isAdmin } = this.props;
    const { isLoading } = this.state;
    const { orderStatus } = config;
    const { in_progress, delivered, paused, canceled } = orderStatus;

    return (
      <div className="switch-status-wrapper">
        <DialogTitle id="alert-dialog-title">
          Changer le statut de la commande
        </DialogTitle>

        <hr className={classes.hr} />

        <DialogContent>
          <Spin spinning={isLoading}>
            <div className={classes.statusItemsWrapper}>
              <SwitchStatusItem
                title="Commande confirmée"
                icon="inProgress"
                onClick={() => this.handleSwitchStatus(in_progress)}
                isDisabled={this.checkIsInProgressDisabled()}
              />

              <SwitchStatusItem
                title="Commande Fournisseur"
                icon="providerOrder"
                onClick={this.openExtraDialog}
                isDisabled={this.checkIsProviderOrderDisabled()}
              />

              {isAdmin && (
                <SwitchStatusItem
                  title="Livrée"
                  icon="delivered"
                  onClick={() => this.openExtraDialog('delivered')}
                  isDisabled={order.status === delivered}
                />
              )}

              <SwitchStatusItem
                title="En pause"
                icon="paused"
                onClick={() => this.handleSwitchStatus(paused)}
                isDisabled={order.status === paused}
              />

              <SwitchStatusItem
                title="Annulée"
                icon="canceled"
                onClick={() =>
                  this.handleSwitchStatus(config.orderStatus.canceled)
                }
                isDisabled={order.status === canceled}
              />
            </div>
          </Spin>
        </DialogContent>

        <DialogActions>
          <Button onClick={onCloseDialog} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      storeAllOrders: Actions.storeAllOrders,
      openDialog: Actions.openDialog,
    },
    dispatch,
  );
};

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps,
  )(SwitchStatus),
);
