import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Config
import config from 'config';

// Images
import paused from 'static/icons/svg/paused.svg';
import providerOrder from 'static/icons/svg/providerOrder.svg';
import inProgress from 'static/icons/svg/inProgress.svg';
import delivered from 'static/icons/png/delivered.png';
import canceled from 'static/icons/png/canceled.png';

const styles = () => ({
  root: {
    '& img.status': {
      width: 35,
    },
    '& .status-wrapper': {
      '&:hover': {
        paddingLeft: 3,
      },
    },
  },
  heading: {
    fontSize: config.fontSizes.h4,
  },
  secondaryHeading: {
    fontSize: config.fontSizes.h4,
    color: config.colors.greyDove,
  },
  large: {
    flexBasis: '33.33%',
    padding: '0 10px',
  },
  medium: {
    flexBasis: '16.66%',
    padding: '0 10px',
  },
  small: {
    flexBasis: '8.33%',
    padding: '0 10px',
  },
  fontNormal: {},
  fontSmall: {
    '& > p': {
      fontSize: '1.2rem !important'

    }
  },
  customerName: {
    '&:hover': {
      color: config.colors.yellowGoldenRod,
      paddingLeft: 3,
    },
  },
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
});

class AccordeonItemCell extends Component {
  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    hasAction: PropTypes.bool,
    actionClick: PropTypes.func,
    size: PropTypes.string,
    fontSize: PropTypes.string,
    isComment: PropTypes.bool,
    hasComment: PropTypes.bool,
    isStatuses: PropTypes.bool,
    status: PropTypes.string,
    order: PropTypes.object,
  };

  static defaultProps = {
    heading: null,
    text: null,
    status: null,
    size: config.itemCellSizes.medium,
    fontSize: 'normal',
    isComment: false,
    hasComment: false,
    isStatuses: false,
    hasAction: false,
    actionClick: () => null,
  };

  openDialog = event => {
    const { openDialog, order } = this.props;
    // Open
    openDialog({ children: config.dialogContent.switchStatus, order: order });
    // Don't expand panel
    event.stopPropagation();
  };

  getIcon = () => {
    const { status } = this.props;

    if (status !== null) {
      let icon = '';

      switch (status) {
        case config.orderStatus.paused:
          icon = paused;
          break;
        case config.orderStatus.provider_ordered:
          icon = providerOrder;
          break;
        case config.orderStatus.ready:
        case config.orderStatus.in_progress:
          icon = inProgress;
          break;
        case config.orderStatus.canceled:
          icon = canceled;
          break;
        case config.orderStatus.delivered:
          icon = delivered;
          break;
        default:
          icon = 'Default';
      }

      return icon;
    }

    return null;
  };

  render() {
    const {
      classes,
      heading,
      text,
      size,
      fontSize,
      actionClick,
      isComment,
      isStatuses,
      hasAction,
      hasComment,
    } = this.props;

    const isImg = isComment || isStatuses;

    const imgStyle = {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    };

    const toggleIcon = hasComment ? { opacity: 1 } : { opacity: 0 };

    var fontSizeClass = 'font' + fontSize[0].toUpperCase() + fontSize.substr(1)

    return (
      <div
        className={classNames(classes.root, classes[size], classes[fontSizeClass], 'item-cell-wrapper')}
        style={isImg ? imgStyle : null}
      >
        {!isComment &&
          !isStatuses && (
            <>
              <Typography className={classes.secondaryHeading}> {heading} </Typography>
              <Typography>
                <span
                  className={classNames(
                    classes.heading,
                    hasAction ? classes.customerName : null,
                  )}
                  onClick={event => actionClick(event)}
                >
                  {text}
                </span>
              </Typography>
            </>
          )}

        {isComment && (
          <Icon style={toggleIcon} className="text-24" color="primary">
            textsms
          </Icon>
        )}

        {isStatuses && (
          <div className="status-wrapper">
            <img
              className="status"
              onClick={event => this.openDialog(event)}
              src={this.getIcon()}
              alt={text}
            />
          </div>
        )}

        {this.props.children}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openDialog: Actions.openDialog,
    },
    dispatch,
  );
}

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps,
  )(AccordeonItemCell),
);
