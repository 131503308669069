import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SwiperList from 'components/SwiperList';
import SelectableAddress from 'components/SelectableAddress';

const styles = () => ({
  spacing: {
    margin: 'auto',
    marginTop: '40px',
    marginBottom: '40px'
  }
});

class AddressData extends PureComponent {

  onSelect = (i) => {
    let newAddresses = [...this.state.addresses];

    // Unselect All
    for (let j = 0; j < newAddresses.length; j++) {
      newAddresses[j].isSelected = false;
    }

    // Select the chosen one
    newAddresses[i].isSelected = true;
    this.setState({addresses: newAddresses});
    this.props.onSelectChange(newAddresses[i]);    
  }


  buildState = (incomingAddresses) => {
    let newAddresses = [...incomingAddresses];

    for (let i = 0; i < newAddresses.length; i++) {
      // Select the first one by default
      newAddresses[i].isSelected = i === 0;
    }

    this.setState({addresses: newAddresses});
    this.props.onSelectChange(newAddresses[0]);
  }

  constructor(props) {
    super(props);
    this.state={
      addresses: [],
    }
  }

  componentDidMount() {
    this.buildState(this.props.addresses);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.addresses !== this.props.addresses) {
      this.buildState(this.props.addresses);
    }
  }

  render() {
    const {classes} = this.props;
    
    return (
      <div className={classNames('select-address-wrapper', classes.spacing)} >
        <SwiperList >

          {
            this.state.addresses.map((item, i) => {
              return (
                <SelectableAddress 
                  key        = {i}
                  name       = {`${item.firstname || ''} ${item.lastname || ''}`}
                  number     = {item.number}
                  street     = {item.way}
                  code       = {item.postal_code}
                  city       = {item.city}
                  country    = {item.country}
                  moreInfo   = {item.additional_information}
                  phoneNumber= {item.phone_number}
                  isSelected = {item.isSelected} 
                  onSelect   = {() => this.onSelect(i)} 
                />
              )
              
            })
          }

        </SwiperList>
      </div>
    )
  }
}

AddressData.propTypes = {
  addresses: PropTypes.array
}

export default withStyles(styles)(AddressData);