import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Icon, Tooltip, Input } from 'antd';

const styles = () => ({});

class InputSearchBar extends PureComponent {
  static defaultProps = {};

  render() {
    const {
      classes,
      placeholder,
      onEnterSearchBar,
      isSearchBarLoading,
      isSearchBarFilterActive,
    } = this.props;

    return (
      <Input.Search
        placeholder={placeholder}
        onSearch={onEnterSearchBar}
        enterButton
        allowClear
        loading={isSearchBarLoading}
        prefix={
          isSearchBarFilterActive && (
            <Tooltip title="Filtre actif">
              <Icon type="check-circle" style={{ color: 'green' }} />
            </Tooltip>
          )
        }
      />
    );
  }
}

export default withStyles(styles)(InputSearchBar);
