export const SET_SELECTED_SELLERS = '[USERS] SET SELECTED SELLERS';
export const CLEAR_USERS = '[USERS] CLEAR_USERS';

export function setSelectedSellers(payload = null) {
  return {
    type: SET_SELECTED_SELLERS,
    payload,
  };
}

export function clearUsers() {
  return {
    type: CLEAR_USERS,
  };
}
