import React, { PureComponent } from 'react';
import { Card, Alert } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import _cloneDeep from 'lodash/cloneDeep';
import _some from 'lodash/some';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Store
import * as Actions from 'store/actions';

// Components
import Title from 'components/Title';
import NameInput from 'components/NameInput';
import GoogleAddressAutocomplete from 'components/GoogleAddressAutocomplete';
import SubmitButton from 'components/SubmitButton';
import Stepper from 'components/Stepper';

// Config & service
import config from 'config';
import requestService from 'services/requestService';

const styles = () => ({
  root: {
    height: '100vh',
    '& .cardWrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '120px',
      marginBottom: '50px',
    },
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepperWrapper: {
    marginTop: 15,
    maxWidth: config.responsive.medium,
    margin: 'auto',
  },
  emailInput: {
    marginTop: 10,
    marginBottom: 10,
  },
  adressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
  },
  label: {
    color: config.colors.greyDove,
  },
  alertWrapper: {
    marginLeft: 20,
  },
});

class NewStoreAbacaPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoading: false,
      isEmptyInput: true,
      isAddressValid: false,
      storeInfo: {
        brandName: '',
        email: '',
        address: {
          city: '',
          country: '',
          id: '',
          number: '',
          postal_code: '',
          way: '',
        },
      },
    };
  }

  isEmptyInput = () => {
    // It returns true if it finds an empty string in contact
    const isEmptyString = _some(this.state.storeInfo, function(obj) {
      return obj === '';
    });
    return isEmptyString;
  };

  isAddressValid = () => {
    const { storeInfo } = this.state;
    const clonedContactAddress = { ...storeInfo.address };

    if (clonedContactAddress.id === '') {
      // Remove empty id before checking for empty string
      delete clonedContactAddress.id;
    }

    // The address number can be empty
    delete clonedContactAddress.number;

    const hasEmptyString = _some(clonedContactAddress, function(obj) {
      return obj === '';
    });

    return !hasEmptyString;
  };

  handleNameInputChange = (key, value) => {
    this.setState({
      ...this.state,
      storeInfo: {
        ...this.state.storeInfo,
        [key]: value,
      },
    });
  };

  handleAddressChange = (value, label) => {
    let storeInfo = _cloneDeep(this.state.storeInfo);
    storeInfo.address[label] = value;
    this.setState({
      ...this.state,
      storeInfo: {
        ...this.state.storeInfo,
        address: storeInfo.address,
      },
    });
  };

  checkDataValidity = () => {
    let areFieldsFilled = false;
    let isAddressOK = false;

    if (this.isEmptyInput()) {
      if (!this.state.isEmptyInput) {
        this.setState({ isEmptyInput: true });
      }
      areFieldsFilled = false;
    } else {
      if (this.state.isEmptyInput) {
        this.setState({ isEmptyInput: false });
      }
      areFieldsFilled = true;
    }

    if (this.isAddressValid()) {
      isAddressOK = true;
      if (!this.state.isAddressValid) {
        this.setState({ isAddressValid: true });
      }
    } else {
      isAddressOK = false;
      if (this.state.isAddressValid) {
        this.setState({ isAddressValid: false });
      }
    }

    return areFieldsFilled && isAddressOK;
  };

  submitHandler = async () => {
    const { history, addOnShopAction } = this.props;
    const { storeInfo } = this.state;

    this.setState({ isLoading: true });

    const createStoreInfo = {
      brand_name: storeInfo.brandName,
      email: storeInfo.email,
    };
    const { data } = await new requestService().createStore(createStoreInfo);
    if (data && data.store && data.store.id) {
      // Add the new shop in redux shops list
      addOnShopAction(data.store);

      // Create store address once the store is created
      const storeId = data.store.id;
      const storeAddressInfo = {
        store_id: storeId,
        number: storeInfo.address.number,
        postal_code: storeInfo.address.postal_code,
        way: storeInfo.address.way,
        city: storeInfo.address.city,
        country: storeInfo.address.country,
      };
      await new requestService().postAddress(storeAddressInfo);
      const { pages, newStoreParam } = config;
      history.push(
        `${pages.abaca.newStoreOwner}?${newStoreParam.storeId}=${storeId}`,
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { error, storeInfo, isEmptyInput, isAddressValid, isLoading } = this.state;
    const { brandName, email, address } = storeInfo;

    return (
      <div className={classes.root}>
        <Title
          text={error ? 'Erreur' : 'Nouveau magasin client'}
          helperTxt={error || ''}
          isLarge={true}
        />

        {!error && (
          <div className={classes.formWrapper}>
            <div className={classes.stepperWrapper}>
              <Stepper current={0} steps={config.stepper.newStore} />
            </div>

            <div className="cardWrapper">
              <Card title="INFORMATIONS MAGASIN" style={{ width: 300 }}>
                <NameInput
                  value={brandName}
                  label={'Nom du magasin (ou marque)'}
                  placeholder={'Ex: Literie 3000'}
                  isRequired={true}
                  isError={false}
                  helperText={''}
                  onChange={value =>
                    this.handleNameInputChange('brandName', value)
                  }
                />
                <NameInput
                  value={email}
                  label={'Email de contact'}
                  placeholder={'Ex: nomdumagasin@wanadoo.fr'}
                  isRequired={true}
                  isError={false}
                  helperText={''}
                  onChange={value => this.handleNameInputChange('email', value)}
                  className={classes.emailInput}
                />
                <div className={classes.adressWrapper}>
                  <label className={classes.label}>Adresse:</label>
                  <GoogleAddressAutocomplete
                    onChange={this.handleAddressChange}
                    address={address}
                  />
                </div>
              </Card>
              <div className={classes.alertWrapper}>
                {isEmptyInput && (
                  <Alert
                    message="Des champs sont encore vides"
                    description="Tous les champs sont obligatoires."
                    type="warning"
                    showIcon
                  />
                )}
                {!isAddressValid && (
                  <Alert
                    message="L'adresse semble invalide"
                    description="Sélectionnez l'adresse en cliquant sur les propositions."
                    type="warning"
                    showIcon
                    style={{ marginTop: 5 }}
                  />
                )}
              </div>
            </div>

            <SubmitButton
              title="Suite"
              isAllowed={this.checkDataValidity()}
              onClick={this.submitHandler}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addOnShopAction: Actions.addOnShopAction,
    },
    dispatch,
  );
};

const connectedNewStoreAbacaPage = connect(null, mapDispatchToProps)(NewStoreAbacaPage);

export default withStyles(styles)(connectedNewStoreAbacaPage);
