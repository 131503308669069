import * as Actions from '../../actions/abaca';

const initialState = {
  stores: [],
  store: null,
};

const storesReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.STORE_ALL: {
      return {
        ...state,
        stores: action.payload,
      };
    }

    case Actions.STORE_CURRENT: {
      return {
        ...state,
        store: action.payload,
      };
    }

    case Actions.CLEAR_STORES: {
      return initialState;
    }

    default:
      return state;
  }
};

export default storesReducer;
