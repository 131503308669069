export const STORE_ALL_SHOPS = '[STORES] STORE_ALL_SHOPS';
export const ADD_ONE_SHOP = '[STORES] ADD_ONE_SHOP';
export const CLEAR_SHOPS = '[STORES] CLEAR_SHOPS';

export function storeAllShops(payload = null) {
  return {
    type: STORE_ALL_SHOPS,
    payload,
  };
}

export function addOnShopAction(payload = null) {
  return {
    type: ADD_ONE_SHOP,
    payload,
  };
}

export function clearShops() {
  return {
    type: CLEAR_SHOPS,
  };
}
