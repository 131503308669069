export const STORE_ALL = '[STORES] STORE_ALL';
export const STORE_CURRENT = '[STORES] STORE_CURRENT';
export const CLEAR_STORES = '[STORES] CLEAR_STORES';

export function storeAllStores(payload = null) {
  return {
    type: STORE_ALL,
    payload,
  };
}

export function storeCurrentStore(payload = null) {
  return {
    type: STORE_CURRENT,
    payload,
  };
}

export function clearStores() {
  return {
    type: CLEAR_STORES,
  };
}
