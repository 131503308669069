import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Upload, Icon, Modal, Button } from 'antd';
import { connect } from 'react-redux';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import isImage from 'is-image';

// Services
import requestService from 'services/requestService';
import Notification from 'services/Notification';

// Components
import Title from 'components/Title';
import SubmitButton from 'components/SubmitButton';

// Utils
import { getProductFilesByProductId } from 'utils';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const styles = () => ({
  root: {
    '& .picture-cards': {
      marginTop: 50,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& .submit-button-wrapper': {
        marginTop: 20,
      },
      '& .ant-upload-picture-card-wrapper': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
});

class Documents extends Component {
  getUrlParamsAndSetStates = async () => {
    const { allFilesList } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('productId');
    const designation = urlParams.get('designation');
    const provider = urlParams.get('provider');
    const store_id = urlParams.get('store_id');

    if (productId) {
      let allFiles = allFilesList;
      if (allFilesList.length === 0) {
        allFiles = await this.setAllFiles();
      }

      const productFiles = getProductFilesByProductId(productId, allFiles);
      productFiles &&
        productFiles.forEach((productFile, index) => {
          let url = process.env.REACT_APP_API_URL + productFile.uri;
          Object.assign(productFile, {
            uid: index,
            name: productFile.name,
            status: productFile.status,
            url: url,
            thumbUrl: isImage(productFile.name)
              ? url
              : 'https://fakeimg.pl/48x48/282828/eae0d0/?retina=1&text=n/a&font=lobster',
          });
        });
      console.log('productFiles', productFiles);

      this.setState({
        fileList: productFiles || [],
        productInfo: {
          designation,
          provider,
          productId,
          store_id,
        },
      });
    } else {
      throw 'urlParam not found';
    }
  };

  setAllFiles = async () => {
    const { storeAllFiles } = this.props;

    const response = await new requestService().getFiles();
    if (response && response.data) {
      const allFiles = response.data;
      storeAllFiles(allFiles);
      return allFiles;
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList });
  };

  handleSubmit = () => {
    const { fileList, productInfo } = this.state;

    // New files to create have a "originFileObj" key
    const filesToCreate = fileList.filter(file =>
      file.hasOwnProperty('originFileObj'),
    );

    let formData;
    filesToCreate.forEach(async file => {
      console.log(file);

      formData = new FormData();
      formData.append('files[]', file.originFileObj, file.name);
      formData.append('name', file.name);
      formData.append('mime_type', file.type);
      formData.append('order_product_id', productInfo.productId);
      productInfo.store_id && formData.append('store_id', productInfo.store_id);

      await new requestService().postFile(formData);
    });

    Notification.display({
      title: 'Documents de réception',
      content: 'Liste sauvegardée et mise à jour',
      level: 'SUCCESS',
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      productInfo: {
        designation: '',
        provider: '',
        productId: '',
        store_id: '',
      },
      fileList: [],
    };
  }

  componentDidMount() {
    try {
      this.getUrlParamsAndSetStates();
    } catch (error) {
      this.setState({ error: true });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      error,
      previewVisible,
      previewImage,
      fileList,
      productInfo,
    } = this.state;
    const { designation, provider } = productInfo;
    const uploadButton = (
      <Button>
        <Icon type="plus" /> Ajouter
      </Button>
    );

    return (
      <div className={classNames('volume-page-wrapper', classes.root)}>
        {!error && (
          <>
            <Title
              text={'Documents de réception'}
              subtitle={`Pour: ${designation} de chez ${provider}`}
            />

            <div className="picture-cards">
              <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture"
                fileList={fileList}
                onPreview={this.handlePreview}
                onChange={this.handleChange}
              >
                {fileList.length >= 10 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={previewImage}
                />
              </Modal>

              <SubmitButton
                title="Sauvegarder"
                isAllowed={true}
                onClick={this.handleSubmit}
              />
            </div>
          </>
        )}

        {error && (
          <Title
            text="Erreur"
            subtitle={'Impossible de récupérer les documents :('}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      storeAllFiles: Actions.storeAllFiles,
    },
    dispatch,
  );
};

const mapStateToProps = ({ abaca }) => {
  return {
    allFilesList: abaca.files,
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Documents),
);
