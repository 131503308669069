import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'antd';

// Components
import SwitchButton from 'components/SwitchButton';

import config from '../config/index';
const statuses = config.productStatusCodes;

const styles = () => ({
  root: {
    maxWidth: 100,
    margin: 'auto',

    '& .available': {
      border: `1px solid ${config.colors.yellowGoldenDream}`,
      borderRadius: 4,
      padding: 10,
      backgroundColor: 'white',
      borderWidth: 2,
      textAlign: 'center',
      '& span': {
        fontVariant: 'all-small-caps',
        color: config.colors.blackMineShaft,
      },
    },
    
    '& .stock': {
      maxWidth: 130,
    },

    '& .delivered': {
      padding: 10,
      backgroundColor: 'white',
      textAlign: 'center',
      '& span': {
        fontVariant: 'all-small-caps',
        color: config.colors.blackMineShaft,
      },
    },
  },
});

class ProductStatusDisplayer extends PureComponent {
  static defaultProps = {
    status: statuses.MUST_BE_ORDERED,
    onSwitchChange: () => {},
    origin: config.origins.abaca,
    isStock: false,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    status: PropTypes.oneOf([
      statuses.MUST_BE_ORDERED,
      statuses.RECEIVED,
      statuses.AVAILABLE_AT_STORE,
      statuses.DELIVERED,
    ]),
    onSwitchChange: PropTypes.func,
    isStock: PropTypes.bool,
    orderProduct: PropTypes.object,
  };

  render() {
    const { classes, origin, status, onSwitchChange, isStock, orderProduct } = this.props;

    return (
      <div className={classNames(classes.root, 'product-status-displayer')}>
        { isStock === true ? (
          <div className="flex items-center">
            <div className="available">
              <span>En stock</span>
            </div>
            
            <a onClick={onSwitchChange(false)} style={{marginLeft: 10}}>
              <Icon
                type={orderProduct.storage_occupation ? 'edit' : 'plus'}
                theme="outlined"
                style={{ marginRight: 10 }}
              />
            </a>
          </div>
        ) : status === statuses.DELIVERED ? (
            <div className="delivered">
              <span>Article livré</span>
              <Icon type="check" style={{ color: config.colors.greenSuccess }} />
            </div>
          ) : status === statuses.AVAILABLE_AT_STORE ? (
            <div className="available">
              <span>En boutique</span>
            </div>
          ) : origin === config.origins.abaca ? (
            <SwitchButton
              leftValue=""
              rightColor={config.gradients.main}
              rightValue="REÇU"
              onClick={onSwitchChange(status === statuses.RECEIVED)}
              isRightValueChecked={status === statuses.RECEIVED}
            />
          ) : (
            <div className="available">
              <span>En attente</span>
            </div>
          )}
      </div>
    );
  }
}

export default withStyles(styles)(ProductStatusDisplayer);
