export const STORE_ALL_PRODUCT_LIST = '[PRODUCTS] STORE_ALL_PRODUCT_LIST';
export const STORE_ALL_PROVIDERS_LIST = '[PRODUCTS] STORE_ALL_PROVIDERS_LIST';
export const STORE_ALL_CATEGORIES_LIST = '[PRODUCTS] STORE_ALL_CATEGORIES_LIST';
export const CLEAR_PRODUCTS = '[PRODUCTS] CLEAR_PRODUCTS';

export function storeAllProductList(payload = null) {
  return {
    type: STORE_ALL_PRODUCT_LIST,
    payload,
  };
}

export function storeAllProvidersList(payload = null) {
  return {
    type: STORE_ALL_PROVIDERS_LIST,
    payload,
  };
}

export function storeAllCategoriesList(payload = null) {
  return {
    type: STORE_ALL_CATEGORIES_LIST,
    payload,
  };
}

export function clearProducts() {
  return {
    type: CLEAR_PRODUCTS,
  };
}
