import React from 'react';
import {Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles/index';
import classNames from 'classnames';

const styles = theme => ({
    root: {}
});

function MainFooter({classes})
{
    const year = (new Date()).getFullYear();
    return (
        <div className={classNames(classes.root, "flex flex-1 items-center px-24")}>
            <Typography>Abaca © {year === 2018 ? year : ['2018', year].join(' - ')}</Typography>
        </div>
    );
}

export default withStyles(styles, {withTheme: true})(MainFooter);