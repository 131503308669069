import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Store
import * as Actions from 'store/actions/abaca';

// Config
import config from 'config';

// Images
import plus from 'static/icons/png/plus.png';
import imgAddress from 'static/icons/png/address.png';

// Components
import Title from 'components/Title';
import HomeButton from 'components/HomeButton';

// Helpers
import { getCustomerById } from '../../../../utils/index';
import requestService from 'services/requestService';

const styles = () => ({
  root: {
    maxWidth: config.responsive.medium,
    margin: 'auto',
    marginTop: 0,
  },
  homeBtnWrapper: {
    marginTop: '50px',
    margin: 'auto',
  },
});

class CustomerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: '',
    };
  }

  async componentDidMount() {
    const { allCustomersList, storeAllCustomers } = this.props;

    try {
      const response = await getCustomerById(
        new URLSearchParams(window.location.search).get('customerId'),
        allCustomersList,
        true,
      );
      const { customer, _allCustomersList } = response;

      storeAllCustomers(_allCustomersList);

      this.setState({
        customerName: `${customer.firstname} ${customer.lastname}`,
        customerData: customer,
      });
    } catch (error) {
      this.setState({ error: true });
    }
  }

  render() {
    const { classes, auth } = this.props;
    const { customerName, customerData, error } = this.state;
    const isAdmin = auth.user.role === config.authRoles.admin;

    return (
      <div className={classes.root}>
        {!error && (
          <>
            <Title
              text={customerName.toUpperCase()}
              subtitle={'Nouvelle commande & Informations client'}
              isLarge={false}
            />

            <div style={{ display: 'flex' }}>
              <div className={classes.homeBtnWrapper}>
                <HomeButton
                  icon={plus}
                  title={'NOUVELLE COMMANDE'}
                  description={'Créer une nouvelle commande'}
                  onClick={() => {
                    let link = `${config.pages.store.newOrder}?customerId=${
                      customerData.id
                    }`;
                    this.props.history.push(link);
                  }}
                />
                <br />
                <br />
                <HomeButton
                  title={'COMMANDES'}
                  description={'Voir toutes les commandes du client'}
                  onClick={() => {
                    let link = `${
                      config.pages[isAdmin ? 'abaca' : 'store'].orders
                    }?customerId=${customerData.id}`;
                    this.props.history.push(link);
                  }}
                />
                <HomeButton
                  icon={imgAddress}
                  title={'ADRESSES'}
                  description={'Modifier les adresses du client'}
                  onClick={() => {
                    let link = `${config.pages.store.addresses}?customerId=${
                      customerData.id
                    }`;
                    this.props.history.push(link);
                  }}
                />
              </div>
            </div>
          </>
        )}

        {error && (
          <Title
            text="Erreur"
            subtitle={
              "Impossible de récupérer l'identifiant client ou celui-ci est erroné"
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ abaca, auth }) => {
  return {
    allCustomersList: abaca.customers.all,
    auth: auth,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      storeAllCustomers: Actions.storeAllCustomers,
    },
    dispatch,
  );
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CustomerPage),
);
