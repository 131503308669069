import React from 'react';
import { Card } from 'antd';
import { withStyles } from '@material-ui/core/styles';

// Components
import NameInput from 'components/NameInput';

const styles = () => ({
  lastnameWrapper: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const NewStoreStaff = ({
  title,
  icon,
  onChange,
  classes,
  firstname,
  lastname,
  email,
}) => {
  return (
    <Card title={title} extra={icon} style={{ width: 300 }}>
      <NameInput
        value={firstname}
        label={'Prénom'}
        placeholder={'Prénom'}
        isRequired={true}
        isError={false}
        helperText={''}
        onChange={value => onChange('firstname', value)}
      />
      <div className={classes.lastnameWrapper}>
        <NameInput
          value={lastname}
          label={'Nom'}
          placeholder={'Nom'}
          isRequired={true}
          isError={false}
          helperText={''}
          onChange={value => onChange('lastname', value)}
        />
      </div>
      <NameInput
        type="email"
        value={email}
        label={'Email'}
        placeholder={'Email'}
        isRequired={true}
        isError={false}
        helperText={''}
        onChange={value => onChange('email', value)}
      />
    </Card>
  );
};

export default withStyles(styles)(NewStoreStaff);
