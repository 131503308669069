import jwtService from 'services/jwtService';
import {setUserData} from 'auth/store/actions/user.actions';
import * as Actions from 'store/actions';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin({email, password})
{
    return (dispatch) =>
        jwtService.signInWithEmailAndPassword(email, password)
            .then((user) => {
                console.log('user', user)
                dispatch(setUserData(user));

                if (user.hasOwnProperty('store')) {
                    dispatch(Actions.storeCurrentStore(user.store));
                }

                dispatch(Actions.showMessage({message: 'Bienvenue ' + user.firstname}));

                return dispatch({
                    type: LOGIN_SUCCESS
                });
            })
            .catch(error => {
                dispatch(Actions.showMessage({message: 'Erreur de connexion, veuillez réessayer !'}));

                return dispatch({
                    type   : LOGIN_ERROR,
                    payload: error
                });
            });
}