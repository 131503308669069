import React, { Component } from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEmpty from 'lodash/isEmpty';
import { Popconfirm } from 'antd';

// Components
import Title from 'components/Title';
import SubmitButton from 'components/SubmitButton';
import NewOrderSummary from 'components/NewOrderSummary';
import Stepper from 'components/Stepper';
import Commenter from 'components/Commenter';

// Services
import requestService from 'services/requestService';

// Store
import * as Actions from 'store/actions/abaca';

// Config
import config from 'config';

// Utils
import { getAllOrdersList } from 'utils';

const styles = () => ({
  summaryWrapper: {
    width: '100%',
    margin: 'auto',
    marginTop: 0,
  },
  stepperWrapper: {
    marginTop: 15,
    maxWidth: config.responsive.medium,
    margin: 'auto',
  },
  content: {
    maxWidth: config.responsive.small,
    margin: 'auto',
  },
  CTA: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '15px',
    marginRight: '20px',
  },
  cancel: {
    cursor: 'pointer',
    color: config.colors.yellowAnzac,
    marginRight: '50px',
  },
  orderProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -24,
    marginLeft: -24,
  }
});


class Summary extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSendingOrder: false,
    }
  }
  
  async componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);

    if (this.props.action === 'newStock' && urlParams.get('doOrder') === 'false') {
      return await this.sendOrder();
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    const {
      history,
      products,
      user,
      action
    } = this.props

    if (!products.length) {
      history.push(
        `${config.pages[user.role === 'admin' ? 'abaca' : 'store'][action === 'newStock' ? 'newStock' : 'newOrder']}`,
      );
    }
  }

  sendOrder = async () => {
    const {
      history,
      products,
      selectedAddress: _selectedAddress,
      billAddress,
      user,
      clearStoreOrder,
      orderCheckout,
      orderComment,
      action,
      addOneOrderInAllOrdersList,
      allOrdersList,
      storeAllOrders
    } = this.props;

    this.setState({ isSendingOrder: true });    

    let selectedAddress = _selectedAddress
    if (action === 'newStock' && _isEmpty(selectedAddress) && user.store) {
      selectedAddress = user.store.address;
    }

    let amount = 0;
    for (let i = 0; i < products.length; i++) {
      amount = amount + Number(products[i].quantity) * products[i].price;
    }
  
    let orderProducts = [];
    for (let i = 0; i < products.length; i++) {
      orderProducts.push({
        product_id: products[i].id,
        quantity: products[i].quantity,
        price: products[i].price,
        status: products[i].mustBeOrdered
          ? config.productStatusCodes.MUST_BE_ORDERED
          : config.productStatusCodes.AVAILABLE_AT_STORE,
      });
    }
  
    const hasBillingAddress = !_isEmpty(billAddress);
    const orderInfo = {
      customer_id: selectedAddress.customer_id,
      store_id: user.store_id || 1,
      seller_id: user.id,
      shipping_address_id: selectedAddress.id,
      billing_address_id: hasBillingAddress ? billAddress.id : selectedAddress.id,
      amount: amount,
      discount: orderCheckout.discount_amount,
      order_products: orderProducts,
      order_checkout: orderCheckout.withoutDiscountAmount,
      is_stock: action === 'newStock' ? 1 : 0,
      eco_contribution: orderCheckout.eco_contribution,
      store_delivery_at: orderCheckout.delivery_at,
      voucher_number: orderCheckout.order_number,
    };
    console.log('orderInfo', orderInfo)
    const response = await new requestService().postOrder(orderInfo);
    if (response && response.data && response.data.status) {
      // Post comment if exists
      await new requestService().postComment(
        { comment: orderComment },
        response.data.order.id,
      );
  
      if (allOrdersList.length === 0) {
        try {
          const response = await getAllOrdersList();
          if (response && response.data) {
            const orders = response.data;
            await storeAllOrders(orders);
          }
        } catch (error) {
          console.error('Summary : Erreur lors de la récupération des commandes');
        }
      }
  
      // Add order to the all orders list (avoid unnecessary getting all orders again)
      addOneOrderInAllOrdersList(response.data.order);
  
      // Clear store to prepare for the next order
      clearStoreOrder();
  
      history.push(
        `${config.pages.store.home}?order=${config.backHomeNewOrderParams.sent}`,
      );
    }
  };
  

  handleCanceledOrder = () => {
    // Clear store to prepare for the next order
    const {
      history,
      clearStoreOrder
    } = this.props

    clearStoreOrder();

    history.push(
      `${config.pages.store.home}?order=${
        config.backHomeNewOrderParams.canceled
      }`,
    );
  };


  render() {
    const { isLoading, isSendingOrder } = this.state;

    const {
      products,
      selectedAddress: _selectedAddress,
      billAddress,
      user,
      action,
      storeOrderCheckoutData,
      classes,
    } = this.props;
    
    const steps = Stepper.getConfig(action, 'customer', user.role);

    let selectedAddress = _selectedAddress
    if (action === 'newStock' && _isEmpty(selectedAddress) && user.store) {
      selectedAddress = user.store.address;
    }

    const summmaryInfo = Object.assign({ billAddress }, { selectedAddress });
    
    return isLoading ? (
        <CircularProgress size={68} className={classes.orderProgress} />
      ) : (
      <div className={classes.summaryWrapper}>
        <Title
          text="RECAPITULATIF"
          subtitle="Cliquez sur Envoyer pour confirmer votre demande de livraison"
          isLarge
        />

        <div className={classes.stepperWrapper}>
          <Stepper current={action === 'newOrder' ? 3 : 1} steps={steps} />
        </div>

        <div className={classes.content}>
          <NewOrderSummary
            products={products}
            action={action}
            info={summmaryInfo}
            onChangeOrderCheckout={storeOrderCheckoutData}
          />

          <br />
          <Commenter />

          <form className={classes.CTA}>
            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No">
            <span
              className={classes.cancel}
              onClick={this.handleCanceledOrder}
            >
              Annuler la commande
            </span>
            </Popconfirm>

            <SubmitButton
              title="Valider"
              isAllowed={true}
              onClick={this.sendOrder}
              isLoading={isSendingOrder}
            />
          </form>
        </div>
      </div>
    )
  }
};

function mapStateToProps({ auth, abaca }) {
  return {
    user: auth.user,
    products: abaca.newOrder.products,
    selectedAddress: abaca.newOrder.selectedAddress,
    billAddress: abaca.newOrder.billAddress,
    orderCheckout: abaca.newOrder.orderCheckout,
    orderComment: abaca.newOrder.orderComment,
    action: abaca.newOrder.action,
    allOrdersList: abaca.orders.all,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearStoreOrder: Actions.clearStoreOrder,
      storeOrderCheckoutData: Actions.storeOrderCheckoutData,
      addOneOrderInAllOrdersList: Actions.addOneOrderInAllOrdersList,
      storeAllOrders: Actions.storeAllOrders,
    },
    dispatch,
  );
}

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(Summary),
  ),
);
