import { LogoutConfig } from 'main/pages/auth/logout/LogoutConfig';
import { DashboardConfig } from 'main/pages/dashboard/DashboardConfig';

// Store side app pages
import { HomePageConfig } from './store/home/home-config';
import { OrdersConfig } from './store/orders/orders-config';
import { NewCustomerConfig } from './store/new-customer/new-customer-config';
import { NewOrderConfig } from './store/new-order/new-order-config';
import { SelectAddressConfig } from './store/select-address/select-address-config';
import { CustomerPageConfig } from './store/customer/customer-config';
import { AddressesPageConfig } from './store/addresses/addresses-config';
import { SummaryPageConfig } from './store/summary/summary-config';

// Abaca side app pages
import { HomeAbacaConfig } from './abaca/home-abaca/home-abaca-config';
import { OrdersAbacaConfig } from './abaca/orders-abaca/orders-abaca-config';
import { SelectStoreAbacaConfig } from './abaca/select-store-abaca/select-store-abaca-config';
import { VolumeAbacaConfig } from './abaca/volume-abaca/volume-abaca-config';
import { ProductListAbacaConfig } from './abaca/product-list-abaca/product-list-abaca-config';
import { NewStoreAbacaConfig } from './abaca/new-store-abaca/new-store-abaca-config';
import { NewStoreOwnerAbacaConfig } from './abaca/new-store-owner-abaca/new-store-owner-abaca-config';
import { NewStoreSellerAbacaConfig } from './abaca/new-store-seller-abaca/new-store-seller-abaca-config';
import { NewStoreLegalsAbacaConfig } from './abaca/new-store-legals-abaca/new-store-legals-abaca-config';
import { StoreMenuAbacaConfig } from './abaca/store-menu-abaca/store-menu-abaca-config';

// Both side app pages
import { DocumentsConfig } from './common/documents/documents-config';
import { StockConfig } from './common/stock/stock-config';
import { NewStockConfig } from './common/new-stock/new-stock-config';

export const pagesConfigs = [
  LogoutConfig,
  DashboardConfig,
  HomePageConfig,
  OrdersConfig,
  NewCustomerConfig,
  NewOrderConfig,
  SelectAddressConfig,
  CustomerPageConfig,
  AddressesPageConfig,
  SummaryPageConfig,
  HomeAbacaConfig,
  OrdersAbacaConfig,
  SelectStoreAbacaConfig,
  VolumeAbacaConfig,
  DocumentsConfig,
  ProductListAbacaConfig,
  NewStoreAbacaConfig,
  NewStoreOwnerAbacaConfig,
  NewStoreSellerAbacaConfig,
  NewStoreLegalsAbacaConfig,
  StockConfig,
  NewStockConfig,
  StoreMenuAbacaConfig,
];
