import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Store
import * as Actions from 'store/actions/abaca';

// Components
import Title from 'components/Title';
import AddressManager from 'components/AddressManager';

// Helpers
import { getCustomerById } from '../../../../utils';

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto',
    marginTop: 0,
  },
  homeBtnWrapper: {
    marginTop: '50px',
    margin: 'auto',
  },
});

class AddressesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Gestion des adresses',
      subtitle: '',
      customerId: '',
    };
  }

  async componentDidMount() {
    const { setSelectedCustomer } = this.props;

    try {
      const customerId = new URLSearchParams(window.location.search).get(
        'customerId',
      );
      const customer = await getCustomerById(customerId);
      this.setState({
        subtitle: `Ajouter, modifier ou supprimer des adresses pour : ${customer.firstname} ${customer.lastname}`,
        customerId: customerId,
      });
      console.log(customer);

      setSelectedCustomer(customer);
    } catch (error) {
      this.setState({ error: true });
    }
  }

  render() {
    const { classes, selectedCustomer } = this.props;
    const { title, subtitle, error, customerId } = this.state;

    return (
      <div className={classes.root}>
        {!error && (
          <>
            <Title text={title.toUpperCase()} subtitle={subtitle} />

            <AddressManager
              customer={selectedCustomer}
              customerId={customerId}
            />
          </>
        )}

        {error && (
          <Title
            text="Erreur"
            subtitle={
              "Impossible de récupérer l'identifiant client ou celui-ci est erroné"
            }
          />
        )}
      </div>
    );
  }
}

function mapStateToProps({ abaca }) {
  return {
    selectedCustomer: abaca.customers.selected,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedCustomer: Actions.setSelectedCustomer,
    },
    dispatch,
  );
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AddressesPage),
);
