import NewStoreOwnerAbaca from './new-store-owner-abaca';
import config from 'config';

export const NewStoreOwnerAbacaConfig = {
  routes: [
    {
      path: config.pages.abaca.newStoreOwner,
      component: NewStoreOwnerAbaca,
    },
  ],
};
