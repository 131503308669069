import CustomerPage from './customer';
import config from 'config';
import {authRoles} from 'auth';

export const CustomerPageConfig = {
  auth: authRoles.any,
  routes  : [
    {
      path     : config.pages.store.customer,
      component: CustomerPage
    },
  ]
};
