export const STORE_ALL_ORDERS = '[ORDERS] STORE_ALL_ORDERS';
export const SET_FILTERED_ORDERS = '[ORDERS] SET_FILTERED_ORDERS';
export const SET_ACTIVE_FILTERS = '[ORDERS] SET_ACTIVE_FILTERS';
export const ADD_ONE_ORDER_IN_ALL_ORDERS_LIST =
  '[ORDERS] ADD_ONE_ORDER_IN_ALL_ORDERS_LIST';
export const UPDATE_ORDER = '[ORDERS] UPDATE_ORDER';
export const CLEAR_ORDERS = '[ORDERS] CLEAR_ORDERS';
export const SET_LIST_MODE = '[ORDERS] SET_LIST_MODE';

export function storeAllOrders(payload = null) {
  return {
    type: STORE_ALL_ORDERS,
    payload,
  };
}

export function addOneOrderInAllOrdersList(payload = null) {
  return {
    type: ADD_ONE_ORDER_IN_ALL_ORDERS_LIST,
    payload,
  };
}

export function setFilteredOrders(payload = null) {
  return {
    type: SET_FILTERED_ORDERS,
    payload,
  };
}

export function setActiveFilters(payload = null) {
  return {
    type: SET_ACTIVE_FILTERS,
    payload,
  };
}

export function updateOrder(orderId, payload = null) {
  return {
    type: UPDATE_ORDER,
    orderId,
    payload,
  };
}

export function clearOrders() {
  return {
    type: CLEAR_ORDERS,
  };
}

export function setListModeAction(payload) {
  return {
    type: SET_LIST_MODE,
    payload,
  };
}
