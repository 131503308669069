import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Store
import * as Actions from 'store/actions/abaca';

// Components
import SwiperList from 'components/SwiperList';
import ManageableAddress from 'components/ManageableAddress';
import AddNewItem from 'components/AddNewItem';
import ContactDataContainer from 'components/ContactDataContainer';

// Config
import config from 'config/index';
import requestService from '../services/requestService';

const styles = () => ({
  spacing: {
    margin: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
  },
  submit: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    alignItems: 'center',
  },
  cancel: {
    cursor: 'pointer',
    color: config.colors.yellowAnzac,
    marginRight: '50px',
  },
  contactDataWrapper: {
    maxWidth: config.responsive.small,
    display: 'flex',
    marginTop: '50px',
    margin: 'auto',
    '& fieldset': {
      margin: '0 10px',
    },
  },
});

class AddressManager extends PureComponent {
  buildState = incomingAddresses => {
    let newAddresses = [...incomingAddresses];

    // Do something with newAddresses here

    this.setState({ addresses: newAddresses });
  };

  constructor(props) {
    super(props);
    this.state = {
      isListingTemplate: true,
      isNewAddress: false,
      addresses: [],
      contact: {
        firstname: '',
        lastname: '',
        phone_number: '',
        email: '',
        address: {
          id: '',
          number: '',
          way: '',
          postal_code: '',
          city: '',
          country: '',
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.customer && this.props.customer.addresses) {
      this.buildState(this.props.customer.addresses);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customer.addresses !== this.props.customer.addresses) {
      this.buildState(this.props.customer.addresses);
    }
  }

  submitHandler = async contact => {
    const { isNewAddress } = this.state;
    const { customerId, setSelectedCustomer } = this.props;
    console.log(contact);

    let addressInfo = {
      firstname: contact.firstname,
      lastname: contact.lastname,
      mobile_number: contact.phone_number,
      email: contact.email,
      number: contact.address.number,
      way: contact.address.way,
      postal_code: contact.address.postal_code,
      country: contact.address.country,
      city: contact.address.city,
      region: contact.address.region,
    };

    if (isNewAddress) {
      // If it's a new address add the customer_id
      addressInfo = Object.assign(addressInfo, {
        customer_id: customerId,
      });
    } else {
      // If we modify an address add its id
      addressInfo = Object.assign(addressInfo, { id: contact.address.id });
    }

    console.log(addressInfo);

    // Create or modify address
    const response = isNewAddress
      ? await new requestService().postAddress(addressInfo)
      : await new requestService().putAddress(addressInfo);

    if (response && response.data && response.data.status) {
      // Success
      // Update addresses list
      const response = await new requestService().getAddress(customerId);
      setSelectedCustomer(response.data);

      // Return to listing
      this.setState({ isListingTemplate: true });
    }
  };

  onModify = keyToModify => {
    const { contact, addresses } = this.state;

    let clonedContact = _.cloneDeep(contact);
    clonedContact.firstname = addresses[keyToModify].firstname;
    clonedContact.lastname = addresses[keyToModify].lastname;
    clonedContact.phone_number = addresses[keyToModify].phone_number;
    clonedContact.address.id = addresses[keyToModify].id;
    clonedContact.address.number = addresses[keyToModify].number;
    clonedContact.address.way = addresses[keyToModify].way;
    clonedContact.address.postal_code = addresses[
      keyToModify
    ].postal_code.toString();
    clonedContact.address.city = addresses[keyToModify].city;
    clonedContact.address.country = addresses[keyToModify].country;

    this.setState(
      {
        contact: clonedContact,
      },
      () => {
        this.setState({ isListingTemplate: false });
      },
    );
  };

  onRemove = async keyToRemove => {
    const { addresses } = this.state;
    const { setSelectedCustomer, customerId } = this.props;

    if (addresses[keyToRemove] && addresses[keyToRemove].id) {
      // Delete it
      await new requestService().deleteAddress(addresses[keyToRemove].id);
      // Update new addresses list
      const response = await new requestService().getAddress(customerId);
      setSelectedCustomer(response.data);
    }
  };

  onNewAddress = () => {
    const { contact } = this.state;

    let clonedContact = _.cloneDeep(contact);
    clonedContact.firstname = '';
    clonedContact.lastname = '';
    clonedContact.phone_number = '';
    clonedContact.email = '';
    clonedContact.address.number = '';
    clonedContact.address.way = '';
    clonedContact.address.postal_code = '';
    clonedContact.address.city = '';
    clonedContact.address.country = '';

    this.setState({
      isListingTemplate: false,
      isNewAddress: true,
      contact: clonedContact,
    });
  };

  getModifyAddressTemplate = () => {
    const { contact } = this.state;

    return (
      <ContactDataContainer
        shape={config.contactDataTypes.management}
        data={contact}
        onSubmit={this.submitHandler}
        onCancel={() => this.setState({ isListingTemplate: true })}
      />
    );
  };

  getDefaultPhoneNumber = () => {
    // If address doesnt have an attached phone number
    // Get default one from customer
    const { customer } = this.props;
    return customer.mobile_number || customer.phone_number;
  };

  getAddressesListTemplate = () => {
    const { addresses } = this.state;
    return (
      <SwiperList>
        {addresses.map((item, i) => {
          return (
            <ManageableAddress
              key={i}
              name={`${item.firstname} ${item.lastname}`}
              number={item.number}
              street={item.way}
              code={item.postal_code}
              city={item.city}
              country={item.country}
              phone_number={item.phone_number || this.getDefaultPhoneNumber()}
              email={item.email}
              onModify={() => this.onModify(i)}
              onRemove={() => this.onRemove(i)}
              canAddressBeDeleted={!(i === 0)}
            />
          );
        })}

        <AddNewItem
          title="Nouvelle adresse"
          className="swiper-slide"
          width="300px"
          onClick={this.onNewAddress}
        />
      </SwiperList>
    );
  };

  render() {
    const { classes } = this.props;
    const { isListingTemplate } = this.state;

    return (
      <div className={classNames('address-manager-wrapper', classes.spacing)}>
        {isListingTemplate
          ? this.getAddressesListTemplate()
          : this.getModifyAddressTemplate()}
      </div>
    );
  }
}

AddressManager.propTypes = {
  customer: PropTypes.object,
  customerId: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedCustomer: Actions.setSelectedCustomer,
    },
    dispatch,
  );
}

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps,
  )(AddressManager),
);
