import Orders from './orders';
import config from 'config';
import {authRoles} from 'auth';

export const OrdersConfig = {
    auth: authRoles.any,
    routes  : [
        {
            path     : config.pages.store.orders,
            component: Orders
        },
    ]
};
