import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import config from 'config/index';

const styles = () => ({
  cardSeller: {
    height: 200,
  },
  modify: {
    cursor: 'pointer',
    color: config.colors.yellowAnzac,
    position: 'absolute',
    bottom: '30px',
  },
  remove: {
    cursor: 'pointer',
    color: config.colors.yellowAnzac,
    position: 'absolute',
    bottom: '30px',
    right: '20px',
  },
  email: {
    overflow: 'auto',
  },
});

class ManageableSeller extends Component {
  render() {
    const {
      classes,
      firstname,
      lastname,
      email,
      onRemove,
      onModify,
    } = this.props;

    return (
      <Card
        className={classes.cardSeller}
        title={`${firstname.toUpperCase()} ${lastname.toUpperCase()}`}
      >
        <div className={classes.email}>
          <span>{email}</span>
        </div>

        <div className={classes.modify} onClick={onModify}>
          <span>Modifier</span>
        </div>

        <div className={classes.remove} onClick={onRemove}>
          <span>Supprimer</span>
        </div>
      </Card>
    );
  }
}

ManageableSeller.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  onRemove: PropTypes.func,
  onModify: PropTypes.func,
};

export default withStyles(styles)(ManageableSeller);
