import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import {
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
  Typography,
  Fab,
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as quickPanelActions from 'main/quickPanel/store/actions';
import * as authActions from 'auth/store/actions';
import { bindActionCreators } from 'redux';
import { FuseAnimate } from '@fuse';
import { Link, withRouter } from 'react-router-dom';
import Logo from 'static/icons/png/abacaLogo.png';
import config from 'config/index';
import { Icon as AntdIcon } from 'antd';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: '#666666',
    width: '100%',
  },
  separator: {
    width: 1,
    height: 64,
  },
  filter: {
    position: 'fixed',
    right: 12,
    bottom: 12,
    zIndex: 99,
  },
  logo: {
    width: 90,
    height: 45,
    cursor: 'pointer',
  },
  fullname: {
    padding: 4,
    margin: 4,
  },
  connectedAsClient: {
    color: '#da2f2f',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 100,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
});

class MainToolbar extends Component {
  state = {
    userMenu: null,
    hasFilters: false,
  };

  userMenuClick = event => {
    this.setState({ userMenu: event.currentTarget });
  };

  userMenuClose = () => {
    this.setState({ userMenu: null });
  };

  toggleFilterPanel = () => {
    const { location } = this.props;

    switch (location.pathname) {
      case config.pages.store.orders:
      case config.pages.abaca.orders: {
        //? If we are on one of the orders pages
        //? Then we need filters
        this.setState({ hasFilters: true });
        break;
      }
      default: {
        this.setState({ hasFilters: false });
      }
    }
  };

  componentDidMount() {
    this.toggleFilterPanel();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.toggleFilterPanel();
    }
  }

  render() {
    const { classes, toggleQuickPanel, user, logout } = this.props;
    const { userMenu, hasFilters } = this.state;

    return (
      <div className={classNames(classes.root, 'flex flex-row')}>
        <div className="flex flex-1 px-24">
          <img
            alt="logo"
            src={Logo}
            className={classes.logo}
            onClick={() => {
              this.props.history.push(
                user && user.role === config.authRoles.admin
                  ? config.pages.abaca.home
                  : config.pages.store.home,
              );
            }}
          />

          {user.data.connectedAsClient && (
            <div className={classes.connectedAsClient}>
              <AntdIcon type="warning" style={{ marginRight: 5 }} />
              Connecté en tant que {config.authRolesNames[user.role]} de{' '}
              {user.store.brand_name}
            </div>
          )}
        </div>

        {user.role !== 'guest' && (
          <div className="flex">
            <FuseAnimate delay={150}>
              <div className="flex justify-center items-center px-12">
                {user.store && user.role !== 'admin'
                  ? user.store.brand_name
                  : 'ABACA'}
              </div>
            </FuseAnimate>

            <div className={classes.separator} />

            <FuseAnimate delay={300}>
              <Button className="h-64" onClick={this.userMenuClick}>
                <div
                  className={classNames(
                    classes.fullname,
                    'hidden sm:flex flex-col ml-12 items-start',
                  )}
                >
                  <Typography
                    component="span"
                    className="normal-case font-600 flex"
                  >
                    {user.role === 'guest'
                      ? user.data.displayName.split(' ')[0]
                      : [user.firstname, user.lastname]
                          .map(name => {
                            return name.charAt(0).toUpperCase() + name.slice(1);
                          })
                          .join(' ')}
                  </Typography>
                  <Typography
                    className="text-11 capitalize"
                    color="textSecondary"
                  >
                    {config.authRolesNames[user.role]}
                  </Typography>
                </div>

                <Icon className="text-16 ml-12 hidden sm:flex" variant="action">
                  keyboard_arrow_down
                </Icon>
              </Button>
            </FuseAnimate>

            <Popover
              open={Boolean(userMenu)}
              anchorEl={userMenu}
              onClose={this.userMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{
                paper: 'py-8',
              }}
            >
              {user.role === 'guest' ? (
                <React.Fragment>
                  <MenuItem component={Link} to="/login">
                    <ListItemIcon>
                      <Icon>lock</Icon>
                    </ListItemIcon>
                    <ListItemText className="pl-0" primary="Login" />
                  </MenuItem>
                  <MenuItem component={Link} to="/register">
                    <ListItemIcon>
                      <Icon>person_add</Icon>
                    </ListItemIcon>
                    <ListItemText className="pl-0" primary="Register" />
                  </MenuItem>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <MenuItem
                    component={Link}
                    to="/pages/profile"
                    onClick={this.userMenuClose}
                  >
                    <ListItemIcon>
                      <Icon>account_circle</Icon>
                    </ListItemIcon>
                    <ListItemText className="pl-0" primary="Mon profil" />
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      logout();
                      this.userMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <Icon>exit_to_app</Icon>
                    </ListItemIcon>
                    <ListItemText className="pl-0" primary="Déconnexion" />
                  </MenuItem>
                </React.Fragment>
              )}
            </Popover>
          </div>
        )}

        {/* google-type button In the bottom right corner */}
        {hasFilters && (
          <FuseAnimate animation="transition.expandIn" delay={300}>
            <Fab
              color="primary"
              aria-label="add"
              className={classNames('w-64 h-64', classes.filter)}
              onClick={() => toggleQuickPanel(true)}
            >
              <Icon color="secondary" fontSize="large">
                filter_list
              </Icon>
            </Fab>
          </FuseAnimate>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleQuickPanel: quickPanelActions.toggleQuickPanel,
      logout: authActions.logoutUser,
    },
    dispatch,
  );
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(MainToolbar),
  ),
);
