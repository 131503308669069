import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Tooltip, Icon, Button } from 'antd';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


// Utils
import config from '../config';
import requestService from '../services/requestService';

const styles = () => ({
  root: {
    margin: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  callToAction: {
    '& .customerCalled': {
      color: config.colors.greySilver,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .CTAButton': {
      color: config.colors.greenSuccess,
      border: '1px solid',
      padding: '6px 8px',
      cursor: 'pointer',
      fontSize: 16,
      borderRadius: 50,
      fontWeight: 700,
    },
  },
});

class PhoneCTA extends PureComponent {
  static propTypes = {
    customer: PropTypes.object,
    order: PropTypes.object,
  };



  toggleCallState = e => {
    const { openDialog, closeDialog, order } = this.props;
    
    // Open
    openDialog({
      children: config.dialogContent.customerContact,
      order: order,
      onCloseDialog: async () => {
        await this.cancelCallState()

        closeDialog()
      }
    });

    // Don't expand panel
    e.stopPropagation();
  };



  cancelCallState = async () => {
    const { isCustomerCalled } = this.state;
    const { order } = this.props;

    const response = await new requestService().putOrder({
      id: order.id,
      is_customer_called: !isCustomerCalled ? 1 : 0,
    });

    if (response && response.data) {
      this.setState({
        isCustomerCalled: !isCustomerCalled,
      });
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      isCustomerCalled: props.order.is_customer_called,
    };
  }

  getCTAButton = () => {
    const { classes } = this.props;
    const { isCustomerCalled } = this.state;

    return (
      <div className={classes.callToAction}>
        {isCustomerCalled ? (
          <div className={'customerCalled'}>
            <span>☎ Client déjà contacté</span>
            <Button type="link" onClick={this.cancelCallState}>
              <Icon
                  type="close-circle"
                  theme="outlined"
                /> Annuler
            </Button>
          </div>
        ) : (
          <Tooltip title="Signaler que ce client a déjà été contacté">
            <div className={'CTAButton'} onClick={this.toggleCallState}>
              <Icon
                  type="calendar"
                  theme="outlined"
                  style={{ marginRight: 4 }}
                /> Planifier la livraison
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.root)}>
        <div>{this.getCTAButton()}</div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openDialog: Actions.openDialog,
      closeDialog: Actions.closeDialog,
    },
    dispatch,
  );
}

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps,
  )(PhoneCTA),
);
