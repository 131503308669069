import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import 'styles/components/autocomplete.css';

class GoogleAddressAutocomplete extends PureComponent {
  assocAddressFields = {
    street_number: 'number',
    route: 'way',
    locality: 'city',
    administrative_area_level_1: 'region',
    country: 'country',
    postal_code: 'postal_code',
  };

  addressFieldsTypes = {
    street_number: 'long_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
  };

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    console.log('handlePlaceChanged', place);

    if (place && place.hasOwnProperty('address_components')) {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (this.addressFieldsTypes[addressType]) {
          let label = this.assocAddressFields[addressType];
          let value =
            place.address_components[i][this.addressFieldsTypes[addressType]];

          console.log('address', value, label);
          this.props.onChange(value, label);
        }
      }
    }
  }

  getDefaultAddress = () => {
    const { address } = this.props;
    // Check for a filled address
    if (address && address.city) {
      return `${address.number}, ${address.way}, ${address.postal_code}, ${address.city}`;
    } else {
      return undefined;
    }
  };

  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }

  componentDidMount() {
    const searchOptions = {
      types: ['geocode'],
      location: new global.google.maps.LatLng(43.6970823, 7.1456501),
      radius: 2000,
    };

    this.autocomplete = new global.google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      searchOptions,
    );

    this.autocomplete.setFields(['address_component', 'place_id']);
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  render() {
    return (
      <div className="googleAddressAutocompleteInputWrapper">
        <input
          ref={this.autocompleteInput}
          defaultValue={this.getDefaultAddress()}
          id="autocomplete"
          className="ant-input"
          placeholder="Entrer l'adresse"
          type="text"
        ></input>
      </div>
    );
  }
}

GoogleAddressAutocomplete.propTypes = {
  onChange: PropTypes.func,
  address: PropTypes.object,
};

export default GoogleAddressAutocomplete;
