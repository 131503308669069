import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Input, Button } from 'antd';
import PropTypes from 'prop-types';

const { TextArea } = Input;

const styles = () => ({
  root: {
    padding: 10,
    fontSize: '1.6rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
});

class TypeComment extends Component {
  static propTypes = {
    onSend: PropTypes.func,
    onCancel: PropTypes.func,
    isSubmitting: PropTypes.bool,
  };

  static defaultProps = {
    onSend: () => {},
    onCancel: () => {},
    isSubmitting: false,
  };

  handleTextChange = event => {
    this.setState({
      value: event.target.value,
    });
  };

  state = {
    value: '',
    isSending: false,
  };

  render() {
    const { classes, onSend, onCancel, isSubmitting } = this.props;
    const { value, isSending } = this.state;

    return (
      <div className={classNames(classes.root, 'type-comment-wrapper')}>
        <TextArea
          rows={1}
          onChange={this.handleTextChange}
          value={value}
          autoFocus
          style={{ marginBottom: 8 }}
        />

        <Button
          type="primary"
          htmlType="submit"
          disabled={value === ''}
          loading={isSubmitting}
          onClick={() => {
            this.setState({ isSending: true });
            onSend(value);
          }}
        >
          Envoyer
        </Button>

        {!isSending && (
          <Button type="link" onClick={() => onCancel()}>
            Annuler
          </Button>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TypeComment);
