import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Tooltip, Icon, Form, DatePicker } from 'antd';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import frFR from 'antd/lib/date-picker/locale/fr_FR';
import moment from 'moment';

// Services

import requestService from '../services/requestService';
import Notification   from 'services/Notification';
import FormService from 'services/Form';
// Store
import * as Actions from 'store/actions';

import config from '../config';

const FormItem = Form.Item

const styles = () => ({
  root: {
    '& a': {
      fontSize: 17,
      '&:hover': {
        color: config.colors.yellowAnzac,
      },
      '&.customerNotCalled': {
        color: config.colors.greenSuccess,
      },
      '&.customerCalled': {
        color: config.colors.greySilver,
      },
    },
  },
  hr: {
    height: 3,
    border: 0,
    marginBottom: 22,
    backgroundImage: `linear-gradient(to right, #fff, ${config.colors.yellowGoldenDream}, ${config.colors.yellowAnzac}, #fff)`,
  },
  formControl: {
    margin: 15,
    [`@media(max-width: ${config.responsive.small}px)`]: {
      margin: 10,
    },
  },
  error: {
    color: config.colors.redFailed,
    textAlign: 'center',
  },
});

class CustomerContact extends React.Component {
  static defaultProps = {
    customer: null,
    order: null,
  }

  static propTypes = {
    customer: PropTypes.object,
    order: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      phoneNumber: 'Aucun numéro trouvé',
      email: 'Aucun email trouvé',
      isCustomerCalled: props.order.is_customer_called,
    };

    this.form = new FormService(this.props.form)

    this.dateFormat = 'DD/MM/YYYY';
  }

  componentDidMount() {
    const { customer } = this.props;
    const fallBackText = 'Aucun numéro trouvé';

    this.setState({
      email: customer.email,
      phoneNumber: customer.mobile_number
        ? customer.mobile_number.replace(/-/g, ' ')
        : customer.phone_number
          ? customer.phone_number
          : fallBackText,
    });
  }

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  displayPhoneNumber = () => {
    const { phoneNumber, isCustomerCalled } = this.state;

    return (
      <Tooltip title="Prenez rendez-vous pour la livraison">
        <a
          href={`tel:${phoneNumber}`}
          className={isCustomerCalled ? 'customerCalled' : 'customerNotCalled'}
        >
          <Icon
            type="phone"
            theme="filled"
            twoToneColor={config.colors.greenSuccess}
            style={{ marginRight: 10 }}
          />
          {phoneNumber}
        </a>
      </Tooltip>
    );
  };

  displayEmail = () => {
    const { email, isCustomerCalled } = this.state;

    return (
      <Tooltip title="Prenez rendez-vous pour la livraison" styles={{zIndex: 9999}}>
        <a
          href={`mailto:${email}`}
          className={isCustomerCalled ? 'customerCalled' : 'customerNotCalled'}
        >
          <Icon
            type="mail"
            theme="filled"
            twoToneColor={config.colors.greenSuccess}
            style={{ marginRight: 10 }}
          />
          {email}
        </a>
      </Tooltip>
    );
  };

  handleCancel = (e) => {
    this.props.onCloseDialog();
  };

  handleSubmit = (e) => {
    const { isCustomerCalled } = this.state;
    const { order, updateOrder } = this.props;

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('values', values)
        this.setState({
          ...values,
          loading: true
        }, async () => {

          const payload = {
            is_customer_called: !isCustomerCalled ? 1 : 0,
            estimated_delivery_at: values.delivery_at ? values.delivery_at.format('YYYY-MM-DD') : null,
          }
          
          // Update order data
          try {
            var response = await new requestService().putOrder({
              id: order.id,
              ...payload
            });
          } catch(error) {

          }

          if (response && response.data) {

            await updateOrder(order.id, payload)

            // If order has been updated
            setTimeout(() => Notification.display(
              {
                content: 'La commande a été mise à jour',
                title: "Bravo 😸",
                level: 'SUCCESS',
              },
              {
                contentType: 'modal',
              },
            ), 500)

            this.props.onCloseDialog();

            this.setState({
              loading: false
            })
          }
        });

        
      }
    });
  };

  render() {
    const { classes, form, order } = this.props;
    const { phoneNumber, email, loading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div className={classNames(classes.root)}>
        <DialogTitle id="alert-dialog-title">Planification de la livraison</DialogTitle>

        <hr className={classes.hr} />

        <DialogContent>
          <div>{phoneNumber && this.displayPhoneNumber()}</div>
          <div>{email && this.displayEmail()}</div>

          <Form onSubmit={this.handleSubmit}>
            
            <FormItem hasFeedback label="Date de livraison prévue">
              {getFieldDecorator('delivery_at', {
                rules: [
                  {required: true, message: 'Date obligatoire'},
                  {required: true, type: 'date', validator: this.form.validateField, message: ['Date non valide']}
                ], initialValue: order.estimated_delivery_at && moment(order.estimated_delivery_at), validateFirst: true})(
                <DatePicker
                  locale={frFR}
                  format={this.dateFormat}
                  disabledDate={this.disabledDate}
                />
              )}
            </FormItem>
          </Form>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCancel} color="default">
            Annuler
          </Button>
          <Button onClick={this.handleSubmit} color="primary" disabled={loading}>
            Confirmer
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const CustomerContactForm = Form.create()(CustomerContact)

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateOrder: Actions.updateOrder,
    },
    dispatch,
  );
};

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps,
  )(CustomerContactForm),
);
