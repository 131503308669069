import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FuseAnimateGroup } from '@fuse';
import { Empty } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import InputSearchBar from 'components/InputSearchBar';
import TouringItem from 'components/TouringItem';

// Config
import config from 'config';

// services
import requestService from '../services/requestService';

// Store
import * as Actions from 'store/actions';

// utils
import { getActiveStatuses } from 'utils';

const styles = () => ({
  root: {
    paddingTop: 40,
    maxWidth: config.responsive.medium - 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  activeFilters: {
    color: config.colors.greyDove,
    marginBottom: 20,
    fontSize: 13,
    alignItems: 'center',
  },
  searchBar: {
    padding: '10px 0',
  },
  ordersListModeSwitcherWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

class TouringContainer extends Component {
  pinOrderOnTop = orderToPin => {
    const { touringContainer, pinnedContainer } = this.state;

    const _touringContainer = [...touringContainer].filter((order, i) => {
      if (order.id === orderToPin.id) {
        orderToPin.initialIndex = i;
      }

      if (order.id !== orderToPin.id) {
        return true;
      }
    });

    orderToPin.isPinned = true;
    const _pinnedContainer = [...pinnedContainer, orderToPin];

    this.setState(
      {
        touringContainer: _touringContainer,
      },
      () => {
        setTimeout(() => {
          this.setState({
            pinnedContainer: _pinnedContainer,
          });
        }, 400);
      },
    );
  };

  unpinOrder = orderToUnpin => {
    const { pinnedContainer, touringContainer } = this.state;

    const _pinnedContainer = [...pinnedContainer].filter(
      order => order.id !== orderToUnpin.id,
    );

    orderToUnpin.isPinned = false;
    let _touringContainer = [...touringContainer];

    _touringContainer.splice(orderToUnpin.initialIndex, 0, orderToUnpin);

    this.setState(
      {
        pinnedContainer: _pinnedContainer,
      },
      () => {
        setTimeout(() => {
          this.setState({
            touringContainer: _touringContainer,
          });
        }, 400);
      },
    );
  };

  updateItems = () => {
    const { data } = this.props;
    const orders = [...data];
    const touringContainer = [];

    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      touringContainer.push(order);
    }

    this.setState({ touringContainer });
  };

  handleOnEnterSearchBar = async value => {
    const { storeAllOrders } = this.props;

    const incomingValue = value.trim();

    this.setState({ isSearchBarLoading: true });

    if (incomingValue) {
      // Filter orders by name/city of delivery
      const response = await new requestService().getOrders(
        true,
        incomingValue,
      );
      if (response && response.data) {
        const orders = response.data;
        storeAllOrders(orders);
      }

      this.setState({ isSearchBarFilterActive: true });
    } else {
      // Remove filters
      const response = await new requestService().getOrders();
      if (response && response.data) {
        const orders = response.data;
        storeAllOrders(orders);
      }

      this.setState({ isSearchBarFilterActive: false });
    }

    this.setState({ isSearchBarLoading: false });
  };

  state = {
    touringContainer: [],
    pinnedContainer: [],
    isSearchBarFilterActive: false,
    isSearchBarLoading: false,
  };

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.updateItems();
    }
  }

  componentDidUpdate(prevProps) {
    const { data, listMode } = this.props;

    if (prevProps.data !== data || prevProps.listMode !== listMode) {
      this.updateItems();
    }
  }

  render() {
    const { classes, activeFilters } = this.props;
    const {
      isSearchBarFilterActive,
      isSearchBarLoading,
      touringContainer,
      pinnedContainer,
    } = this.state;

    return (
      <div className={classNames(classes.root, 'accordeon-container')}>
        <div
          className={classNames(
            classes.activeFilters,
            'flex justify-between md:p-0 px-6',
          )}
        >
          <span>
            Période du {activeFilters.dates.from.format('DD/MM/YYYY')} au{' '}
            {activeFilters.dates.to.format('DD/MM/YYYY')}
          </span>
          <span>{getActiveStatuses(activeFilters)}</span>
          <div className={classes.searchBar}>
            <InputSearchBar
              placeholder="Par nom, par ville, par numéro de cmd..."
              onEnterSearchBar={this.handleOnEnterSearchBar}
              isSearchBarLoading={isSearchBarLoading}
              isSearchBarFilterActive={isSearchBarFilterActive}
            />
          </div>
        </div>

        <FuseAnimateGroup
          enter={{ animation: 'transition.slideLeftBigIn' }}
          leave={{ animation: 'transition.bounceLeftOut' }}
        >
          {pinnedContainer.map(order => {
            return (
              <div className={classes.accordeonItemWrapper} key={order.id}>
                <TouringItem
                  key={order.id}
                  order={order}
                  isPinned={order.isPinned || false}
                  onPinOrder={() => this.pinOrderOnTop(order)}
                  onUnpinOrder={() => this.unpinOrder(order)}
                />
              </div>
            );
          })}
        </FuseAnimateGroup>

        <FuseAnimateGroup
          enter={{ animation: 'transition.slideLeftBigIn' }}
          leave={{ animation: 'transition.bounceLeftOut' }}
        >
          {touringContainer.length > 0 ? (
            touringContainer.map(order => {
              return (
                <div className={classes.accordeonItemWrapper} key={order.id}>
                  <TouringItem
                    key={order.id}
                    order={order}
                    isPinned={order.isPinned || false}
                    onPinOrder={() => this.pinOrderOnTop(order)}
                    onUnpinOrder={() => this.unpinOrder(order)}
                  />
                </div>
              );
            })
          ) : (
            <Empty description="Aucune commande" />
          )}
        </FuseAnimateGroup>
      </div>
    );
  }
}

const mapStateToProps = ({ abaca }) => ({
  listMode: abaca.orders.listMode,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      storeAllOrders: Actions.storeAllOrders,
    },
    dispatch,
  );
};

const connectedTouringContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TouringContainer);

export default withStyles(styles)(connectedTouringContainer);
