import Summary from './summary';
import config from 'config';

export const SummaryPageConfig = {
  routes  : [
    {
      path     : config.pages.store.summary,
      component: Summary
    },
    {
      path     : config.pages.abaca.summary,
      component: Summary
    },
  ]
};
