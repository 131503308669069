import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Tooltip, Icon, Form, DatePicker } from 'antd';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import frFR from 'antd/lib/date-picker/locale/fr_FR';
import moment from 'moment';

// Services

import Notification   from 'services/Notification';
import FormService from 'services/Form';
// Store
import * as Actions from 'store/actions';

import config from 'config';

import { postNewOrderStatus } from 'utils';

const FormItem = Form.Item

const styles = () => ({
  root: {
    '& a': {
      fontSize: 17,
      '&:hover': {
        color: config.colors.yellowAnzac,
      },
      '&.customerNotCalled': {
        color: config.colors.greenSuccess,
      },
      '&.customerCalled': {
        color: config.colors.greySilver,
      },
    },
  },
  hr: {
    height: 3,
    border: 0,
    marginBottom: 22,
    backgroundImage: `linear-gradient(to right, #fff, ${config.colors.yellowGoldenDream}, ${config.colors.yellowAnzac}, #fff)`,
  },
  formControl: {
    margin: 15,
    [`@media(max-width: ${config.responsive.small}px)`]: {
      margin: 10,
    },
  },
  error: {
    color: config.colors.redFailed,
    textAlign: 'center',
  },
});

class OrderDelivery extends React.Component {
  static defaultProps = {
    order: null,
  }

  static propTypes = {
    order: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.form = new FormService(this.props.form)

    this.dateFormat = 'DD/MM/YYYY';
  }

  handleCancel = (e) => {;
      
    this.props.closeDialog({onCloseDialog: null});
  };
  
  handleSubmit = (e) => {
    const { order, updateOrder, onCloseDialog } = this.props;

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('values', values)
        this.setState({
          ...values,
          loading: true
        }, async () => {

          const payload = {
            delivered_at: values.delivered_at ? values.delivered_at.format('YYYY-MM-DD') : null,
          }
          
          // Update order data
          try {
            var response = await postNewOrderStatus(order.id, 'delivered', null, payload)
          } catch(error) {

          }

          if (response && response.data) {
            payload.status = 'delivered'
            await updateOrder(order.id, payload)

            // If order has been updated
            setTimeout(() => Notification.display(
              {
                content: 'La commande a été mise à jour',
                title: "Bravo 😸",
                level: 'SUCCESS',
              },
              {
                contentType: 'modal',
              },
            ), 500)

            onCloseDialog()
            this.handleCancel()

            this.setState({
              loading: false
            })
          }
        });

        
      }
    });
  };

  render() {
    const { classes, form, order } = this.props;
    const { loading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div className={classNames(classes.root)}>
        <DialogTitle id="alert-dialog-title">Date de la livraison effectuée</DialogTitle>

        <hr className={classes.hr} />

        <DialogContent>

          <Form onSubmit={this.handleSubmit}>
            
            <FormItem hasFeedback label="Date de livraison finale">
              {getFieldDecorator('delivered_at', {
                rules: [
                  {required: true, message: 'Date obligatoire'},
                  {required: true, type: 'date', validator: this.form.validateField, message: ['Date non valide']}
                ], initialValue: order.delivered_at && moment(order.delivered_at), validateFirst: true})(
                <DatePicker
                  locale={frFR}
                  format={this.dateFormat}
                />
              )}
            </FormItem>
          </Form>
          
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleCancel} color="default">
            Annuler
          </Button>
          <Button onClick={this.handleSubmit} color="primary" disabled={loading}>
            Confirmer
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const OrderDeliveryForm = Form.create()(OrderDelivery)

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateOrder: Actions.updateOrder,
      closeDialog: Actions.closeDialog,
    },
    dispatch,
  );
};

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps,
  )(OrderDeliveryForm),
);
