import Stock from './stock';
import config from 'config';

export const StockConfig = {
  routes: [
    {
      path: config.pages.store.stock,
      component: Stock,
    },
    {
      path: config.pages.abaca.stock,
      component: Stock,
    },
  ],
};
