import React, { PureComponent } from 'react';
import { Alert, Icon } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import _cloneDeep from 'lodash/cloneDeep';
import _some from 'lodash/some';

// Components
import Title from 'components/Title';
import SubmitButton from 'components/SubmitButton';
import Stepper from 'components/Stepper';
import NewStoreStaff from 'components/NewStoreStaff';

// Config & service
import config from 'config';
import requestService from 'services/requestService';

const styles = () => ({
  root: {
    height: '100vh',
    '& .cardWrapper': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '120px',
      marginBottom: '50px',
    },
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepperWrapper: {
    marginTop: 15,
    maxWidth: config.responsive.medium,
    margin: 'auto',
  },
  lastnameWrapper: {
    marginTop: 10,
    marginBottom: 10,
  },
  alertWrapper: {
    marginLeft: 20,
  },
});

class NewStoreOwnerAbacaPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isEmptyInput: true,
      isLoading: false,
      ownerContactInfo: {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
      },
      isOwnerAlreadyExists: false,
      storeId: '',
    };
  }

  async componentDidMount() {
    const { newStoreParam, authRoles } = config;

    const storeId = new URLSearchParams(window.location.search).get(
      newStoreParam.storeId,
    );
    if (storeId) {
      const { data } = await new requestService().getStoreUsers(
        storeId,
        authRoles.owner,
      );
      if (data && data.users) {
        console.log('getStoreUsers', data);
        // Set owner
        this.setState({
          ownerContactInfo: data.users.length ? {
            id: data.users[0].id,
            firstname: data.users[0].firstname,
            lastname: data.users[0].lastname,
            email: data.users[0].email,
          } : this.state.ownerContactInfo,
          isOwnerAlreadyExists: data.users.length,
          storeId: storeId,
        });
      }
    } else {
      this.setState({
        error: 'Identifiant de boutique introuvable',
      });
    }
  }

  isEmptyInput = () => {
    const { ownerContactInfo } = this.state;
    let contactWithoutId = { ...ownerContactInfo };
    delete contactWithoutId.id;
    // It returns true if it finds an empty string in contact
    const isEmptyString = _some(contactWithoutId, function(obj) {
      return obj === '';
    });
    return isEmptyString;
  };

  checkDataValidity = () => {
    let areFieldsFilled = false;

    if (this.isEmptyInput()) {
      if (!this.state.isEmptyInput) {
        this.setState({ isEmptyInput: true });
      }
      areFieldsFilled = false;
    } else {
      if (this.state.isEmptyInput) {
        this.setState({ isEmptyInput: false });
      }
      areFieldsFilled = true;
    }

    return areFieldsFilled;
  };

  handleChange = (label, value) => {
    const { ownerContactInfo } = this.state;

    this.setState({
      ownerContactInfo: {
        ...ownerContactInfo,
        [label]: value,
      },
    });
  };

  submitHandler = async () => {
    const { ownerContactInfo, isOwnerAlreadyExists, storeId } = this.state;
    const { email, firstname, lastname, id } = ownerContactInfo;
    const { authRoles, newStoreParam } = config;
    const { owner } = authRoles;

    this.setState({ isLoading: true });

    let createOwnerInfo = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      role: owner,
      store_id: storeId,
    };

    if (isOwnerAlreadyExists && id) {
      // If it exists add its id
      createOwnerInfo = Object.assign(createOwnerInfo, { id });
    }

    const { data } = isOwnerAlreadyExists
      ? await new requestService().updateUser(createOwnerInfo)
      : await new requestService().createUser(createOwnerInfo);

    if (data && data.status) {
      // Success
      this.props.history.push(
        `${config.pages.abaca.newStoreSeller}?${
          newStoreParam.storeId
        }=${storeId}`,
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { error, ownerContactInfo, isEmptyInput, isLoading } = this.state;
    const { email, firstname, lastname } = ownerContactInfo;

    return (
      <div className={classes.root}>
        <Title
          text={error ? 'Erreur' : 'Nouveau gérant'}
          helperTxt={error || ''}
          isLarge={true}
        />

        {!error && (
          <div className={classes.formWrapper}>
            <div className={classes.stepperWrapper}>
              <Stepper current={1} steps={config.stepper.newStore} />
            </div>

            <div className="cardWrapper">
              <NewStoreStaff
                title="GERANT"
                icon={
                  <Icon
                    type="crown"
                    style={{ color: config.colors.yellowAnzac }}
                  />
                }
                onChange={this.handleChange}
                firstname={firstname}
                lastname={lastname}
                email={email}
              />

              <div className={classes.alertWrapper}>
                <Alert
                  style={{ maxWidth: 350, marginBottom: 10 }}
                  message="Information"
                  description="Le gérant du magasin a la responsabilité de passer commande au fournisseur une fois qu'une nouvelle demande de livraison a été initiée par l'un de ses vendeurs."
                  type="info"
                  showIcon
                />

                {isEmptyInput && (
                  <Alert
                    message="Des champs sont encore vides"
                    description="Tous les champs sont obligatoires."
                    type="warning"
                    showIcon
                  />
                )}
              </div>
            </div>

            <SubmitButton
              title="Suite"
              isAllowed={this.checkDataValidity()}
              onClick={this.submitHandler}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(NewStoreOwnerAbacaPage);
