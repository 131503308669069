import * as Actions from '../../actions/abaca';
import moment from 'moment';

import config from 'config';

const initialState = {
  all: [],
  filtered: [],
  activeFilters: {
    dates: {
      from: moment()
        .subtract('year', '1')
        .startOf('year'),
      to: moment().endOf('year'),
    },
    statuses: {
      paused: true,
      in_progress: true,
      provider_ordered: true,
      canceled: false,
      delivered: false,
      ready: true,
    },
  },
  listMode: config.orderListMode.accordeon,
};

const ordersReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.STORE_ALL_ORDERS: {
      return {
        ...state,
        all: action.payload,
      };
    }

    case Actions.ADD_ONE_ORDER_IN_ALL_ORDERS_LIST: {
      return {
        ...state,
        all: [...state.all, action.payload],
      };
    }

    case Actions.SET_FILTERED_ORDERS: {
      return {
        ...state,
        filtered: action.payload,
      };
    }

    case Actions.SET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };
    }

    case Actions.CLEAR_ORDERS: {
      return initialState;
    }

    case Actions.SET_LIST_MODE: {
      return {
        ...state,
        listMode: action.payload,
      };
    }

    case Actions.UPDATE_ORDER: {
      let newAll = [...state.all];
      let newFiltered = [...state.filtered];

      const index = state.all.findIndex(order => order.id == action.orderId);
      const filteredIndex = state.filtered.findIndex(
        order => order.id == action.orderId,
      );

      if (filteredIndex !== -1) {
        newFiltered[filteredIndex] = {
          ...newFiltered[filteredIndex],
          ...action.payload,
        };
      }

      newAll[index] = {
        ...newAll[index],
        ...action.payload,
      };

      return {
        ...state,
        all: newAll,
        filtered: newFiltered,
      };
    }

    default:
      return state;
  }
};

export default ordersReducer;
