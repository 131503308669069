import moment from 'moment';
import config from '../config/index';

export default [
    {
      orderId: '65498789',
      customerId: "456aze6546qxd",
      client_name: "Nicolas Dupont",
      storeName: 'Literie 3000',
      dates: {
          started: "21-11-2018",
          delivered: null,
          events: {
              paused: "04-12-2018",
              canceled: null,
          },
      },
      items: [
          {
            id: 'A1Z2E3R4T5',
            description: "Canapé convertible",
            price: 550,
            provider: "Poltrona Frau",
            image: null,
            mustBeOrdered: true,
            status: config.productStatusCodes.TO_BE_RECEIVED,
            stockageArea: undefined,
            cubicMeter: null,
            files: [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
              },
              {
                uid: '-2',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
              }
            ]
          },
          {
            id: 'W8X9C9S5G1',
            description: "Cuisinière",
            price: 300,
            provider: "VIKING",
            image: null,
            mustBeOrdered: false,
            status: config.productStatusCodes.AVAILABLE_AT_STORE,
            stockageArea: undefined,
            cubicMeter: null,
          }
      ],
      status:"paused",
      comments: [
        {
          id: 1,
          author: 'Philippe Bouron',
          date: moment().subtract(3, 'days'),
          text: 'Le client est en travaux et il faut attendre son appel pour livrer.'
        },
        {
          id: 2,
          author: 'Han solo',
          date: moment(),
          text: 'OK on peut livrer. Attention canapé rouge pas noir finalement! OK on peut livrer. Attention canapé rouge pas noir finalement! OK on peut livrer. Attention canapé rouge pas noir finalement!'
        },
      ]
    },
    {
      orderId: '12221212',
      customerId: "Uau225050woope22o",
      client_name: "Florent Delahouse",
      storeName: 'Literie 3000',
      dates: {
          started: "23-11-2018",
          delivered: null,
          events: {
            paused: null,
            canceled: null,
          },
      },
      items: [
          {
              description: "Canapé convertible",
              price: 550,
              provider: "Poltrona Frau",
              image: null,
              mustBeOrdered: false,
              status: config.productStatusCodes.TO_BE_RECEIVED,
              stockageArea: undefined,
              cubicMeter: null,
          },
          {
              description: "Cuisinière",
              price: 300,
              provider: "VIKING",
              image: null,
              mustBeOrdered: false,
              status: config.productStatusCodes.TO_BE_RECEIVED,
              stockageArea: undefined,
              cubicMeter: null,
          }
      ],
      status:"inProgress",
      comments: null
    },
    {
      orderId: '12221212',
      customerId: "Uau223sae22o",
      client_name: "Jean Neymar",
      storeName: 'Darty',
      dates: {
          started: "21-01-2019",
          delivered: null,
          events: {
              paused: null,
              canceled: "22-01-2019",
          },
      },
      items: [
          {
              description: "Cuisinière",
              price: 300,
              provider: "VIKING",
              image: null,
              mustBeOrdered: false,
              status: config.productStatusCodes.AVAILABLE_AT_STORE,
          }
      ],
      status:"canceled",
      comments: null,
    },
    {
      orderId: '12221212',
      customerId: "10298KSHDQKJSBF28",
      client_name: "Romain Senbas",
      storeName: 'Conforama',
      dates: {
          started: "01-01-2019",
          delivered: null,
          events: {
              paused: null,
              canceled: null,
          },
      },
      items: [
          {
            description: "Table basse",
            price: 150,
            provider: "Habitat",
            image: null,
            mustBeOrdered: false,
            status: config.productStatusCodes.AVAILABLE_AT_STORE,
          }
      ],
      status:"inProgress",
      comments: [
        {
          id: 1,
          author: 'Philippe Bouron',
          date: moment().subtract(3, 'days'),
          text: 'Le client est en travaux et il faut attendre son appel pour livrer.'
        },        
      ],
    },
    {
      orderId: '12221212',
      customerId: "a78z8e98q7df456",
      client_name: "Tristan Gentil",
      storeName: 'Literie 3000',
      dates: {
          started: "02-02-2019",
          delivered: "14-02-2019",
          events: {
            paused: null,
            canceled: null,
          },
      },
      items: [
          {
              description: "Table salle à manger",
              price: 200,
              provider: "IKEA",
              image: null,
              mustBeOrdered: false,
              status: config.productStatusCodes.RECEIVED,
          },
          {
              description: "Table salle à manger",
              price: 200,
              provider: "IKEA",
              image: null,
              mustBeOrdered: false,
              status: config.productStatusCodes.AVAILABLE_AT_STORE,
          },
          {
              description: "Table salle à manger",
              price: 200,
              provider: "IKEA",
              image: null,
              mustBeOrdered: false,
              status: config.productStatusCodes.AVAILABLE_AT_STORE,
          },
      ],
      status:"delivered",
      comments: [
        {
          id: 1,
          author: 'Philippe Bouron',
          date: moment().subtract(3, 'days'),
          text: 'Le client est en travaux et il faut attendre son appel pour livrer.'
        },
        {
          id: 2,
          author: 'Han solo',
          date: moment(),
          text: 'OK on peut livrer. Attention canapé rouge pas noir finalement! OK on peut livrer. Attention canapé rouge pas noir finalement! OK on peut livrer. Attention canapé rouge pas noir finalement! OK on peut livrer. Attention canapé rouge pas noir finalement!'
        },
      ]
    },
    {
      orderId: '12221212',
      customerId: "vb1x3c321xc1x",
      client_name: "Nicolas Dupont",
      storeName: 'Conforama',
      dates: {
          started: "21-11-2018",
          delivered: null,
          events: {
              paused: "04-12-2018",
              canceled: null,
          },
      },
      items: [
          {
              description: "Canapé convertible",
              price: 550,
              provider: "Poltrona Frau",
              image: null,
          },
          {
              description: "Cuisinière",
              price: 300,
              provider: "VIKING",
              image: null,
          }
      ],
      status:"paused",
      comments: [
        {
          id: 1,
          author: 'Philippe Bouron',
          date: moment().subtract(3, 'days'),
          text: 'Le client est en travaux et il faut attendre son appel pour livrer.'
        },
        {
          id: 2,
          author: 'Han solo',
          date: moment(),
          text: 'OK on peut livrer. Attention canapé rouge pas noir finalement!'
        },
      ],
    },
    {
      orderId: '12221212',
      customerId: "qd654q654s6q565qs",
      client_name: "Florent Delahouse",
      storeName: 'Conforama',
      dates: {
          started: "23-11-2018",
          delivered: null,
          events: {
              paused: null,
              canceled: null,
          },
      },
      items: [
          {
              description: "Canapé convertible",
              price: 550,
              provider: "Poltrona Frau",
              image: null,
          },
          {
              description: "Cuisinière",
              price: 300,
              provider: "VIKING",
              image: null,
          }
      ],
      status:"inProgress",
      comments: null,
    },
    {
      orderId: '12221212',
      customerId: "tyu7t8yu7t9u7ty9",
      client_name: "Romain Senbas",
      storeName: 'Conforama',
      dates: {
          started: "01-01-2019",
          delivered: null,
          events: {
              paused: null,
              canceled: null,
          },
      },
      items: [
          {
              description: "Table basse",
              price: 150,
              provider: "Habitat",
              image: null,
          }
      ],
      status:"inProgress",
      comments: [
        {
          id: 1,
          author: 'Philippe Bouron',
          date: moment().subtract(3, 'days'),
          text: 'Le client est en travaux et il faut attendre son appel pour livrer.'
        },
        {
          id: 2,
          author: 'Han solo',
          date: moment(),
          text: 'OK on peut livrer. Attention canapé rouge pas noir finalement!'
        },
      ],
    },
    {
      orderId: '12221212',
      customerId: "10298KSHDQKJSBF28",
      client_name: "Jean Neymar",
      dates: {
          started: "21-01-2019",
          delivered: null,
          events: {
              paused: null,
              canceled: "22-01-2019",
          },
      },
      items: [
          {
              description: "Cuisinière",
              price: 300,
              provider: "VIKING",
              image: null,
          }
      ],
      status:"canceled",
      comments: null,
    },
    {
      orderId: '12221212',
      customerId: "10298KSHDQKJSBF28",
      client_name: "Tristan Gentil",
      dates: {
          started: "02-02-2019",
          delivered: "14-02-2019",
          events: {
              paused: null,
              canceled: null,
          },
      },
      items: [
          {
              description: "Table salle à manger",
              price: 200,
              provider: "IKEA",
              image: null,
          },
          {
              description: "Table salle à manger",
              price: 200,
              provider: "IKEA",
              image: null,
          },
          {
              description: "Table salle à manger",
              price: 200,
              provider: "IKEA",
              image: null,
          },
      ],
      status:"delivered",
      comments: [
        {
          id: 1,
          author: 'Philippe Bouron',
          date: moment().subtract(3, 'days'),
          text: 'Le client est en travaux et il faut attendre son appel pour livrer.'
        },
        {
          id: 2,
          author: 'Han solo',
          date: moment(),
          text: 'OK on peut livrer. Attention canapé rouge pas noir finalement!'
        },
      ],
    },
    {
      orderId: '12221212',
      customerId: "10298KSHDQKJSBF28",
      client_name: "Nicolas Dupont",
      dates: {
          started: "21-11-2018",
          delivered: null,
          events: {
              paused: "04-12-2018",
              canceled: null,
          },
      },
      items: [
          {
              description: "Canapé convertible",
              price: 550,
              provider: "Poltrona Frau",
              image: null,
          },
          {
              description: "Cuisinière",
              price: 300,
              provider: "VIKING",
              image: null,
          }
      ],
      status:"paused",
      comments: [
        {
          id: 1,
          author: 'Philippe Bouron',
          date: moment().subtract(3, 'days'),
          text: 'Le client est en travaux et il faut attendre son appel pour livrer.'
        },
        {
          id: 2,
          author: 'Han solo',
          date: moment(),
          text: 'OK on peut livrer. Attention canapé rouge pas noir finalement!'
        },
      ],
    },
    {
      orderId: '12221212',
      customerId: "10298KSHDQKJSBF28",
      client_name: "Florent Delahouse",
      dates: {
          started: "23-11-2018",
          delivered: null,
          events: {
              paused: null,
              canceled: null,
          },
      },
      items: [
          {
              description: "Canapé convertible",
              price: 550,
              provider: "Poltrona Frau",
              image: null,
          },
          {
              description: "Cuisinière",
              price: 300,
              provider: "VIKING",
              image: null,
          }
      ],
      status:"inProgress",
      comments: null,
    },
    {
      orderId: '12221212',
      customerId: "10298KSHDQKJSBF28",
      client_name: "Romain Senbas",
      dates: {
          started: "01-01-2019",
          delivered: null,
          events: {
              paused: null,
              canceled: null,
          },
      },
      items: [
          {
              description: "Table basse",
              price: 150,
              provider: "Habitat",
              image: null,
          }
      ],
      status:"inProgress",
      comments: [
        {
          id: 1,
          author: 'Philippe Bouron',
          date: moment().subtract(3, 'days'),
          text: 'Le client est en travaux et il faut attendre son appel pour livrer.'
        },
        {
          id: 2,
          author: 'Han solo',
          date: moment(),
          text: 'OK on peut livrer. Attention canapé rouge pas noir finalement!'
        },
      ],
    },
    {
      orderId: '12221212',
      customerId: "10298KSHDQKJSBF28",
      client_name: "Tristan Gentil",
      dates: {
          started: "02-02-2019",
          delivered: "14-02-2019",
          events: {
              paused: null,
              canceled: null,
          },
      },
      items: [
          {
              description: "Table salle à manger",
              price: 200,
              provider: "IKEA",
              image: null,
          },
          {
              description: "Table salle à manger",
              price: 200,
              provider: "IKEA",
              image: null,
          },
          {
              description: "Table salle à manger",
              price: 200,
              provider: "IKEA",
              image: null,
          },
      ],
      status:"delivered",
      comments: [
        {
          id: 1,
          author: 'Philippe Bouron',
          date: moment().subtract(3, 'days'),
          text: 'Le client est en travaux et il faut attendre son appel pour livrer.'
        },
        {
          id: 2,
          author: 'Han solo',
          date: moment(),
          text: 'OK on peut livrer. Attention canapé rouge pas noir finalement!'
        },
      ],
    },
]