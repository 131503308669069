import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import config from 'config/index';

import paused from 'static/icons/svg/paused.svg';
import providerOrder from 'static/icons/svg/providerOrder.svg';
import inProgress from 'static/icons/svg/inProgress.svg';
import delivered from 'static/icons/png/delivered.png';
import canceled from 'static/icons/png/canceled.png';

const styles = () => ({
  statusItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '4px',
    border: '1px solid transparent',
    '&:hover': {
      border: `1px solid ${config.colors.yellowGoldenDream}`,
      backgroundColor: config.colors.greySeashell,
    },
  },
  statusItemTitle: {
    fontSize: 'smaller',
    textAlign: 'center',
    whiteSpace: 'normal',
    maxWidth: '75px',
    height: '44px',
  },
  disabled: {
    opacity: 0.15,
    cursor: 'not-allowed',
  },
});

class SwitchStatusItem extends PureComponent {
  static defaultProps = {
    title: 'Statut default',
    icon: 'canceled',
    isDisabled: false,
  };

  render() {
    const { classes, isDisabled, icon, onClick, title } = this.props;

    let properIcon;
    switch (icon) {
      case 'paused':
        properIcon = paused;
        break;
      case 'providerOrder':
        properIcon = providerOrder;
        break;
      case 'inProgress':
        properIcon = inProgress;
        break;
      case 'canceled':
        properIcon = canceled;
        break;
      case 'delivered':
        properIcon = delivered;
        break;
      default:
        properIcon = 'Default';
    }

    return (
      <div
        className={classNames(classes.statusItemWrapper, {
          [classes.disabled]: isDisabled,
        })}
        onClick={!isDisabled ? onClick : null}
      >
        <span className={classes.statusItemTitle}>{title}</span>
        <img src={properIcon} alt={properIcon} style={{ width: '50px' }} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SwitchStatusItem);
