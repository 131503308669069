import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FuseAnimate } from '@fuse';
import _capitalize from 'lodash/capitalize';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';

// Icons
import store from 'static/icons/png/volume.png';
import goldMan from 'static/icons/png/goldMan.png';
import crown from 'static/icons/png/crown.png';
import document from 'static/icons/png/document.png';

// Components
import Title from 'components/Title';
import HomeButton from 'components/HomeButton';

// Helpers & Config
import config from 'config';

// store
import { loginAsClientAction } from 'auth/store/actions/user.actions';

const { responsive, pages, newStoreParam, authRoles } = config;
const { abaca } = pages;

const styles = () => ({
  root: {
    '& .shop-options-wrapper': {
      paddingTop: 30,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: responsive.medium,
      margin: 'auto',
    },
    '& .connect-under': {
      textAlign: 'center',
      marginTop: 50,
    },
    '& .connect-under-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 50,
    },
  },
});

class SelectStorePage extends Component {
  connectUnderSelectedClientName = async () => {
    const { dispatch } = this.props;
    const { ownerUuid, selectedClientName } = this.state;
    this.setState({ isLoading: true });
    dispatch(loginAsClientAction(selectedClientName, ownerUuid));
  };

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      selectedClientName: '',
      selectedClientId: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedClientName = urlParams.get(newStoreParam.storeName);
    const selectedClientId = urlParams.get(newStoreParam.storeId);
    const ownerUuid = urlParams.get(newStoreParam.ownerUuid);

    if (selectedClientName && selectedClientId) {
      this.setState({ selectedClientName, selectedClientId, ownerUuid });
    } else {
      this.setState({ error: 'Veuillez sélectionner un magasin client' });
    }
  }

  render() {
    const { classes, user } = this.props;
    const {
      error,
      selectedClientName,
      selectedClientId,
      isLoading,
      ownerUuid,
    } = this.state;

    return (
      <div className={classNames('select-store-wrapper', classes.root)}>
        {!error && (
          <>
            <Title
              text={selectedClientName}
              helperTxt={'Que souhaitez-vous consulter ?'}
              isLarge={true}
            />

            <FuseAnimate animation="transition.slideRightBigIn" delay={300}>
              <React.Fragment>
                <div className="shop-options-wrapper">
                  <div>
                    <HomeButton
                      title={'COMMANDES'}
                      description={'Voir les commandes en cours'}
                      onClick={() =>
                        this.props.history.push(
                          `${abaca.orders}?&${
                            newStoreParam.storeId
                          }=${selectedClientId}`,
                        )
                      }
                    />

                    <HomeButton
                      icon={store}
                      title={'VOLUMES'}
                      description={'Volumes de stockage au mois'}
                      onClick={() =>
                        this.props.history.push(
                          `${abaca.volume}?clientName=${selectedClientName}&${
                            newStoreParam.storeId
                          }=${selectedClientId}`,
                        )
                      }
                    />
                  </div>
                  <div>
                    <HomeButton
                      icon={goldMan}
                      title={'VENDEURS'}
                      description={'Gérer les vendeurs'}
                      onClick={() =>
                        this.props.history.push(
                          `${abaca.newStoreSeller}?${
                            newStoreParam.storeId
                          }=${selectedClientId}`,
                        )
                      }
                    />

                    <HomeButton
                      icon={crown}
                      title={'GERANT'}
                      description={'Modifier le gérant'}
                      onClick={() =>
                        this.props.history.push(
                          `${abaca.newStoreOwner}?${
                            newStoreParam.storeId
                          }=${selectedClientId}`,
                        )
                      }
                    />

                    <HomeButton
                      icon={document}
                      title={'DOCUMENTS'}
                      description={'Modifier les documents légaux'}
                      onClick={() =>
                        this.props.history.push(
                          `${abaca.newStoreLegals}?${
                            newStoreParam.storeId
                          }=${selectedClientId}`,
                        )
                      }
                    />
                  </div>
                </div>

                {user.role === authRoles.admin &&
                  ownerUuid && (
                    <div className="connect-under-wrapper">
                      <Button
                        loading={isLoading}
                        type="dashed"
                        onClick={() => this.connectUnderSelectedClientName()}
                      >
                        <Icon type="login" />
                        Se connecter en tant que {selectedClientName}
                      </Button>
                    </div>
                  )}
              </React.Fragment>
            </FuseAnimate>
          </>
        )}

        {error && <Title text="Erreur" subtitle={'Magasin inconnu'} />}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, abaca }) => ({
  user: auth.user,
  abaca,
});

const connectedComponent = connect(mapStateToProps)(SelectStorePage);

export default withStyles(styles)(connectedComponent);
