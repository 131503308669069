import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FuseAnimateGroup } from '@fuse';
import { Empty, Input, Tooltip, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import AccordeonItem from './AccordeonItem';

// Config
import config from 'config';

// services
import requestService from '../services/requestService';

// Store
import * as Actions from 'store/actions';

// utils
import { getActiveStatuses } from 'utils';

const { Search } = Input;

const styles = () => ({
  root: {
    paddingTop: 40,
    maxWidth: config.responsive.medium - 10,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  activeFilters: {
    color: config.colors.greyDove,
    marginBottom: 20,
    fontSize: 13,
    alignItems: 'center',
  },
  accordeonItemWrapper: {
    '&.unfocus': {
      opacity: '0.3 !important',
      transition: '0.4s',
    },
  },
  searchBar: {
    padding: '10px 0',
  },
});

class AccordeonContainer extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    origin: PropTypes.string,
    activeFilters: PropTypes.object.isRequired,
  };

  static defaultProps = {
    origin: config.origins.store,
  };

  updateAccordeonItems = () => {
    const { data, origin } = this.props;
    const orders = data;
    const Accordeons = [];

    function getComment(events) {
      // Get last event comment
      return events.length ? events[events.length - 1].comment : null;
    }

    let addressee, order;

    for (let i = 0; i < orders.length; i++) {
      order = orders[i];
      addressee =
        order.is_stock === 1
          ? 'Stock boutique'
          : `${order.customer.firstname} ${order.customer.lastname}`;

      Accordeons.push(
        <AccordeonItem
          key={'accordeonItem' + i}
          voucherNumber={order.voucher_number}
          status={order.status}
          firstOrderDate={order.created_at}
          orderTo={addressee}
          comment={getComment(order.events)}
          pausedDate={
            order.status === config.orderStatus.paused ? order.status_at : null
          }
          deliveredDate={
            order.status === config.orderStatus.delivered
              ? order.status_at
              : null
          }
          canceledDate={
            order.status === config.orderStatus.canceled
              ? order.status_at
              : null
          }
          subItems={order.products}
          customer={order.customer}
          storeName={order.store ? order.store.brand_name : null}
          origin={origin}
          order={order}
          onChange={this.onChange}
        />,
      );
    }
    this.setState({ container: Accordeons });
  };

  onChange = orderId => {
    this.setState({
      selected: orderId,
    });
  };

  handleOnEnterSearchBar = async value => {
    const { storeAllOrders } = this.props;

    const incomingValue = value.trim();

    this.setState({ isSearchBarLoading: true });

    if (incomingValue) {
      // Filter orders by name/city of delivery
      const response = await new requestService().getOrders(
        true,
        incomingValue,
      );
      if (response && response.data) {
        const orders = response.data;
        storeAllOrders(orders);
      }

      this.setState({ isSearchBarFilterActive: true });
    } else {
      // Remove filters
      const response = await new requestService().getOrders();
      if (response && response.data) {
        const orders = response.data;
        storeAllOrders(orders);
      }

      this.setState({ isSearchBarFilterActive: false });
    }

    this.setState({ isSearchBarLoading: false });
  };

  constructor(props) {
    super(props);

    this.state = {
      container: [],
      selected: null,
      isSearchBarFilterActive: false,
      isSearchBarLoading: false,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.updateAccordeonItems();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateAccordeonItems();
    }
  }

  render() {
    const { classes, activeFilters } = this.props;
    const {
      container,
      selected,
      isSearchBarFilterActive,
      isSearchBarLoading,
    } = this.state;

    return (
      <div className={classNames(classes.root, 'accordeon-container')}>
        <div
          className={classNames(
            classes.activeFilters,
            'flex justify-between md:p-0 px-6',
          )}
        >
          <span>
            Période du {activeFilters.dates.from.format('DD/MM/YYYY')} au{' '}
            {activeFilters.dates.to.format('DD/MM/YYYY')}
          </span>
          <span>{getActiveStatuses(activeFilters)}</span>
          <div className={classes.searchBar}>
            <Search
              placeholder="Par nom, par ville, par numéro de cmd..."
              onSearch={this.handleOnEnterSearchBar}
              enterButton
              allowClear
              loading={isSearchBarLoading}
              prefix={
                isSearchBarFilterActive && (
                  <Tooltip title="Filtre actif">
                    <Icon type="check-circle" style={{ color: 'green' }} />
                  </Tooltip>
                )
              }
            />
          </div>
        </div>

        <FuseAnimateGroup
          enter={{ animation: 'transition.slideLeftBigIn' }}
          leave={{ animation: 'transition.bounceLeftOut' }}
        >
          {container.length > 0 ? (
            container.map(item => {
              return (
                <div
                  className={classNames(classes.accordeonItemWrapper, {
                    unfocus: selected && selected !== item.props.order.id,
                  })}
                  key={item.props.order.id}
                >
                  {item}
                </div>
              );
            })
          ) : (
            <Empty description="Aucune commande" />
          )}
        </FuseAnimateGroup>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      storeAllOrders: Actions.storeAllOrders,
      setFilteredOrders: Actions.setFilteredOrders,
    },
    dispatch,
  );
};

const connectedAccordeonContainer = connect(
  null,
  mapDispatchToProps,
)(AccordeonContainer);

export default withStyles(styles)(connectedAccordeonContainer);
