import * as Actions from '../../actions/abaca';

const initialState = {
  allProductsList: [],
  allProvidersList: [],
  allCategoriesList: [],
};

const productsReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.STORE_ALL_PRODUCT_LIST: {
      return {
        ...state,
        allProductsList: action.payload.data,
      };
    }

    case Actions.STORE_ALL_PROVIDERS_LIST: {
      return {
        ...state,
        allProvidersList: action.payload.data,
      };
    }

    case Actions.STORE_ALL_CATEGORIES_LIST: {
      return {
        ...state,
        allCategoriesList: action.payload.data,
      };
    }

    case Actions.CLEAR_PRODUCTS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default productsReducer;
