import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _capitalize from 'lodash/capitalize';
import { bindActionCreators } from 'redux';

// Components
import AddComment from 'components/Commenter/AddComment/AddComment';
import TypeComment from 'components/Commenter/TypeComment/TypeComment';
import Comment from 'components/Commenter/Comment/Comment';
// Store
import * as Actions from 'store/actions/abaca';
// Service
import requestService from 'services/requestService';
// Config
import config from 'config';

const styles = () => ({
  root: {
    flexGrow: 1,
    borderRadius: 4,
  },
});

class Commenter extends PureComponent {
  static defaultProps = {
    comment: null,
    orderId: null,
  };

  static propTypes = {
    comment: PropTypes.string,
    orderId: PropTypes.number,
  };

  goToTypeComment = () => {
    this.setState({ step: config.commenterSteps.typeComment });
  };

  goToAddComment = () => {
    this.setState({ step: config.commenterSteps.addComment });
  };

  sendComment = async value => {
    const { orderId, user, storeOrderComment, updateOrder } = this.props;
    this.setState({ isLoading: true });

    // Add the author inside the comment
    const withAuthor = `${_capitalize(user.firstname)} ${_capitalize(
      user.lastname,
    )} : ${value}`;

    if (orderId) {
      await new requestService().postComment({ comment: withAuthor }, orderId);
    } else {
      storeOrderComment(withAuthor);

      // ToDo : update order comment in order redux store list
      // updateOrder(orderId, {})
    }

    this.setState({
      step: config.commenterSteps.showingComment,
      stateComment: withAuthor,
      isLoading: false,
    });
  };

  deleteComment = async () => {
    const { orderId, clearOrderComment } = this.props;

    this.setState({ isLoading: true });

    if (orderId) {
      await new requestService().deleteComment(orderId);
    } else {
      clearOrderComment();
    }

    this.setState({
      step: config.commenterSteps.addComment,
      isLoading: false,
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      step: config.commenterSteps.addComment,
      isLoading: false,
      stateComment: '',
    };
  }

  componentDidMount() {
    const { comment } = this.props;
    if (comment) {
      this.setState({
        step: config.commenterSteps.showingComment,
      });
    }
  }

  render() {
    const { classes, comment } = this.props;
    const { step, isLoading, stateComment } = this.state;

    return (
      <div className={classNames(classes.root, 'commenter-wrapper')}>
        {step === config.commenterSteps.addComment && (
          <AddComment onClick={this.goToTypeComment} />
        )}

        {step === config.commenterSteps.showingComment &&
          (comment || stateComment) && (
            <Comment
              comment={stateComment || comment}
              onDelete={this.deleteComment}
              isLoading={isLoading}
            />
          )}

        {step === config.commenterSteps.typeComment && (
          <TypeComment
            onSend={this.sendComment}
            onCancel={this.goToAddComment}
            isSubmitting={isLoading}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      storeOrderComment: Actions.storeOrderComment,
      clearOrderComment: Actions.clearOrderComment,
      updateOrder: Actions.updateOrder,
    },
    dispatch,
  );
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Commenter),
);
