import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Radio } from 'antd';
import classNames from 'classnames';

// Store
import * as Actions from 'store/actions/abaca';

// Components
import Title from 'components/Title';
import SubmitButton from 'components/SubmitButton';
import AddressData from 'components/AddressData';
import Stepper from 'components/Stepper';
import GoogleAddressAutocomplete from 'components/GoogleAddressAutocomplete';
import NameInput from 'components/NameInput';

// Config
import config from 'config';

// Helpers
import { getCustomerById, isAddressValid } from 'utils';

// Services
import requestService from 'services/requestService';

const selectAddress = {
  title: 'ADRESSE DE LIVRAISON',
  subtitle: "Sélectionnez l'adresse de livraison pour cette commande",
};

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto',
    marginTop: 0,
  },
  form: {
    maxWidth: '100%',
  },
  stepperWrapper: {
    marginTop: 15,
    maxWidth: config.responsive.medium,
    margin: 'auto',
  },
  billAddressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 30,
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontWeight: 700,
  },
  inputBillAddressWrapper: {
    marginTop: 10,
    minWidth: 300,
  },
  nameInputWrapper: {
    display: 'flex',
    '& > .name-input-wrapper:first-of-type': {
      marginRight: 5,
    },
  },
});

class SelectAddressPage extends Component {
  submitHandler = async () => {
    const {
      selectedAddress,
      billAddress,
      areBillAndDeliveryAddressesTheSame,
    } = this.state;
    const { storeSelectedAddress, storeBillAddress } = this.props;

    this.setState({ isLoading: true });
    
    // Store selected Address waiting for final submit
    storeSelectedAddress(selectedAddress);

    // Store bill address if different from delivery address
    if (!areBillAndDeliveryAddressesTheSame) {
      // create address
      const addressToCreate = {
        ...billAddress,
        customer_id: selectedAddress.customer_id,
      };
      let response = await new requestService().postAddress(addressToCreate);
      if (response.statusText !== 'OK') {
        return this.setState({
          error: 'Création adresse de facturation impossible',
        });
      }

      // Billing address name can be different from current customer
      // Then we must update address name
      if (billAddress.firstname && billAddress.lastname) {
        const addressInfoToUpdate = Object.assign(
          { firstname: billAddress.firstname, lastname: billAddress.lastname },
          { id: response.data.address.id },
        );
        response = await new requestService().putAddress(addressInfoToUpdate);
        if (response.statusText !== 'OK') {
          return this.setState({
            error: 'Modification adresse de facturation impossible',
          });
        }
      }

      storeBillAddress(response.data.address);
    }

    this.props.history.push(
      `${config.pages.store.summary}?customerId=${selectedAddress.customer_id}`,
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      error: '',
      target: 'customer',
      addresses: [],
      selectedAddress: '',
      areBillAndDeliveryAddressesTheSame: true,
      billAddress: {
        firstname: '',
        lastname: '',
        city: '',
        country: '',
        id: '',
        number: '',
        postal_code: '',
        way: '',
      },
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { allCustomersList } = this.props;

    try {
      const customer = await getCustomerById(
        new URLSearchParams(window.location.search).get('customerId'),
        allCustomersList,
      );
      this.setState({ addresses: customer.addresses });
    } catch (error) {
      this.setState({ error: error.toString() });
    }
  }

  onBillAddressRadioChange = e => {
    this.setState({
      areBillAndDeliveryAddressesTheSame: e.target.value,
    });
  };

  handleBillAddressChange = (value, label) => {
    let clonedBillAddress = { ...this.state.billAddress };
    clonedBillAddress[label] = value;
    this.setState({
      billAddress: clonedBillAddress,
    });
  };

  checkIsAllowedToSubmit = () => {
    const { areBillAndDeliveryAddressesTheSame, billAddress } = this.state;

    if (!areBillAndDeliveryAddressesTheSame && !isAddressValid(billAddress)) {
      return false;
    }

    return true;
  };

  render() {
    const { classes, user } = this.props;
    const {
      addresses,
      error,
      target,
      areBillAndDeliveryAddressesTheSame,
      billAddress,
      isLoading,
    } = this.state;

    const steps = Stepper.getConfig('newOrder', target, user.role);

    return (
      <div className={classes.root}>
        {!error && (
          <>
            <Title
              text={selectAddress.title}
              subtitle={selectAddress.subtitle}
            />

            <div className={classes.stepperWrapper}>
              <Stepper current={2} steps={steps} />
            </div>

            <form className={classes.form}>
              <AddressData
                addresses={addresses}
                onSelectChange={selected =>
                  this.setState({ selectedAddress: selected })
                }
              />

              <div className={classes.billAddressWrapper}>
                <label
                  className={classNames(
                    classes.subtitle,
                    'uppercase text-black',
                  )}
                >
                  L'adresse de facturation est identique :
                </label>
                <Radio.Group
                  onChange={this.onBillAddressRadioChange}
                  defaultValue={true}
                >
                  <Radio.Button value={true}>oui</Radio.Button>
                  <Radio.Button value={false}>non</Radio.Button>
                </Radio.Group>
                {!areBillAndDeliveryAddressesTheSame && (
                  <div className={classes.inputBillAddressWrapper}>
                    <label className={classes.label}>
                      Adresse de facturation:
                    </label>
                    <GoogleAddressAutocomplete
                      onChange={this.handleBillAddressChange}
                      address={billAddress}
                    />

                    <div className={classes.nameInputWrapper}>
                      <NameInput
                        style={{ marginRight: 5 }}
                        value={billAddress.firstname}
                        label={'Prénom'}
                        placeholder={'Prénom'}
                        isRequired={true}
                        isError={false}
                        helperText={''}
                        onChange={value =>
                          this.handleBillAddressChange(value, 'firstname')
                        }
                      />
                      <NameInput
                        value={billAddress.lastname}
                        label={'Nom'}
                        placeholder={'Nom'}
                        isRequired={true}
                        isError={false}
                        helperText={''}
                        onChange={value =>
                          this.handleBillAddressChange(value, 'lastname')
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SubmitButton
                  title="Suite"
                  isAllowed={this.checkIsAllowedToSubmit()}
                  onClick={this.submitHandler}
                  isLoading={isLoading}
                />
              </div>
            </form>
          </>
        )}

        {error && (
          <Title
            text="Erreur"
            subtitle={
              error ||
              "Impossible de récupérer l'identifiant client ou celui-ci est erroné"
            }
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      storeSelectedAddress: Actions.storeSelectedAddress,
      storeBillAddress: Actions.storeBillAddress,
    },
    dispatch,
  );
}

function mapStateToProps({ abaca, auth }) {
  return {
    allProductsList: abaca.products.allProductsList,
    allCustomersList: abaca.customers.all,
    user: auth.user,
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SelectAddressPage),
);
