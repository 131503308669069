import 'babel-polyfill';
import 'typeface-muli';
import React from 'react';
import ReactDOM from 'react-dom';
import history from './history';
import './react-table-defaults';
import './styles/index.css';
import 'antd/dist/antd.css';
import './fake-db/fake-db';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { routes } from './fuse-configs/fuseRoutesConfig';
import { FuseTheme, FuseAuthorization } from '@fuse';
import MainToolbar from './main/MainToolbar';
import MainNavbarContent from './main/MainNavbarContent';
import MainNavbarHeader from './main/MainNavbarHeader';
import MainFooter from './main/MainFooter';
import jssExtend from 'jss-extend';
import QuickPanel from 'main/quickPanel/QuickPanel';
import store from 'store';
import SettingsPanel from 'main/SettingsPanel';
import { Auth } from 'auth';
import DefaultLayout from 'layouts/DefaultLayout';

import moment from 'moment';
import localeSettings from 'utils/locale';
import * as Sentry from '@sentry/browser';

moment.locale('fr', localeSettings.fr.date)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://0065751358d0412ab8d3405a107a333e@o400796.ingest.sentry.io/5259561',
  });
}

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <Auth history={history} routes={routes}>
        <Router history={history}>
          <FuseAuthorization routes={routes}>
            <FuseTheme>
              <DefaultLayout
                routes={routes}
                toolbar={<MainToolbar />}
                navbarHeader={<MainNavbarHeader />}
                navbarContent={<MainNavbarContent />}
                // footer={
                //     <MainFooter/>
                // }
                rightSidePanel={
                  <React.Fragment>
                    <QuickPanel />
                  </React.Fragment>
                }
                // contentWrapper={
                //     <SettingsPanel/>
                // }
              />
            </FuseTheme>
          </FuseAuthorization>
        </Router>
      </Auth>
    </Provider>
  </JssProvider>,
  document.getElementById('root'),
);

registerServiceWorker();
