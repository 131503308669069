import React from 'react';
import { Upload, Icon, Button, Card, Alert } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  alert: {
    marginBottom: '15px !important',
  },
  fileNameWrapper: {
    marginBottom: 15,
  },
});

class CardUpload extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
    };
  }

  render() {
    const {
      title,
      classes,
      description,
      onFileSelected,
      currentFileName,
      displayUri,
    } = this.props;
    const { fileList } = this.state;

    const uploadProps = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        onFileSelected(file);
        return false;
      },
      fileList,
    };

    return (
      <Card title={title} hoverable={false}>
        {currentFileName ? (
          <div className={classes.fileNameWrapper}>
            <Typography>Fichier déjà téléchargé:</Typography>
            <Typography>
              <a
                href={`${process.env.REACT_APP_API_URL}${displayUri}`}
                target="_blank"
                title="display file"
              >
                {currentFileName}
              </a>
            </Typography>
          </div>
        ) : (
          <Alert
            className={classes.alert}
            message={description}
            type="info"
            showIcon
          />
        )}
        <Upload {...uploadProps}>
          <Button disabled={fileList.length === 1}>
            <Icon type="upload" />{' '}
            {currentFileName ? 'Remplacer le fichier' : 'Choisir un fichier'}
          </Button>
        </Upload>
      </Card>
    );
  }
}

export default withStyles(styles)(CardUpload);
