import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Images
import accordeonActiveImg from 'static/icons/png/accordeon_active.png';
import accordeonImg from 'static/icons/png/accordeon.png';
import touringActiveImg from 'static/icons/png/touring_active.png';
import touringImg from 'static/icons/png/touring.png';

// components
import OrderListModeSwitcherButton from './OrderListModeSwitcherButton';

// Store
import * as Actions from 'store/actions';

// config
import config from 'config';

const styles = () => ({
  orderListModeSwitcher: {
    display: 'flex',
  },
  separator: {
    marginRight: 15,
  },
});

class OrderListModeSwitcher extends PureComponent {
  static defaultProps = {};

  state = {};

  handleItemClick = clickedListMode => {
    const { setListModeAction, listMode } = this.props;
    if (clickedListMode !== listMode) {
      setListModeAction(clickedListMode);
    }
  };

  render() {
    const { classes, listMode } = this.props;
    const { accordeon, touring } = config.orderListMode;

    return (
      <div className={classes.orderListModeSwitcher}>
        <OrderListModeSwitcherButton
          imageSrcActive={accordeonActiveImg}
          imageSrc={accordeonImg}
          onItemClick={() => this.handleItemClick(accordeon)}
          label={'Accordéon'}
          isItemActive={listMode === accordeon}
        />

        <div className={classes.separator} />

        <OrderListModeSwitcherButton
          imageSrcActive={touringActiveImg}
          imageSrc={touringImg}
          onItemClick={() => this.handleItemClick(touring)}
          label={'Tournée'}
          isItemActive={listMode === touring}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ abaca }) => ({
  listMode: abaca.orders.listMode,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setListModeAction: Actions.setListModeAction,
    },
    dispatch,
  );
};

const connectedOrderListModeSwitcher = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderListModeSwitcher);

export default withStyles(styles)(connectedOrderListModeSwitcher);
