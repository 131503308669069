import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table } from 'antd';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { Button, Icon } from 'antd';

// Components
import Title from 'components/Title';
import InputSearchBar from 'components/InputSearchBar';

// Services
import requestService from 'services/requestService';

// config
import config from 'config/index.js';

const styles = () => ({
  spinner: {
    textAlign: 'center',
    marginTop: 100,
  },
  tableWrapper: {
    marginTop: 25,
  },
  stockViewWrapper: {
    marginTop: 25,
    paddingBottom: 70,
    paddingLeft: 20,
    paddingRight: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

class stock extends PureComponent {
  static defaultProps = {};
  static propTypes = {};

  state = {
    isLoading: true,
    isSearchBarLoading: false,
    isSearchBarFilterActive: false,
  };

  async componentDidMount() {
    const { storeAllProductList } = this.props;

    const response = await this.getProductsSorted();

    storeAllProductList(response);

    this.setState({
      isLoading: false,
    });
  }

  getProductsSorted = async (filterValue = '') => {
    let response = await new requestService().getProducts(true, filterValue);
    response.data = response.data.sort((a, b) => {
      const aStock = a.stock ? a.stock.quantity : -1;
      const bStock = b.stock ? b.stock.quantity : -1;
      return bStock - aStock;
    });
    return response;
  };

  handleOnEnterSearchBar = async value => {
    const { storeAllProductList } = this.props;

    const incomingValue = value.trim();

    this.setState({ isSearchBarLoading: true });

    const response = await this.getProductsSorted(incomingValue);
    if (response) {
      storeAllProductList(response);
    }

    this.setState({
      isSearchBarFilterActive: !!incomingValue,
      isSearchBarLoading: false,
    });
  };

  render() {
    const { classes, allProductsList, auth, history } = this.props;
    const {
      isLoading,
      isSearchBarLoading,
      isSearchBarFilterActive,
    } = this.state;
    const isAdmin = auth.user.role === config.authRoles.admin;

    const columns = [
      {
        title: 'Désignation',
        dataIndex: 'designation',
        key: 'designation',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Prix',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Stock',
        dataIndex: 'stock.quantity',
        key: 'stock',
      },
      {
        title: 'Action',
        key: 'action',
        render: item => {
          return (
            <Button
              onClick={() =>
                history.push(
                  `${
                    config.pages[isAdmin ? 'abaca' : 'store'].newStock
                  }?designation=${item.designation}`,
                )
              }
              type={'primary'}
            >
              Nouvelle commande de stock
            </Button>
          );
        },
      },
    ];

    return (
      <div className="stock">
        <Title text="Stock" subtitle="" />
        {isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress size={68} />
          </div>
        ) : (
          <div className={classes.stockViewWrapper}>
            <div className={classes.actions}>
              <Button
                type="dashed"
                onClick={() =>
                  this.props.history.push(
                    config.pages[isAdmin ? 'abaca' : 'store'].newStock,
                  )
                }
              >
                <Icon type="plus" />
                Nouvelle commande de stock
              </Button>
              <div className={classes.searchBar}>
                <InputSearchBar
                  placeholder="Par designation, description..."
                  onEnterSearchBar={this.handleOnEnterSearchBar}
                  isSearchBarLoading={isSearchBarLoading}
                  isSearchBarFilterActive={isSearchBarFilterActive}
                />
              </div>
            </div>
            <div className={classes.tableWrapper}>
              <Table
                columns={columns}
                dataSource={allProductsList}
                pagination={{
                  pageSize: 10,
                  position: 'bottom',
                }}
                onChange={() => {}}
                rowKey={record => `${record.id} ${record.created_at}`}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ abaca, auth }) => ({
  allProductsList: abaca.products.allProductsList,
  auth,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      storeAllProductList: Actions.storeAllProductList,
    },
    dispatch,
  );
};

const connectedComponentStock = connect(
  mapStateToProps,
  mapDispatchToProps,
)(stock);
export default withStyles(styles)(connectedComponentStock);
