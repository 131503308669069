import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import { Icon, Tooltip, Divider as VerticalDivider } from 'antd';
import { withRouter } from 'react-router-dom';
import _some from 'lodash/some';
import moment from 'moment';

// Config, Utils & Helpers
import config from '../config/index';
import { isMobileByUserAgent, isMobileByWidth } from 'utils';
import { checkIsOrderReady, getFullShippingAddress } from 'utils';

// Components
import AccordeonSubItem from 'components/AccordeonSubItem';
import AccordeonItemCell from 'components/AccordeonItemCell';
import Commenter from 'components/Commenter/index';
import PhoneCTA from 'components/PhoneCTA';

const styles = theme => ({
  root: {
    width: '100%',
  },
  [config.orderStatus.delivered]: {
    '& > div:first-of-type': {
      background: config.gradients.main,
    },
  },
  [config.orderStatus.canceled]: {
    '& > div:first-of-type': {
      background: config.gradients.failed,
    },
  },
  [config.orderStatus.provider_ordered]: {
    '& > div:first-of-type': {
      background: config.gradients.provider,
    },
  },
  margin: {
    margin: '10px 0',
  },
  noMargin: {
    margin: '0',
  },
  popoverStatus: {
    fontSize: config.fontSizes.h2,
    '& span': {
      marginLeft: 5,
      fontSize: config.fontSizes.h3,
    },
  },
  voucherLink: {
    padding: 12,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    '& a': {
      color: config.colors.yellowAnzac,
      '&:hover': {
        color: config.colors.yellowGoldenRod,
      },
    },
  },
  verticalDivider: {
    height: '60px !important',
  },
  orderReadyBorder: {
    backgroundColor: config.colors.greenSuccess,
    position: 'absolute',
    top: 0,
    left: 0,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    width: 5,
    height: '100%',
  },
  expansionPanel: {
    backgroundColor: '#FFFF',
  },
});

class AccordeonItem extends PureComponent {
  static defaultProps = {
    comment: null,
    pausedDate: null,
  };

  static propTypes = {
    // Required
    classes: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    firstOrderDate: PropTypes.string.isRequired,
    orderTo: PropTypes.string.isRequired,
    subItems: PropTypes.array.isRequired,
    storeName: PropTypes.string.isRequired,
    customer: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    // Not required
    comment: PropTypes.string,
    pausedDate: PropTypes.string,
  };

  onClick = (orderId, isExpanded) => {
    let marginType = '';
    isExpanded ? (marginType = 'margin') : (marginType = 'noMargin');

    this.setState({
      marginType: marginType,
    });

    this.props.onChange(isExpanded ? orderId : null);
  };

  onCustomerNameClick = event => {
    const { customer } = this.props;

    // Don't expand panel
    event.stopPropagation();

    // Go to the customer page
    if (customer) {
      const link = `${config.pages.store.customer}?customerId=${customer.id}`;
      this.props.history.push(link);
    }
  };

  getPopoverContent() {
    // const { comment } = this.props;
    // const content = (
    // <div>
    //   <p>{comment ? `"${comment}"` : 'Aucun commentaire'}</p>
    // </div>
    // )
    // return content
  }

  getPopoverStatus = () => {
    const { classes, status } = this.props;

    let iconType = null;
    let humanizedStatus = null;
    let tone = null;
    switch (status) {
      case config.orderStatus.paused:
        humanizedStatus = 'en pause';
        iconType = 'pause-circle';
        tone = '#c1b033';
        break;
      case config.orderStatus.in_progress:
        humanizedStatus = 'en cours';
        iconType = 'play-circle';
        tone = '#c1b033';
        break;
      case config.orderStatus.canceled:
        humanizedStatus = 'annulée';
        iconType = 'close-circle';
        tone = '#eb2f96';
        break;
      case config.orderStatus.delivered:
        humanizedStatus = 'délivrée';
        iconType = 'check-circle';
        tone = '#52c41a';
        break;

      default:
        humanizedStatus = '- Erreur';
    }

    return (
      <div className={classes.popoverStatus}>
        <Icon type={iconType} theme="twoTone" twoToneColor={tone} />
        <span>Commande {humanizedStatus}</span>
      </div>
    );
  };

  handlePopoverVisibleChange = visible => {
    this.setState({ popoverIsVisible: visible });
  };

  closePopover = () => {
    const { popoverIsVisible } = this.state;

    if (popoverIsVisible) {
      this.setState({ popoverIsVisible: false });
    }
  };

  handleReadyOrder = () => {
    this.setState({ isOrderReady: checkIsOrderReady(this.props.subItems) });
  };

  constructor(props) {
    super(props);

    let totalAmount = 0;
    for (let i = 0; i < this.props.subItems.length; i++) {
      totalAmount = totalAmount + this.props.subItems[i].price;
    }

    this.state = {
      marginType: 'noMargin',
      totalAmount: totalAmount,
      popoverIsVisible: false,
      isOrderReady: false,
      selected: null,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.closePopover, true);

    if (isMobileByUserAgent() || isMobileByWidth()) {
      this.setState({ isMobile: true });
    }

    this.handleReadyOrder();

    this.setState({
      displayBillURL: this.props.order.billingURI,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.closePopover);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.order !== this.props.order) {
      this.handleReadyOrder();
    }
  }

  formatShippingAddress = shippingAddress => ({ __html: shippingAddress });

  render = () => {
    const {
      classes,
      voucherNumber,
      status,
      comment,
      firstOrderDate,
      deliveredDate,
      pausedDate,
      canceledDate,
      orderTo,
      subItems,
      storeName,
      customer,
      order,
      origin,
    } = this.props;
    const { isMobile, totalAmount, isOrderReady, displayBillURL } = this.state;

    const iconStyle = !isMobile
      ? { position: 'absolute' }
      : { position: 'absolute', top: 2, left: 2 };

    let orderId = new URLSearchParams(window.location.search).get('orderId');
    const isExpanded = orderId ? Number(orderId) === order.id : false;

    return (
      <div
        className={classNames(
          classes.root,
          classes[this.state.marginType],
          classes[status],
        )}
      >
        {/* <Popover 
          title={this.getPopoverStatus()}
          content={this.getPopoverContent()} 
          trigger='click' 
          arrowPointAtCenter
          visible={this.state.popoverIsVisible}
          onVisibleChange={this.handlePopoverVisibleChange} > */}

        <ExpansionPanel
          defaultExpanded={isExpanded}
          onChange={(event, isExpanded) => this.onClick(order.id, isExpanded)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {isOrderReady && (
              <Tooltip title="Commande prête !" placement="right">
                <div className={classes.orderReadyBorder} />
              </Tooltip>
            )}

            {// Statuses icons
            // TODO find something on mobile we can't switch statuses at the moment
            // // TODO Maybe make the switch status UI in a popover ?
            !isMobile && (
              <AccordeonItemCell
                size={config.itemCellSizes.small}
                status={status}
                order={order}
                isStatuses={true}
              />
            )}

            {/* Voucher number */}
            <AccordeonItemCell
              heading={`#${voucherNumber}`}
              size={config.itemCellSizes.medium}
              fontSize={'small'}
            />

            {// Store Emiter
            // Displayed only for abaca UI
            origin === config.origins.abaca && (
              <AccordeonItemCell
                heading="Magasin"
                text={storeName}
                size={config.itemCellSizes.large}
              />
            )}

            {/* Final Client Receiver */}
            <AccordeonItemCell
              heading="Livraison à"
              text={orderTo}
              actionClick={this.onCustomerNameClick}
              hasAction={true}
              size={config.itemCellSizes.large}
            />

            {status !== config.orderStatus.paused && (
              <AccordeonItemCell
                heading="Livraison prévue le"
                text={
                  order.estimated_delivery_at !== null
                    ? moment(order.estimated_delivery_at).format('DD/MM/YYYY')
                    : 'n/a'
                }
                size={config.itemCellSizes.large}
              />
            )}

            {/* Ville de livraison (Où) */}
            {order.shipping_address && order.shipping_address.way ? (
              <AccordeonItemCell
                heading="Où"
                text={order.shipping_address.city}
                size={config.itemCellSizes.large}
              />
            ) : status === config.orderStatus.paused ? (
              /* Total Amount  */
              <AccordeonItemCell
                heading="Total"
                text={`${totalAmount}€`}
                size={config.itemCellSizes.medium}
              />
            ) : (
              <AccordeonItemCell size={config.itemCellSizes.large}>
                <p>&nbsp;</p>
              </AccordeonItemCell>
            )}

            {status === config.orderStatus.delivered && (
              <AccordeonItemCell
                heading="Délivrée le"
                text={
                  deliveredDate
                    ? moment(deliveredDate).format('DD/MM/YYYY')
                    : deliveredDate
                }
                size={config.itemCellSizes.large}
              />
            )}

            {status === config.orderStatus.paused && (
              <AccordeonItemCell
                heading="Mise en pause le"
                text={
                  pausedDate
                    ? moment(pausedDate).format('DD/MM/YYYY')
                    : pausedDate
                }
                size={config.itemCellSizes.large}
              />
            )}

            {status === config.orderStatus.canceled && (
              <AccordeonItemCell
                heading="Annulée le"
                text={
                  canceledDate
                    ? moment(canceledDate).format('DD/MM/YYYY')
                    : canceledDate
                }
                size={config.itemCellSizes.large}
              />
            )}

            <AccordeonItemCell
              heading="Commentaire"
              text={comment ? comment : '-'}
              size={config.itemCellSizes.large}
            />

            {/* Comment Icon */}
            <AccordeonItemCell
              isComment={true}
              hasComment={!!comment}
              size={config.itemCellSizes.small}
            />
          </ExpansionPanelSummary>
          <div
            className="bg-indigo-lightest border-t-4 border-teal rounded-b text-indigo-darkest px-16 py-12 shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-4">
                <svg
                  className="fill-current h-24 w-24 text-teal mr-12"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <span className="font-bold">Adresse de livraison</span>
                <p
                  dangerouslySetInnerHTML={this.formatShippingAddress(
                    getFullShippingAddress(order.shipping_address),
                  )}
                />
              </div>
            </div>
          </div>

          <Divider />
          {/* -------------------------------------- Sub items mapping area */}
          {subItems.map((item, key) => {
            return (
              <AccordeonSubItem
                key={key}
                id={item.id}
                origin={origin}
                illustration={item.photo_src}
                designation={item.product.designation}
                price={item.price}
                provider={item.product.provider.name}
                status={item.status}
                isStock={!!order.is_stock}
                orderProduct={item}
              />
            );
          })}
          <Divider />
          {/* -------------------------------------- Comment area */}
          <ExpansionPanelActions className={classes.expansionPanel}>
            <Commenter comment={comment} orderId={order.id} />

            {// Displayed only for Abaca
            origin === config.origins.abaca &&
              isOrderReady &&
              status !== config.orderStatus.delivered &&
              customer && <PhoneCTA customer={customer} order={order} />}
            <VerticalDivider
              type="vertical"
              className={classes.verticalDivider}
            />
            <Typography className={classes.voucherLink}>
              <a href={displayBillURL} target="_blank">
                <Icon
                  type="snippets"
                  theme="outlined"
                  style={{ marginRight: 10 }}
                />
                Editer le bon de commande
              </a>
            </Typography>
          </ExpansionPanelActions>
        </ExpansionPanel>
        {/* </Popover> */}
      </div>
    );
  };
}

export default withRouter(withStyles(styles)(AccordeonItem));
