import NewOrderPage from 'main/pages/store/new-order/new-order';
import config from 'config';

export const NewStockConfig = {
  routes  : [
    {
      path     : config.pages.store.newStock,
      component: NewOrderPage
    },
    {
      path: config.pages.abaca.newStock,
      component: NewOrderPage,
    },
  ]
};
