import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import config from '../config';

const styles = theme => ({
  switchTogglerButton: {
    '& .switch': {
      position: 'relative',
      display: 'inline-block',
      width: '100px',
      height: '45px',
    },

    '& .switch input': {
      display:'none'
    },

    '& .slider': {
      position: 'absolute',
      cursor: 'pointer',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#EFEFEF',
      webkitTransition: '.4s',
      transition: '.4s',
      zIndex: 1,      
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      borderRadius: '5px',
      '& #leftValue, #rightValue': {
        minWidth: 30,
        minHeight: 2,
        textAlign: 'center',
      }
    },

    '& .selector': {
      position: 'absolute',
      content: "",
      height: '36px',
      width: '42px',
      left: '4px',
      bottom: '4px',
      backgroundColor: '#CFCFCF',
      webkitTransition: '.4s',
      transition: '.4s',
      borderRadius: '5px',
    },

    '& input:checked + .slider .selector.rightValue': {
      backgroundColor: '#DDC23D',
      webkitTransform: 'translateX(50px)',
      msTransform: 'translateX(50px)',
      transform: 'translateX(50px)',
    },

    '& .text': {
      zIndex: 2,
      fontSize: '1em',
      // fontFamily: 'Muller',
      color: '#fff',
    }
  }
})

class SwitchButton extends Component {
  static defaultProps = {
    leftValue: 'LEFT',
    leftColor: '',
    rightValue: 'RIGHT',
    rightColor: config.gradients.main,
  };
  
  constructor() {
    super();
    this.state = {
      value: ''
    }
  }

  render() {
    const {classes} = this.props;

    let bgColor = this.props.leftColor ;
    let style = '';
    if (this.props.isRightValueChecked) {
      style = 'rightValue'
      bgColor = this.props.rightColor ;
    }

    return (
      <div className={classes.switchTogglerButton}>
        <label className="switch">
          <input type="checkbox" onChange={this.props.onClick} checked={this.props.isRightValueChecked} />
          <span className="slider">
            <div className={`selector ${style}`} style={{backgroundColor: bgColor}} ></div>
            <span id='leftValue' className='text' >{this.props.leftValue}</span>
            <span id='rightValue' className='text' >{this.props.rightValue}</span>
          </span>
        </label>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(SwitchButton);