import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { Tooltip, Icon } from 'antd';
import { withRouter } from 'react-router-dom';

// Redux
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import ProductStatusDisplayer from 'components/ProductStatusDisplayer';

// Config, Utils & Helpers
import {
  isMobileByUserAgent,
  isMobileByWidth,
  putOrderProduct,
  getAllOrdersList,
} from 'utils';
import config from '../config/index';
const statuses = config.productStatusCodes;

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: '#FDFDFD',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    padding: 24,
  },
  column: {
    flexBasis: '33.33%',
    '& a': {
      color: config.colors.yellowAnzac,
      '&:hover': {
        color: config.colors.yellowGoldenRod,
      },
    },
  },
  designationColumn: {
    flexBasis: '50%',
  },
  imagePlaceHolder: {
    width: '100px',
    height: '100px',
  },
});

class AccordeonSubItem extends Component {
  static defaultProps = {
    designation: '---',
    price: 0,
    provider: '---',
    image: '',
    origin: config.origins.store,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    designation: PropTypes.string,
    price: PropTypes.number,
    provider: PropTypes.string,
    image: PropTypes.string,
    origin: PropTypes.string,
    status: PropTypes.oneOf([
      statuses.MUST_BE_ORDERED,
      statuses.RECEIVED,
      statuses.AVAILABLE_AT_STORE,
      statuses.DELIVERED,
    ]),
    onSwitchChange: PropTypes.func,
    id: PropTypes.number,
    isStock: PropTypes.bool,
    orderProduct: PropTypes.object,
  };

  handleSwitchChange = isRightValueChecked => () => {
    const { id, orderProduct } = this.props;

    if (!isRightValueChecked) {
      // The product isn't received yet
      // Trigger the receiving process
      this.props.openDialog({
        children: config.dialogContent.productReceived,
        orderProductId: id,
        subItemInfo: orderProduct,
      });
    }
  };

  updateOrderProductReceptionWithStorageInfo = async () => {
    const {
      receivedProductStorageInfo,
      id,
      clearCanceledReceptionProductId,
      storeAllOrders,
    } = this.props;

    if (id && receivedProductStorageInfo.cubicMeter) {
      await putOrderProduct(
        id,
        receivedProductStorageInfo.stockageZone,
        receivedProductStorageInfo.cubicMeter,
      );

      // An order product has been updated we need to update the order list
      const response = await getAllOrdersList();
      if (response && response.data) {
        const orders = response.data;
        storeAllOrders(orders);
      }

      clearCanceledReceptionProductId();
    }
  };

  goToReceptionDocumentsPage = () => {
    const { id, history, provider, designation, user } = this.props;
    history.push(
      `${
        user.role === config.authRoles.admin
          ? config.pages.abaca.documents
          : config.pages.store.documents
      }?productId=${id}&designation=${designation}&provider=${provider}`,
    );
  };

  state = {
    isMobile: false,
  };

  componentDidMount() {
    if (isMobileByUserAgent() || isMobileByWidth()) {
      this.setState({ isMobile: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      canceledReceptionProductId,
      clearCanceledReceptionProductId,
      confirmedReceivedProductId,
    } = this.props;

    const hasReceptionProductBeenCanceled =
      prevProps.canceledReceptionProductId !== canceledReceptionProductId &&
      canceledReceptionProductId === id;

    const hasReceptionProductBeenConfirmed =
      prevProps.confirmedReceivedProductId !== confirmedReceivedProductId &&
      confirmedReceivedProductId === id;

    if (hasReceptionProductBeenCanceled) {
      clearCanceledReceptionProductId();
    } else if (hasReceptionProductBeenConfirmed) {
      this.updateOrderProductReceptionWithStorageInfo(id);
    }
  }

  render() {
    const {
      classes,
      origin,
      provider,
      status,
      designation,
      price,
      isStock,
      orderProduct,
    } = this.props;

    const withQuantity =
      Number(orderProduct.quantity) > 1 ? `(x${orderProduct.quantity})` : '';

    return (
      <div className={classes.root}>
        <ExpansionPanelDetails className={classes.details}>
          <div className={classes.designationColumn}>
            <Typography className={classes.heading}>Désignation</Typography>
            <Typography className={classes.secondaryHeading}>
              {`${designation} ${withQuantity}`}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.heading}>Prix</Typography>
            <Typography className={classes.secondaryHeading}>
              {`${price} € ${withQuantity}`}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.heading}>Fournisseur</Typography>
            <Typography className={classes.secondaryHeading}>
              {provider}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.heading}>Documents</Typography>
            <Typography className={classes.secondaryHeading}>
              <Tooltip title="Voir ou gérer les documents de réception">
                <a onClick={this.goToReceptionDocumentsPage}>
                  <Icon
                    type="file"
                    theme="outlined"
                    style={{ marginRight: 10 }}
                  />
                  Accéder
                </a>
              </Tooltip>
            </Typography>
          </div>
          <div className={classes.column}>
            <ProductStatusDisplayer
              status={status}
              onSwitchChange={this.handleSwitchChange}
              origin={origin}
              isStock={isStock}
              orderProduct={orderProduct}
            />
          </div>
        </ExpansionPanelDetails>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      openDialog: Actions.openDialog,
      clearCanceledReceptionProductId: Actions.clearCanceledReceptionProductId,
      storeAllOrders: Actions.storeAllOrders,
    },
    dispatch,
  );
}

function mapStateToProps({ fuse, auth }) {
  return {
    canceledReceptionProductId: fuse.dialog.canceledReceptionProductId,
    confirmedReceivedProductId: fuse.dialog.confirmedReceivedProductId,
    receivedProductStorageInfo: fuse.dialog.receivedProductStorageInfo,
    user: auth.user,
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(AccordeonSubItem)),
);
