import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FuseAnimate } from '@fuse';
import Button from '@material-ui/core/Button';
import Home from '@material-ui/icons/Home';
import { connect } from 'react-redux';

import config from 'config';

const styles = theme => ({
  root: {},
  leftIcon: {
    marginRight: '10px',
  },
});

class Error404 extends Component {
  getPath = () => {
    const { userRole } = this.props;
    const { pages, authRoles, origins } = config;
    const redirectLink =
      pages[userRole === authRoles.admin ? origins.abaca : origins.abaca].home;
    console.log(redirectLink);

    return redirectLink;
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.root,
          'flex flex-col flex-1 items-center justify-center p-16',
        )}
      >
        <div className="max-w-512 text-center">
          <FuseAnimate animation="transition.expandIn" delay={100}>
            <Typography
              variant="display4"
              color="inherit"
              className="font-medium mb-16"
            >
              404
            </Typography>
          </FuseAnimate>

          <FuseAnimate delay={500}>
            <Typography
              variant="headline"
              color="textSecondary"
              className="mb-16"
            >
              Désolé mais nous ne trouvons pas la page que vous recherchez !
            </Typography>
          </FuseAnimate>

          <Link to={this.getPath()} role="button">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <Home className={classes.leftIcon} />
              Revenir à l'accueil
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    userRole: auth.user.role,
  };
};

const connectedError404 = connect(mapStateToProps)(Error404);

export default withStyles(styles, { withTheme: true })(connectedError404);
