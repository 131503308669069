import * as Actions from '../../actions/abaca';

const initialState = {
  errors: {
    all: [],
  },
};

const common = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_ERROR_ACTION: {
      return {
        ...state,
        errors: {
          all: [...state.errors.all, action.payload],
        },
      };
    }
    case Actions.REMOVE_ERRORS_ACTION: {
      return {
        ...state,
        errors: {
          all: [],
        },
      };
    }

    default:
      return state;
  }
};

export default common;
