import Dashboard from './Dashboard';

export const DashboardConfig = {
    routes  : [
        {
            path     : '/dashboard',
            component: Dashboard
        }
    ]
};
