import HomePage from './home';
import config from 'config';

export const HomePageConfig = {
  routes: [
    {
      path: config.pages.store.home,
      component: HomePage
    }
  ]
};