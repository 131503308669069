import config from 'config';
import requestService from '../services/requestService';
import _some from 'lodash/some';

export const getAllOrdersList = async (withFullDetails = true) => {
  return await new requestService().getOrders(withFullDetails);
};

export const postNewOrderStatus = async (
  order_id = null,
  status = null,
  comment = '',
  extraData = {},
) => {
  if (!order_id || !status) {
    throw new Error('Please provide a valid orderId and status');
  }
  return await new requestService().postOrderStatus({
    order_id,
    status,
    comment,
    ...extraData,
  });
};

export const putOrderProduct = async (
  id = null,
  storage_area = '',
  storage_occupation = 0,
  status = 'received',
) => {
  if (!id || !storage_occupation) {
    throw new Error('Please provide a valid id, and storage_occupation');
  }
  return await new requestService().putOrderProduct({
    id,
    storage_area,
    storage_occupation,
    status,
  });
};

export const updateProviderOrder = async orderProducts => {
  console.log('1 orderProducts', orderProducts);
  if (!orderProducts) {
    throw new Error('Please provide a valid providerProducts');
  }

  let status;

  try {
    status = await new requestService().updateProviderOrder(orderProducts);
  } catch (error) {
    console.log(error);
    return null;
  }

  return status;
};

export const getCustomerById = async (
  customerId = null,
  allCustomersList = [],
  hasFullReturnDetails = false,
) => {
  if (customerId) {
    let _allCustomersList = [];

    if (allCustomersList.length > 0) {
      _allCustomersList = allCustomersList;
    } else {
      try {
        const response = await new requestService().getCustomers();
        _allCustomersList = response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    }

    const foundCustomer = _allCustomersList.find(
      customer => Number(customer.id) === Number(customerId),
    );
    console.log('foundCustomer =====>', foundCustomer);

    if (!hasFullReturnDetails) {
      return foundCustomer;
    } else {
      return {
        customer: foundCustomer,
        _allCustomersList,
      };
    }
  } else {
    return null;
  }
};

export const getShopById = async (shopId = null, allShops = []) => {
  if (shopId) {
    let _allShops = [];

    if (allShops.length > 0) {
      _allShops = allShops;
    } else {
      const { data } = await new requestService().getStores();
      _allShops = data;
    }

    const foundShop = _allShops.find(
      shop => Number(shop.id) === Number(shopId),
    );
    console.log('foundShop =====>', foundShop);
    return foundShop;
  } else {
    return null;
  }
};

export const getProviderById = async (
  providerId = null,
  allProvidersList = [],
) => {
  if (providerId) {
    let allProviders = {};
    if (allProvidersList.length === 0) {
      try {
        const response = await new requestService().getProviders();
        allProviders = response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      allProviders = allProvidersList;
    }
    const foundProvider = allProviders.find(
      provider => Number(provider.id) === Number(providerId),
    );
    console.log('foundProvider =====>', foundProvider);
    return foundProvider ? foundProvider : null;
  } else {
    return null;
  }
};

export const getProviderByName = (providerName = '', allProviders = []) => {
  if (providerName && allProviders.length > 0) {
    const foundProvider = allProviders.find(
      provider => provider.name.trim() === providerName.trim(),
    );
    if (foundProvider) {
      return foundProvider;
    } else {
      console.log('provider not found (get by name)');
      return null;
    }
  } else {
    console.log('a provider name is required and/or list of providers missing');
    return null;
  }
};

export const getOrderById = async (orderId = null, allOrders = []) => {
  if (orderId) {
    let _allOrders = [];

    if (allOrders.length > 0) {
      _allOrders = allOrders;
    } else {
      const { data } = await new requestService().getOrders();
      _allOrders = data;
    }

    const foundOrder = _allOrders.find(
      order => Number(order.id) === Number(orderId),
    );
    console.log('foundOrder =====>', foundOrder);
    return foundOrder;
  } else {
    return null;
  }
};

export const getCategoryById = async (
  categoryId = null,
  allCategoriesList = [],
) => {
  if (categoryId) {
    let allCategories = {};
    if (allCategoriesList.length === 0) {
      try {
        const response = await new requestService().getCategories();
        allCategories = response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      allCategories = allCategoriesList;
    }
    const foundCategory = allCategories.find(
      category => Number(category.id) === Number(categoryId),
    );
    console.log('foundCategory =====>', foundCategory);
    return foundCategory ? foundCategory : null;
  } else {
    return null;
  }
};

export const getCategoryByName = (categoryName = '', allCategories = []) => {
  if (categoryName && allCategories.length > 0) {
    const foundCategory = allCategories.find(
      category =>
        category.name.trim().toLowerCase() ===
        categoryName.trim().toLowerCase(),
    );
    if (foundCategory) {
      return foundCategory;
    } else {
      console.log('Category not found (get by name)');
      return null;
    }
  } else {
    console.log(
      'a category name is required and/or list of categories missing',
    );
    return null;
  }
};

export const getProductByDesignation = (designation = '', allProducts = []) => {
  if (designation) {
    const foundProduct = allProducts.find(
      product =>
        product.designation.trim().toLowerCase() ===
        designation.trim().toLowerCase(),
    );
    if (foundProduct) {
      return foundProduct;
    } else {
      console.log('product not found');
      return null;
    }
  } else {
    console.log('product missing');
    return null;
  }
};

export const getProductFilesByProductId = (productId, allFiles) => {
  if (productId && allFiles.length > 0) {
    const clonedAllFiles = [...allFiles];
    const productFiles = [];
    clonedAllFiles.forEach(file => {
      if (parseInt(file.order_product_id) === parseInt(productId)) {
        productFiles.push(file);
      }
    });
    return productFiles;
  } else {
    console.error('getProductFilesByProductId bad request');
    return null;
  }
};

export const isMobile = () => {
  return isMobileByUserAgent() || isMobileByWidth();
};

export const isMobileByUserAgent = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isMobileByWidth = () => {
  if (window.innerWidth * window.devicePixelRatio <= config.responsive.small) {
    return true;
  } else {
    return false;
  }
};

export const isNewProductsValid = products => {
  const result = _some(products, function(obj) {
    return (
      (obj && !obj.designation) ||
      obj.designation.trim() === '' ||
      obj.description.trim() === '' ||
      obj.provider.trim() === '' ||
      obj.price.toString().trim() === '' ||
      obj.price <= 0 ||
      obj.quantity <= 0
    );
  });

  // If we find an empty string Or the price is invalid
  // Then return false for ==> not allowed
  return !result;
};

export const isAddressValid = address => {
  const clonedAddress = { ...address };

  if (clonedAddress.id === '') {
    // Remove empty id before checking for empty string
    delete clonedAddress.id;
  }

  // The address number can be empty
  delete clonedAddress.number;

  const hasEmptyString = _some(clonedAddress, function(obj) {
    return obj === '';
  });

  return !hasEmptyString;
};

export const getFullShippingAddress = shippingAddress => {
  let _shippingAddress = 'n/a';

  if (shippingAddress) {
    let premise = shippingAddress.premise
      ? shippingAddress.premise + '<br />'
      : '';
    _shippingAddress = `${premise}${shippingAddress.number}, ${
      shippingAddress.way
    }<br />${shippingAddress.postal_code} ${shippingAddress.city}`;
  }

  return _shippingAddress;
};

export const checkIsOrderReady = subItems => {
  const productStatusCodes = config.productStatusCodes;

  let isOrderReady = false;

  if (subItems && subItems.length) {
    const items = [];
    // Store every item status
    for (let i = 0; i < subItems.length; i++) {
      if (
        subItems[i].status === productStatusCodes.RECEIVED ||
        subItems[i].status === productStatusCodes.AVAILABLE_AT_STORE
      ) {
        items.push({ isReady: true });
      } else {
        items.push({ isReady: false });
      }
    }

    // isOrderReady is false if we find a "false" in items
    isOrderReady = !_some(items, item => item.isReady === false);
  }

  return isOrderReady;
};

export const getActiveStatuses = activeFilters => {
  let activeStatuses = null;

  activeStatuses = 'Commandes : ';
  activeStatuses += `${activeFilters.statuses.paused ? 'En pause, ' : ''}`;
  activeStatuses += `${activeFilters.statuses.delivered ? 'Livrée, ' : ''}`;
  activeStatuses += `${activeFilters.statuses.canceled ? 'Annulée, ' : ''}`;
  activeStatuses += `${activeFilters.statuses.in_progress ? 'En cours, ' : ''}`;
  activeStatuses += `${
    activeFilters.statuses.ready ? 'Prête pour livraison' : ''
  }`;

  return activeStatuses;
};
