import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import plus from '../static/icons/png/plus.png';
import classNames from 'classnames';

const styles = () => ({
  AddNewItem: {
    border: '1px dashed lightgrey',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: '300px',
    minWidth: '170px',

    '& span': {
      color: '#8a8a8a',
      fontSize: '1.4em'
    }
  }
});

class AddNewItem extends Component {
  static defaultProps = {
    title: 'Ajouter un item',
    width: '300px',
    height: '300px',
  };
  
  render() {
    const { classes, ...props } = this.props;
    
    return (
      <div 
        className={classNames(classes.AddNewItem, props.className)} 
        style={{width: this.props.width, height: this.props.height}} 
        onClick={this.props.onClick}
        >
        <img src={plus} style={{width:'60px'}} alt='add new item' />
        <span>{this.props.title}</span>
      </div>
    );
  }
}

export default withStyles(styles)(AddNewItem);