import SelectAddress from './select-address';
import config from 'config';

export const SelectAddressConfig = {
  routes  : [
    {
      path     : config.pages.store.selectAddress,
      component: SelectAddress
    },
  ]
};
