import axios from 'axios';
import jwtDecode from 'jwt-decode';
import FuseUtils from '@fuse/FuseUtils';

import config from 'config';

const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL =====>', API_URL);
class jwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      err => {
        return new Promise((resolve, reject) => {
          // Error
          if (
            err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', "Veuillez d'abord vous connecter");
            this.setSession(null);
          }
          throw err;
        });
      },
    );
  };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();
    console.log('access_token', access_token);

    if (!access_token) {
      return this.emit('onNoAccessToken', true);
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
      this.onAuthentication();
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'Votre connexion a expirée');
    }
  };

  onAuthentication() {
    // TODO commented for dev
    // if (history.location.pathname !== '/accueil') {
    //   history.push('/accueil');
    // }
  }

  createUser = data => {
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/register', data).then(response => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    console.log('env     =>', process.env.NODE_ENV);
    console.log('API_URL =>', API_URL);

    const data = {
      email,
      password,
    };

    const axiosConf = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + config.endpoints.login, data, axiosConf)
        .then(response => {
          console.log('response', response);
          if (response.data.user) {
            this.setSession(response.data.token);
            resolve(response.data.user);

            this.onAuthentication();
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          console.log('Error on signin', error);
        });
    });
  };

  signInWithToken = () => {
    console.log('signInWithToken');
    return new Promise((resolve, reject) => {
      let access_token = this.getAccessToken();
      const decoded = jwtDecode(access_token);

      if (decoded.hasOwnProperty('data')) {
        return resolve(decoded.data);
      }

      axios
        .get(API_URL + config.endpoints.show + '/' + decoded.uid, {
          data: {
            access_token: access_token,
          },
        })
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  updateUserData = user => {
    return axios.post('/api/auth/user/update', {
      user: user,
    });
  };

  setSession = access_token => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new jwtService();

export default instance;
