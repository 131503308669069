import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

// images
import pinActive from 'static/icons/png/pin_active.png';
import pin from 'static/icons/png/pin.png';

const styles = () => ({
  pin: {
    cursor: 'pointer',
  },
  show: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
});

class Pin extends PureComponent {
  static defaultProps = {};

  state = {};

  render() {
    const { classes, isActive, onClick } = this.props;

    return (
      <div className={classes.pin} onClick={onClick}>
        <img
          className={classnames(
            { [classes.show]: isActive },
            { [classes.hidden]: !isActive },
          )}
          src={pinActive}
        />
        <img
          className={classnames(
            { [classes.show]: !isActive },
            { [classes.hidden]: isActive },
          )}
          src={pin}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Pin);
