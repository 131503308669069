import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '@fuse/index';

import { LoginConfig } from 'main/pages/auth/login/LoginConfig';
import { Error404Config } from 'main/pages/errors/404/Error404Config';
import { Error500Config } from 'main/pages/errors/500/Error500Config';

import { pagesConfigs } from 'main/pages/pagesConfigs';
import { ComponentsConfig } from 'main/pages/components/ComponentsConfig';

import { authRoles } from 'auth/';
import _ from '@lodash';

function setAuth(configs) {
  return configs.map(config => {
    // Admin prefix routes
    let roleBase = config.auth;
    config.routes = config.routes.map(route => {
      let role;

      if (!route.auth) {
        if (!roleBase) {
          if (route.path.match(/^\/admin/)) {
            role = authRoles.admin;
          } else {
            role = authRoles.staff;
          }
        } else {
          role = roleBase;
        }
      } else {
        role = route.auth;
      }

      let roles = _.uniq(
        _.flatten([].concat(role).map(_role => authRoles[_role])),
      );
      // console.log(route.path + ' =>', roles)

      return {
        ...route,
        auth: roles,
        exact: route.hasOwnProperty('exact')
          ? route.exact
          : route.path.match(/.+\/$/),
      };
    });

    return config;
  });
}

let securePagesConfig = setAuth(pagesConfigs);

console.log('securePagesConfig', securePagesConfig);

const routeConfigs = [
  LoginConfig,
  Error404Config,
  Error500Config,
  ...securePagesConfig,
  ...setAuth([ComponentsConfig]),
];

export const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/" />,
  },
  {
    component: () => <Redirect to={`/errors/error-404`} />,
  },
];
