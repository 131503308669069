import * as Actions from '../../actions/abaca';

const initialState = [];

const shopsReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.STORE_ALL_SHOPS: {
      return [...state, ...action.payload];
    }

    case Actions.ADD_ONE_SHOP: {
      return [...state, action.payload];
    }

    case Actions.CLEAR_SHOPS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default shopsReducer;
