import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';

import config from 'config/index';

const styles = () => ({
  addressWrapper: {
    width: '300px',
    minWidth: '200px',
    border: `1px solid ${config.colors.greySeashell}`,
    borderRadius: '8px',
    padding: '20px',
    display: 'block !important',

    '& .name': {
      color: config.colors.blackMineShaft,
      fontSize: config.fontSizes.h2,
    },

    '& hr': {
      margin: '15px 0',
      borderColor: config.colors.greySilver,
      borderWidth: '1px',
    },

    '& .others-wrapper': {
      marginBottom: 70,
    },

    '& .others': {
      color: config.colors.greyDove,
      fontSize: config.fontSizes.h4,
    },
  },
  modify: {
    cursor: 'pointer',
    color: config.colors.yellowAnzac,
    position: 'absolute',
    bottom: '30px',
  },
  remove: {
    cursor: 'pointer',
    color: config.colors.yellowAnzac,
    position: 'absolute',
    bottom: '30px',
    right: '20px',
  },
});

class ManageableAddress extends Component {
  static defaultProps = {
    name: 'Nom inconnu',
    number: 'Numéro inconnu',
    street: 'Voie inconnue',
    code: 'Code postal inconnu',
    city: 'Ville inconnue',
    country: 'Pays inconnu',
    phone_number: 'Numéro de téléphone inconnu',
    canAddressBeDeleted: true,
  };

  render() {
    const {
      classes,
      name,
      number,
      street,
      city,
      country,
      phone_number,
      code,
      className,
      onRemove,
      onModify,
      canAddressBeDeleted,
    } = this.props;

    return (
      <div className={classNames(classes.addressWrapper, className)}>
        <div className="name">{name}</div>

        <div className={'others-wrapper'}>
          <hr />
          <span className="others">
            {number}, {street}
          </span>
          <br />
          <span className="others">
            {city}, {code}{' '}
          </span>
          <br />
          <span className="others">{country}</span>
          <br />
          <span className="others">{phone_number}</span>
        </div>

        <div className={classes.modify} onClick={onModify}>
          <span>Modifier</span>
        </div>

        {canAddressBeDeleted && (
          <Popconfirm
            title="Êtes-vous sûr(e) ?"
            okText="Oui"
            cancelText="Annuler"
            onConfirm={onRemove}
          >
            <div className={classes.remove}>
              <span>Supprimer</span>
            </div>
          </Popconfirm>
        )}
      </div>
    );
  }
}

ManageableAddress.propTypes = {
  name: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  phone_number: PropTypes.string,
  code: PropTypes.number,
  className: PropTypes.string,
  onRemove: PropTypes.func,
  onModify: PropTypes.func,
};

export default withStyles(styles)(ManageableAddress);
