export const STORE_ALL_CUSTOMERS = '[CUSTOMERS] STORE ALL CUSTOMERS LIST';
export const SET_SELECTED_CUSTOMER = '[CUSTOMERS] SET SELECTED CUSTOMER';
export const CLEAR_CUSTOMERS = '[CUSTOMERS] CLEAR_CUSTOMERS';

export function storeAllCustomers(payload = null) {
  return {
    type: STORE_ALL_CUSTOMERS,
    payload,
  };
}

export function setSelectedCustomer(payload = null) {
  return {
    type: SET_SELECTED_CUSTOMER,
    payload,
  };
}

export function clearCustomers() {
  return {
    type: CLEAR_CUSTOMERS,
  };
}
