import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from 'auth/store/actions';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import jwtService from 'services/jwtService';
import authService from 'services/Auth';
import { FuseSplashScreen } from '@fuse';

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return Promise.all([
      // Comment the lines which you do not use
      this.jwtCheck(),
    ])
      .then(() => {
        this.setState({ waitAuthCheck: false });
      })
      .catch(error => {
        console.error('Auth init', error);
        this.props.showMessage({
          message: 'Erreur lors de la tentative de connexion',
        });
      });
  }

  componentDidUpdate(prevProps) {
    console.log('auth did update', prevProps);
  }

  jwtCheck = () =>
    new Promise((resolve, reject) => {
      const { routes, history } = this.props;

      jwtService.on('onAutoLogin', () => {
        console.log('onAutoLogin');

        /**
         * Sign in and retrieve user data from Api
         */
        jwtService
          .signInWithToken()
          .then(user => {
            console.log('signInWithToken user =>', user);
            this.props.setUserData(user);

            // Check user roles
            if (!authService.checkRole(user, routes)) {
              return reject("Vous n'êtes pas autorisé à accéder à cette page");
            }

            resolve();

            this.props.showMessage({ message: 'Connexion sécurisée acceptée' });
          })
          .catch(error => {
            console.error('signInWithToken', error);
            this.props.showMessage({
              message: 'Erreur lors de la reconnexion',
            });

            resolve();
          });
      });

      jwtService.on('onAutoLogout', message => {
        console.log('onAutoLogout');

        if (message) {
          this.props.showMessage({ message });
        }

        this.props.logout();

        resolve();
      });

      jwtService.on('onNoAccessToken', () => {
        history.push({
          pathname: '/login',
        });

        resolve();
      });

      jwtService.init();

      return Promise.resolve();
    });

  render() {
    const { children } = this.props;

    return this.state.waitAuthCheck ? (
      <FuseSplashScreen />
    ) : (
      <React.Fragment children={children} />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userActions.logoutUser,
      setUserData: userActions.setUserData,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage,
    },
    dispatch,
  );
}

export default connect(
  null,
  mapDispatchToProps,
)(Auth);
