import history from 'history.js';
import { setDefaultSettings } from 'store/actions/fuse';
import { FuseDefaultSettings } from '@fuse';
import _ from '@lodash';
import store from 'store';
import * as Actions from 'store/actions';
import {
  CLEAR_FILES,
  CLEAR_ORDERS,
  CLEAR_PRODUCTS,
  CLEAR_STORES,
  CLEAR_CUSTOMERS,
  CLEAR_SHOPS,
  CLEAR_USERS,
} from '../../../store/actions/abaca';

// services
import jwtService from 'services/jwtService';
import Notification from 'services/Notification';
import RequestService from 'services/requestService';

import config from 'config';

import { LOGIN_SUCCESS } from './login.actions';

export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';
export const LOGIN_AS_CLIENT = '[USER] LOGIN_AS_CLIENT';

let settingsDefault = {
  layout: {
    style: 'abaca',
    config: {
      scroll: 'content',
      navbar: {
        display: false,
        folded: true,
        position: 'left',
      },
      toolbar: {
        display: true,
        style: 'fixed',
        position: 'below',
      },
      footer: {
        display: true,
        style: 'fixed',
        position: 'below',
      },
      mode: 'fullwidth',
    },
  },
  customScrollbars: true,
  theme: {
    main: 'default',
    navbar: 'default',
    toolbar: 'default',
    footer: 'default',
  },
};

/**
 * Set User Data
 */
export function setUserData(user) {
  return dispatch => {
    /*
        Set User Settings
         */
    let settings =
      user.data && user.data.hasOwnProperty('settings')
        ? user.data.settings
        : settingsDefault;
    dispatch(setDefaultSettings(settings));

    /*
        Set User Data
         */
    dispatch({
      type: SET_USER_DATA,
      payload: user,
    });
  };
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, { data: { settings } });

    updateUserData(user);

    return dispatch(setUserData(user));
  };
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    updateUserData(newUser);

    return dispatch(setUserData(newUser));
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

/**
 * Logout
 */
export function logoutUser() {
  return (dispatch, getState) => {
    const user = getState().auth.user;

    if (user.role === 'guest') {
      return null;
    }

    history.push({
      pathname: '/login',
    });

    jwtService.logout();

    dispatch(setDefaultSettings(FuseDefaultSettings));

    dispatch({ type: USER_LOGGED_OUT });
    dispatch({ type: CLEAR_FILES });
    dispatch({ type: CLEAR_ORDERS });
    dispatch({ type: CLEAR_PRODUCTS });
    dispatch({ type: CLEAR_SHOPS });
    dispatch({ type: CLEAR_STORES });
    dispatch({ type: CLEAR_CUSTOMERS });
    dispatch({ type: CLEAR_USERS });

    return true;
  };
}

/**
 * Update User Data
 */
function updateUserData(user) {
  if (user.role === 'guest') {
    return;
  }

  jwtService
    .updateUserData(user)
    .then(() => {
      store.dispatch(
        Actions.showMessage({ message: 'User data saved with api' }),
      );
    })
    .catch(error => {
      store.dispatch(Actions.showMessage({ message: error.message }));
    });
}

export const loginAsClientAction = (selectedClientName, userUuid) => {
  return async (dispatch, getState) => {
    // Get new login-as data
    const requestService = new RequestService();
    const response = await requestService.loginAs(userUuid);

    if (response && response.data) {
      // Clear current user store
      dispatch(setDefaultSettings(FuseDefaultSettings));
      dispatch({ type: USER_LOGGED_OUT });
      dispatch({ type: CLEAR_FILES });
      dispatch({ type: CLEAR_ORDERS });
      dispatch({ type: CLEAR_PRODUCTS });
      dispatch({ type: CLEAR_SHOPS });
      dispatch({ type: CLEAR_STORES });
      dispatch({ type: CLEAR_CUSTOMERS });
      dispatch({ type: CLEAR_USERS });

      // Set new session with new user logged-as
      const { token, user } = response.data;
      requestService.setJWTaccessToken(token);
      dispatch(setUserData(user));

      if (user.hasOwnProperty('store')) {
        dispatch(Actions.storeCurrentStore(user.store));
      }

      history.push({
        pathname: config.pages.store.home,
      });

      Notification.display(
        {
          title: 'Connexion établie 😸',
          content: `Vous êtes maintenant connecté en tant que ${
            config.authRolesNames[user.role]
          } de ${user.store.brand_name}`,
          level: 'SUCCESS',
        },
        {
          contentType: 'modal',
        },
      );

      dispatch({ type: LOGIN_AS_CLIENT, payload: selectedClientName });
      dispatch({ type: LOGIN_SUCCESS });
    } else {
      Notification.display({
        content: 'La connexion a échoué',
        title: 'Oops !',
        level: 'warning',
      });
    }
  };
};
